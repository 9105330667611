import {useState} from 'react';
import Cookies from 'js-cookie';
import useToast from './useToast';

function useAPI() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const [response, setResponse] = useState(null);

    const {renderToast} = useToast();

    const sendRequest = async (endpoint, method = 'GET', params = {}, body = null) => {
        setLoading(true);
        setError(null);
        setStatus(null);
        setResponse(null);

        const url = new URL(`https://api.place2eat.eu/api/${endpoint}`);
        // const url = new URL(`http://localhost:5001/api/${endpoint}`);

        const queryParams = new URLSearchParams(params);

        if (Object.keys(params).length > 0) {
            url.search = queryParams.toString();
        }

        const headers = new Headers();
        headers.set('Content-Type', 'application/json');
        const token = Cookies.get('token');

        if (token) {
            headers.append('Authorization', Cookies.get('token'));
        }

        try {
            const requestOptions = {
                method,
                headers: headers,
                body: body ? JSON.stringify(body) : null,
            };

            const response = await fetch(url.toString(), requestOptions);
            const data = await response.json();

            setLoading(false);
            setStatus(response.status);
            setResponse(data);

            return {data, response};
        } catch (err) {
            setLoading(false);
            setError(err);

            if (
                document.querySelector('#info-root > div').classList.contains('d-flex') &&
                token
            ) {
                renderToast(
                    'Änderungen im Demo Modus sind nicht möglich.',
                    'Bitte kontaktiere uns, um die Vollversion zu erhalten.',
                    'warning'
                );
            } else {
                if (err instanceof TypeError
                    && window.location.pathname !== '/login'
                    && window.location.pathname !== '/signup'
                    && window.location.pathname !== '/restorePassword'
                    && window.location.pathname !== '/mailverify'
                    && endpoint !== 'resend'
                    && endpoint !== 'verify'
                    && endpoint !== 'resetpassword') {
                    Cookies.remove('token', {path: '/'});
                    window.location.href = '/login';
                    return null;
                }
            }
        }
    };

    return {loading, error, status, response, sendRequest};
}

export default useAPI;
