import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useAPI from '../../hooks/useAPI';
import Cookies from "js-cookie";
import useToasts from "../../hooks/useToast";
import LoadingSpinner from "../../components/loading/LoadingSpinner";

const MailVerifyScreen = ({checkDemo}) => {
    const {loading, status, response = '', sendRequest} = useAPI();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const navigate = useNavigate();
    const {renderToast} = useToasts();
    const [finishedLoading, setFinishedLoading] = useState(false);

    async function verifyMail(code) {
        try {
            const postData = {
                token: code
            };
            sendRequest('verify', 'POST', {}, postData).then((data) => {
                if (data.response.status === 200) {
                    Cookies.set("token", data.data.token);
                    renderToast(data.data.message, "", "success");
                    checkDemo();
                    setFinishedLoading(true);
                    navigate("/reservation");
                } else {
                    renderToast("Fehler", data.data.message, "danger");
                }
            });
        } catch (error) {
            console.error('Fehler beim Verifizieren der E-Mail: ', error);
        }
    }

    useEffect(() => {
        if (code !== "") {
            verifyMail(code);
        }
    }, [code]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100vh',
        }}>
            {!finishedLoading ? (
                <div className={"fw-bold"}>
                    <LoadingSpinner size={"lg"}/>
                </div>
            ) : (
                <div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={'tech2-logo.png'} alt="Company Logo" style={{maxWidth: '200px', marginRight: '20px'}}/>
                        <span style={{fontWeight: 'bold', fontSize: '50px'}}>&amp;</span>
                        <img src={'logo512.png'} alt="Tech2 Logo" style={{maxWidth: '200px', marginLeft: '20px'}}/>
                    </div>
                    <h1 style={{color: '#333', marginTop: '20px'}}>E-Mail Verifizierung</h1>
                    <p style={{fontSize: '18px', marginTop: '10px'}}>Vielen Dank für die Verwendung von place2eat!</p>
                    <p style={{fontSize: '16px'}}>Deine E-Mail wurde bestätigt</p>
                </div>
            )}
        </div>
    );

};

export default MailVerifyScreen;
