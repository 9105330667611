import tech2Wallpaper from "./tech2-wallpaper.png";
import {Container} from "react-bootstrap";
import React from "react";

const AuthScreen = ({children}) => {
    return (
        <Container className={"vh-100 d-flex flex-column justify-content-center"}>
            <div className="d-md-flex login-box">
                <div className="w-100 w-md-50" style={{
                    backgroundImage: `url(${tech2Wallpaper}`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    minHeight: "190px"
                }}>
                    <div className={"login-banner d-flex flex-column justify-content-between h-100"}>
                    </div>
                </div>
                <div className="w-100 w-md-50 d-flex flex-column justify-content-center align-items-center my-4">
                    {children}
                </div>
            </div>
        </Container>
    );
}

export default AuthScreen;