import React, {useEffect, useState} from "react";
import "./printableTimeline.css";
import {formatDate} from "../../utils/dateUtils";
import {FaComment, FaHourglassEnd, FaHourglassStart, FaUsers} from "react-icons/fa";
import {GiTable} from "@react-icons/all-files/gi/GiTable";
import {FaPhone} from "@react-icons/all-files/fa/FaPhone";
import {FaUser} from "@react-icons/all-files/fa/FaUser";
import {BsStopwatch} from "react-icons/bs";

const PrintableTimeline = ({date, reservations, tables, separatePages, print}) => {
    const [timePeriod, setTimePeriod] = useState([]);
    const [hourCellWidth, setHourCellWidth] = useState(null);
    const [rowsWithMargin, setRowsWithMargin] = useState([]);

    // group reservations by tables
    const groupedReservations = reservations.reduce((acc, reservation) => {
        if (!acc[reservation.id]) {
            acc[reservation.id] = {...reservation, tables: [reservation.tableNumber]};
        } else {
            acc[reservation.id].tables.push(reservation.tableNumber);
        }
        return acc;
    }, {});

    const handleResize = () => {
        const width = document.getElementsByClassName('ptHourCell')[0]?.getBoundingClientRect().width;
        setHourCellWidth(width);
    }

    const hourCellRef = (node) => {
        if (node !== null) {
            handleResize();
            window.addEventListener('resize', handleResize);
        } else {
            window.removeEventListener('resize', handleResize);
        }
    };

    function getDPI() {
        // Create an element with an explicit size of one inch.
        const div = document.createElement("div");
        div.style.width = "1in";
        div.style.visibility = "hidden"; // Make it not visible on the page.
        document.body.appendChild(div); // Add it to the body to measure.
        const dpi = div.offsetWidth; // Measure the element's width in pixels, which gives us the DPI.
        document.body.removeChild(div); // Clean up by removing the element.
        return dpi;
    }


    /**
     * Set margins to ensure that rows and reservations are not split across pages
     */
    const splitRows = () => {
        const dpi = getDPI();
        const pageHeight = 8.3 * dpi; // Dynamically calculated based on DPI (8.3 is height of A4 page landscape in inches)

        const rows = document.getElementsByClassName('tlRow');
        if (!rows.length) return;
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            // Calculate the position of the row relative to the page
            const rowPosition = row.offsetTop % pageHeight;

            let addMargin = false;

            // area name rows (area page separation)
            if (i + 1 < rows.length) {
                if (rows[i + 1].classList.contains('areaNameRow')) {
                    if (separatePages) {
                        addMargin = true;
                    }
                }
            }

            // Check if the row is close to the bottom of the page
            if (pageHeight - rowPosition < 125) {
                // ignore last row
                if (i < rows.length - 1) {
                    addMargin = true;
                }
            }

            if (addMargin) {
                // Apply additional margin to push it to the next page
                row.style.marginBottom = `${pageHeight - rowPosition}px`;
                setRowsWithMargin((prev) => {
                    if (prev.includes(row)) return prev;
                    return [...prev, row];
                })
            }
        }

        const reservationEntries = document.getElementsByClassName('reservationEntry');
        for (let i = 0; i < reservationEntries.length; i++) {
            const entry = reservationEntries[i];
            // Calculate the position of the row relative to the page
            const entryPosition = entry.offsetTop % pageHeight;
            // Check if the row is close to the bottom of the page
            const offset = pageHeight - entryPosition;
            if (offset < 280) {
                // Apply additional margin to push it to the next page
                if (entry.style.marginTop) continue;
                entry.style.marginTop = `${offset + 10}px`
            }
        }
    };


    useEffect(() => {
        splitRows();

        if (hourCellWidth) {
            print();
        }
    }, [hourCellWidth]);

    useEffect(() => {
        const defaultValues = JSON.parse(localStorage.getItem('defaultValues'));
        const date = new Date();
        const currentDay = date.getDay();
        const weekday = defaultValues.weekdays.find((day, index) => index === currentDay);
        let openHour = parseInt(weekday.openTime.split(":")[0]);
        let closeHour = parseInt(weekday.closeTime.split(":")[0]);
        let closeMinutes = parseInt(weekday.closeTime.split(":")[1]);

        if (closeMinutes > 0 && closeHour < 23) {
            closeHour++;
        }

        const newTimePeriod = [openHour, closeHour];
        setTimePeriod(newTimePeriod);
    }, [date]);

    const groupedTables = tables.reduce((acc, table) => {
        (acc[table.area.id] = acc[table.area.id] || []).push(table);
        return acc;
    }, {});

    function generateLinearGradient(colors) {
        let gradient = `linear-gradient(120deg`;
        const step = 2; // gradient steps can be adjusted here
        let currentPercent = 0;

        while (currentPercent < 100) {
            for (let i = 0; i < colors.length; i++) {
                const startPercent = currentPercent;
                const endPercent = startPercent + step;

                // Check if end percent exceeds 100%
                if (endPercent > 100) break;

                gradient += `, #${colors[i]} ${startPercent}%`;
                gradient += `, #${colors[i]} ${endPercent}%`;

                currentPercent += step;

                // Stop if we reach or exceed 100%
                if (currentPercent >= 100) break;
            }
        }

        gradient += ")"; // Close the gradient function
        return gradient;
    }

    const renderTableReservations = (tableId) => {
        return reservations
            .filter(reservation => reservation.tableId === tableId.toString())
            .map((reservation, index) => {
                const jsDate = date instanceof Date ? date : new Date(date.toDate());

                const reservationStart = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate(), reservation.startHour, reservation.startMinute);
                const reservationEnd = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate(), reservation.endHour, reservation.endMinute);
                const durationHours = reservationEnd.getHours() + reservationEnd.getMinutes() / 60 - (reservationStart.getHours() + reservationStart.getMinutes() / 60);
                const startPosition = ((reservationStart.getHours() - timePeriod[0]) + reservationStart.getMinutes() / 60) * hourCellWidth;
                const width = durationHours * hourCellWidth;

                const colors = reservation.areaColors;
                let backgroundStyle = {background: "#348AA7"};
                if (colors[0] !== undefined) {
                    if (colors.length > 1) {
                        backgroundStyle = {
                            backgroundImage: generateLinearGradient(colors),
                        };
                    } else if (colors.length === 1) {
                        backgroundStyle = {
                            background: `#${colors[0]}`
                        };
                    }
                }

                return (
                    <div
                        key={index}
                        className="p-1 rounded-3 text-white fw-bold text-truncate text-center"
                        style={{
                            position: 'absolute',
                            left: `${startPosition + 200 + 20}px`,
                            width: `${width}px`,
                            textShadow: "0px 0px 2px rgba(0, 0, 0, 0.75)",
                            marginTop: 6,
                            ...backgroundStyle
                        }}
                    >
                        ({reservation.personAmount}) {reservation.name}
                    </div>
                );
            });
    };

    const calculateDuration = (startHour, startMinute, endHour, endMinute) => {
        const start = startHour + startMinute / 60;
        const end = endHour + endMinute / 60;
        return (end - start).toFixed(2);
    };

    const DateTimeBar = () => {
        return (
            <>
                <div className="ptDateCell text-truncate">
                    {formatDate(date instanceof Date ? date : new Date(date.toDate()))}
                </div>
                {Array.from({length: timePeriod[1] - timePeriod[0] + 1}, (_, i) => timePeriod[0] + i).map((hour, index) => (
                    <div
                        className="ptTimeCell"
                        key={hour}
                        ref={index === 0 ? hourCellRef : null}
                    >
                        {hour}:00
                    </div>
                ))}
            </>
        );
    }

    return (
        <div id="printableTimeline" className={"py-2"} style={{paddingInline: 20}}>
            <div className="d-flex">
                <DateTimeBar/>
            </div>
            {rowsWithMargin.map((_, index) => {
                return (
                    <div
                        className={"position-absolute d-flex"}
                        style={{top: 210 * (index + 1) + "mm", left: 0, right: 0, paddingInline: 20}}
                    >
                        <DateTimeBar/>
                    </div>
                );
            })}

            {Object.entries(groupedTables).map(([areaId, tables]) => (
                <div key={areaId}>
                    <div className="tlRow areaNameRow lead bg-light p-2 border-top border-2 border-black">
                        {tables[0].area.area}
                    </div>
                    {tables.map((table, index) => (
                        <div className="d-flex tlRow" key={table.id}>
                            <div
                                className={`ptTableNumberCell text-truncate ${index === 0 && "pt-border-top-1-black"}`}>
                                {table.tableNumber}
                            </div>
                            {Array.from({length: timePeriod[1] - timePeriod[0] + 1}, (_, i) => timePeriod[0] + i).map((hour) => (
                                <div
                                    className={`ptHourCell ${index === 0 && "pt-border-top-1-black"}`} key={hour}
                                    style={{minWidth: hourCellWidth}}
                                ></div>
                            ))}
                            {renderTableReservations(table.id)}
                        </div>
                    ))}
                </div>
            ))}

            <div id={"reservationsList"} className="reservationList mt-5 mb-2">
                <div className={"d-flex flex-wrap gap-3"} style={{maxWidth: "297mm"}}>
                    {Object.values(groupedReservations).sort((a, b) => a.name.localeCompare(b.name)).map((reservation, index) => {
                        // do not display reservations without a comment
                        if (!reservation.comment) return null;

                        return <div key={index}
                                    className="reservationEntry p-3 bg-light rounded-3 d-flex flex-column gap-2 border border-1"
                        >
                            <div className={"fw-bold"}>
                                <FaUser className={"me-2 text-muted"}/>
                                {reservation.name}
                            </div>
                            <div>
                                <FaPhone className={"me-2 text-muted"}/>
                                {reservation.phone}
                            </div>
                            <div>
                                <GiTable className={"mb-1 me-2 text-muted"}/>
                                {reservation.tables.join(', ')}
                            </div>
                            <div>
                                <FaUsers className={"me-2 text-muted"}/>
                                {reservation.personAmount}
                            </div>
                            <div>
                                <FaHourglassStart className={"me-2 text-muted"}/>
                                {`${reservation.startHour}:${reservation.startMinute.toString().padStart(2, '0')}`}
                            </div>
                            <div>
                                <FaHourglassEnd className={"me-2 text-muted"}/>
                                {`${reservation.endHour}:${reservation.endMinute.toString().padStart(2, '0')}`}
                            </div>
                            <div>
                                <BsStopwatch className={"me-2 text-muted"}/>
                                {
                                    calculateDuration(
                                        reservation.startHour,
                                        reservation.startMinute,
                                        reservation.endHour,
                                        reservation.endMinute)
                                } Stunden
                            </div>
                            <div>
                                <FaComment className={"me-2 text-muted"}/>
                                {reservation.comment || "/"}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default PrintableTimeline;
