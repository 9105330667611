import React, {useEffect, useState} from 'react';
import BirdView from "../../components/birdView/BirdView";
import useAPI from "../../hooks/useAPI";
import Slider from "rc-slider";
import useDefaults from "../../hooks/useDefaults";
import "./Birdperspective.css";
import {transformReservations} from "../../utils/reservationUtils";
import {FaLock, FaLockOpen, FaUnlock} from "react-icons/fa";

const BirdPerspective = ({date, selectedAreas, openModal, showAddReservation, updateReservations}) => {
    const {sendRequest} = useAPI();

    const [period, setPeriod] = useState([10, 14]);
    const [reservations, setReservations] = useState([]);
    const [periodReservations, setPeriodReservations] = useState([]);
    const {defaultValues, getTodayCloseTime, getTodayOpenTime} = useDefaults();
    const [marks, setMarks] = useState([]);
    const [areasLocked, setAreasLocked] = useState(true);

    const handleReservationDelete = () => {
        const formattedDate = date.format("YYYY-MM-DD");
        sendRequest(`reservations/resdate/${formattedDate}`).then(data => {
            setReservations(transformReservations(data.data));
        });
    }

    useEffect(() => {
        updateReservations(reservations);
    }, [reservations]);

    useEffect(() => {
        // set the areasLocked state from the local storage
        let areasLocked = JSON.parse(localStorage.getItem('areasLocked'));
        if (areasLocked !== null) {
            setAreasLocked(areasLocked);
        }

        document.addEventListener("reservationDelete", handleReservationDelete);
        return () => {
            document.removeEventListener("reservationDelete", handleReservationDelete);
        };
    }, []);

    useEffect(() => {
        const formattedDate = date.format("YYYY-MM-DD");
        sendRequest(`reservations/resdate/${formattedDate}`).then(data => {
            setReservations(transformReservations(data.data));
        });
    }, [date, showAddReservation]);

    useEffect(() => {
        const periodReservations = reservations.filter(reservation => {
            const reservationStart = +reservation.startHour + reservation.startMinute / 60;
            const reservationEnd = +reservation.endHour + reservation.endMinute / 60;
            const periodStart = period[0];
            const periodEnd = period[1];
            return reservationStart >= periodStart && reservationEnd <= periodEnd;
        });
        setPeriodReservations(periodReservations);
    }, [date, period, reservations]);

    useEffect(() => {
        if (defaultValues !== null && date !== null) {
            setMarks(generateMarks(getTodayOpenTime(date.toDate()), getTodayCloseTime(date.toDate())));
        } else {
            setMarks(generateMarks("00:00:00", "23:00:00"));
        }
    }, [defaultValues, date]);

    const generateMarks = (openTime, closeTime) => {
        const marks = {};

        const [openHour, openMinute] = openTime.split(":");
        let openHours = parseInt(openHour);
        let openMinutes = parseInt(openMinute);
        if (openMinutes !== 0) {
            openHours = openHours - 1;
        }

        const [closeHour, closeMinute] = closeTime.split(":");
        let closeHours = parseInt(closeHour);
        let closeMinutes = parseInt(closeMinute);
        if (closeMinutes !== 0) {
            closeHours = closeHours + 1;
        }

        // Determine the interval for marks based on screen width
        const markWidth = 40; // Adjust this based on actual mark size
        const availableWidth = window.innerWidth;
        const totalHours = closeHours - openHours;
        const maxMarks = Math.floor(availableWidth / markWidth);
        const markInterval = Math.ceil(totalHours / maxMarks);

        let currentHour = openHours;
        while (currentHour <= closeHours) {
            marks[currentHour] = `${currentHour < 10 ? "0" + currentHour : currentHour}:00`;
            currentHour += markInterval;
        }

        return marks;
    };

    const [sliderMinValue, setSliderMinValue] = useState(0);
    const [sliderMaxValue, setSliderMaxValue] = useState(24);

    useEffect(() => {
        let todayDay = date.day() - 1;
        if (todayDay === -1) todayDay = 6;
        let todayOpenTime = defaultValues?.weekdays[todayDay]?.openTime?.split(":")[0];
        if (!todayOpenTime) todayOpenTime = 0;
        setSliderMinValue(+todayOpenTime)
        let todayCloseTime = defaultValues?.weekdays[todayDay]?.closeTime?.split(":")[0];
        if (!todayCloseTime) todayCloseTime = 24;
        setSliderMaxValue(+todayCloseTime)

        // get the timeSliderPeriod from the local storage
        let timeSliderPeriod = JSON.parse(localStorage.getItem('timeSliderPeriod'));

        if (timeSliderPeriod) {
            if (timeSliderPeriod[0] < +todayOpenTime) {
                timeSliderPeriod[0] = +todayOpenTime;
            }
            if (timeSliderPeriod[1] > +todayCloseTime) {
                timeSliderPeriod[1] = +todayCloseTime;
            }
        } else {
            timeSliderPeriod = [+todayOpenTime, +todayCloseTime];
        }

        setPeriod(timeSliderPeriod);
    }, [defaultValues, date]);

    const handleSliderChange = (value) => {
        setPeriod(value);

        // store the value in the local storage
        localStorage.setItem('timeSliderPeriod', JSON.stringify(value));
    }

    return (
        <>
            <div className={"container px-4 pb-4 pt-1g bg-white"}>
                <Slider
                    range
                    draggableTrack
                    value={period}
                    onChange={handleSliderChange}
                    min={sliderMinValue}
                    max={sliderMaxValue}
                    step={0.5}
                    marks={marks}
                    allowCross={false}
                />
            </div>
            <BirdView period={period} reservations={reservations} areas={selectedAreas} openModal={openModal}
                      date={date} areasLocked={areasLocked}
            />
            <div
                className={`position-absolute bottom-0 start-0 m-2 btn btn-secondary lockBtn bg-transparent text-dark`}
                onClick={(e) => {
                    setAreasLocked((prev) => {
                        localStorage.setItem('areasLocked', JSON.stringify(!prev));
                        return !prev;
                    });
                    e.currentTarget.blur();
                }}>
                {!areasLocked ? <FaLockOpen/> : <FaLock/>}
            </div>
        </>
    );
};

export default BirdPerspective;
