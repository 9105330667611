import React, {useEffect, useState} from 'react';
import useAPI from "../../hooks/useAPI";
import {MdTableBar} from "react-icons/md";
import {PiChairDuotone} from "react-icons/pi";
import {GiBrickWall} from "react-icons/gi";
import {TiDelete} from "react-icons/ti";

export const icons = [
    {icon: <MdTableBar size={35}/>, id: 0, name: "TABLE"},
    {icon: <PiChairDuotone size={35}/>, id: 1, name: "CHAIR"},
    {icon: <GiBrickWall size={35}/>, id: 2, name: "WALL"},
];

const RestaurantElements = ({onSelectedElementChange, selectedIcon}) => {
    const {loading, status, response = "", sendRequest} = useAPI();
    const [restaurantElements, setRestaurantElements] = useState([]);

    const handleIconClick = (iconId) => {
        if (selectedIcon === iconId) {
            // When the same icon is pressed again, unmark it
            onSelectedElementChange(-1);
        } else {
            onSelectedElementChange(iconId);
        }
    };

    async function getRestaurantElements() {
        try {
            const data = await sendRequest(`cells/types`, 'GET');
            setRestaurantElements(data.data);
        } catch (error) {
            console.error("Fehler beim Abrufen der Restaurant-Elemente:", error);
        }
    }

    useEffect(() => {
        getRestaurantElements();
    }, []);


    return (
        <div className={"d-flex justify-content-center gap-3"}>
            {icons.map((item) => {
                const isItemInList = restaurantElements.some((element) => element === item.name);
                return (
                    isItemInList && (
                        <span
                            key={item.id}
                            onClick={() => handleIconClick(item.id)}
                            className={selectedIcon === item.id ? "text-primary" : 'inherit'}
                            style={{
                                cursor: "pointer",
                            }}>
                            {item.icon}
                        </span>
                    )
                );
            })}
            <div
                onClick={() => handleIconClick(100)}
                className={`ms-4 rounded ${selectedIcon === 100 ? "bg-primary-subtle" : "bg-none"}`}>
                <TiDelete size={35} color={"#ff4747"}/>
            </div>
        </div>
    );
};

export default RestaurantElements;
