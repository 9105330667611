import React from 'react';
import AppBarV2 from "../../components/appbarV2/AppBarV2";

const ContactScreen = () => {

    const handleEmailClick = () => {
        window.location.href = "mailto:info@tech2solutions.eu";
    };
    const handlePhoneClick = () => {
        window.location.href = "tel:02942/30615";
    };
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '100vh'
        }}>
            <div>
                <AppBarV2></AppBarV2>
            </div>
            <div style={{maxWidth: '600px', margin: 'auto', textAlign: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                    <img src={'tech2-logo.png'} alt="Company Logo"
                         style={{maxWidth: '200px', marginRight: '20px', margin: 'auto'}}/>
                    <span style={{fontWeight: 'bold', fontSize: '50px'}}>&amp;</span>
                    <img src={'logo512.png'} alt="Tech2 Logo"
                         style={{maxWidth: '200px', marginLeft: '20px', margin: 'auto'}}/>
                </div>
                <h1 style={{color: '#333', marginTop: '20px'}}>Kontakt Aufnahme</h1>
                <p style={{fontSize: '18px', marginTop: '10px'}}>
                    Kontaktieren Sie uns per E-Mail oder telefonisch für weitere Informationen.
                </p>
                <p>
                    <span style={{color: '#007bff', cursor: 'pointer', paddingRight: '10px'}}
                          onClick={handleEmailClick}>
                     info@tech2solutions.eu
                     </span>
                    <span style={{marginLeft: '5px', fontSize: '16px', verticalAlign: 'middle', cursor: 'pointer'}}
                          onClick={() => handleCopyToClipboard('info@tech2solutions.eu')}>
                    &#128203;
                </span>
                </p>
                <span style={{color: '#007bff', cursor: 'pointer', paddingRight: '10px'}} onClick={handlePhoneClick}>
                    02942/30615
                </span>
                <span style={{marginLeft: '5px', fontSize: '16px', verticalAlign: 'middle', cursor: 'pointer'}}
                      onClick={() => handleCopyToClipboard('tel:02942/30615')}>
                    &#128203;
                </span>

            </div>
        </div>
    );
};

export default ContactScreen;
