import "./AuthScreen.css";
import React from 'react';
import {Button} from "react-bootstrap";
import {InputWithIcon} from "../../components/inputWithIcon/InputWithIcon";
import {Key, Person} from "react-bootstrap-icons";
import {useLocation, useNavigate} from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import AuthScreen from "./AuthScreen";
import useToasts from "../../hooks/useToast";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {BiSolidShow} from "react-icons/bi";
import {AiFillEyeInvisible} from "react-icons/ai";

const PasswordRestoreScreen = ({checkDemo}) => {
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    const {renderToast} = useToasts();
    const [mail, setMail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [repeatedPassword, setRepeatedPassword] = React.useState("");

    // useAPI hook for sending the login request
    const {loading, status, response, sendRequest} = useAPI();

    // toogle password visibility
    const [showPassword, setShowPassword] = React.useState(false);
    const [showRepeatedPassword, setShowRepeatedPassword] = React.useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleRepeatedPasswordVisibility = () => {
        setShowRepeatedPassword(!showRepeatedPassword);
    };

    function sendRestorePasswordRequest() {

        if (password.length < 6) {
            renderToast("Fehler", "Das Passwort muss mindestens 6 Zeichen lang sein", "danger");
            return;
        }

        if (password !== repeatedPassword) {
            renderToast("Fehler", "Die Passwörter stimmen nicht überein", "danger");
            return;
        }

        // Fortsetzung mit dem Senden des Passwort-Wiederherstellungsanforderungscodes
        const postData = {
            token: code,
            password: password,
        };

        sendRequest("resetpassword", "PATCH", {}, postData).then(data => {
            if (data.response.status === 200) {
                navigate('../login');
                renderToast("Erfolgreich", data.data.message, "success");
            } else {
                renderToast("Fehler beim Setzen des neuen Passworts", data.data.message, "danger");
            }
        });
    }

    function sendNewPassword() {
        const postData = {
            email: mail.toLowerCase(),
        };
        sendRequest("restorepassword", "POST", {}, postData).then(data => {
            if (data.response.status === 200) {
                renderToast("Erfolgreich", data.data.message, "success");
            } else {
                renderToast("Fehler", data.data.message, "danger");
            }
        });
    }

    return (
        <AuthScreen>
            <div>
                <div className="text-center">
                    {code ? (
                        <h3 className={"mb-5 fw-bold text-primary"}>Neues Passwort setzen</h3>
                    ) : (
                        <h3 className={"mb-5 fw-bold text-primary"}>Passwort zurücksetzen</h3>
                    )}
                </div>
                {!code ? (
                    <div className={"w-100"}>
                        <InputWithIcon
                            className={"mb-3"}
                            icon={<Person/>}
                            type={"text"}
                            placeholder={"E-Mail"}
                            value={mail}
                            onChange={(event) => setMail(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    sendNewPassword();
                                }
                            }}
                        />
                    </div>
                ) : (
                    <div className={"w-100"}>
                        <InputWithIcon
                            icon={<Key/>}
                            className={"mb-3"}
                            showVisibleText={true}
                            visibleTextIcon={showPassword ? <BiSolidShow/> : <AiFillEyeInvisible/>}
                            toggleVisibleTextIcon={togglePasswordVisibility}
                            type={showPassword ? "text" : "password"}
                            placeholder={"Passwort"}
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    sendRestorePasswordRequest();
                                }
                            }}
                        />
                        <InputWithIcon
                            icon={<Key/>}
                            showVisibleText={true}
                            visibleTextIcon={showRepeatedPassword ? <BiSolidShow/> : <AiFillEyeInvisible/>}
                            toggleVisibleTextIcon={toggleRepeatedPasswordVisibility}
                            type={showRepeatedPassword ? "text" : "password"}
                            placeholder={"Passwort wiederholen"}
                            value={repeatedPassword}
                            onChange={(event) => setRepeatedPassword(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    sendRestorePasswordRequest();
                                }
                            }}
                        />
                    </div>
                )}
                <div>
                    {loading ? (
                        <LoadingSpinner/>
                    ) : code ? (
                        <Button variant={"primary"} className={"mt-5 w-100 text-white"} onClick={sendRestorePasswordRequest}>
                            Passwort zurücksetzen
                        </Button>
                    ) : (
                        <Button variant={"primary"} className={"mt-5 w-100 text-white"} onClick={sendNewPassword}>
                            E-Mail zum zurücksetzen senden
                        </Button>
                    )}
                </div>
                <div className={"mt-3 text-center"}>
                    <a className={"sign-up-text fw-500 text-decoration-none"} type={"button"}
                       onClick={() => navigate("../login")}>
                        <span className={"text-secondary me-1"}>Zuück zum</span>
                        <span className={"text-primary fw-bold"}>Login</span>
                    </a>
                </div>
            </div>
        </AuthScreen>
    );
}
export default PasswordRestoreScreen;




