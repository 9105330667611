import "./AuthScreen.css";
import React, {useEffect} from 'react';
import {InputWithIcon} from "../../components/inputWithIcon/InputWithIcon";
import {Key, Person} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import AuthScreen from "./AuthScreen";
import useAPI from "../../hooks/useAPI";
import {Button} from "react-bootstrap";
import useToasts from "../../hooks/useToast";

const SignupScreen = ({checkDemo}) => {
    const navigate = useNavigate();
    const [checkVal, setCheckVal] = React.useState(false);

    // state for storing the registration credentials
    const [credentials, setCredentials] = React.useState({
        email: "",
        password: "",
        passwordRepeat: ""
    });

    // useAPI hook for sending the registration request
    const {loading, status, response, sendRequest} = useAPI();
    const onSignup = () => {
        if (loading) return;

        // check if all fields are filled
        if (credentials.email === "" || credentials.password === "" || credentials.passwordRepeat === "") {
            renderToast("Unvollständig", "Bitte fülle alle Felder aus", "danger")
            return;
        }

        if (credentials.password.length < 6) {
            renderToast("Fehler", "Das Passwort muss mindestens 6 Zeichen lang sein", "danger");
            return;
        }

        // check if the passwords match
        if (credentials.password !== credentials.passwordRepeat) {
            renderToast("Passwörter stimmen nicht überein", "Bitte überprüfe deine Eingabe", "danger")
            return;
        }

        // check if the email is valid
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!credentials.email.match(emailRegex)) {
            renderToast("Ungültige E-Mail", "Bitte überprüfe deine Eingabe", "danger")
            return;
        }

        if (!checkVal) {
            renderToast("ABG & DSGVO bestätigen", "Bitte akzeptiere die Allgemeinen Geschäftsbedienungen und die Datenschutzerklärung", "warning");
            return;
        }

        const finalCredentials = {
            email: credentials.email,
            password: credentials.password
        }
        sendRequest("sign", "POST", {create: "true"}, finalCredentials);
    };

    useEffect(() => {
        checkDemo();
    }, []);

    // handle the api response, set cookies, show toasts and navigate to the reservation page
    const {renderToast} = useToasts();
    useEffect(() => {
        if (response) {
            if (status === 201 || status === 200) {
                navigate(`/login?verifyMail=${credentials.email}`);
            } else {
                renderToast("Ein Fehler ist aufgetreten", response.message, "danger");
            }
        }
    }, [loading]);


    return (
        <AuthScreen>
            <div className={"px-2"} style={{maxWidth: 400}}>
                <div className="text-center">
                    <h3 className={"mb-5 text-primary"}>
                        <div className={"fw-bold text-uppercase"}>place<span className={"text-dark"}>2</span>eat</div>
                        <span className={"fs-5"}>Demo Account anlegen</span>
                    </h3>
                </div>
                <div className={"w-100"}>
                    <InputWithIcon
                        className={"mb-3"}
                        icon={<Person/>}
                        type={"email"}
                        placeholder={"E-Mail"}
                        value={credentials.email}
                        onChange={(event) => setCredentials({...credentials, email: event.target.value})}
                    />
                    <InputWithIcon
                        className={"mb-3"}
                        icon={<Key/>}
                        type={"password"}
                        placeholder={"Passwort"}
                        value={credentials.password}
                        onChange={(event) => setCredentials({...credentials, password: event.target.value})}
                    />
                    <InputWithIcon
                        icon={<Key/>}
                        type={"password"}
                        placeholder={"Passwort wiederholen"}
                        value={credentials.passwordRepeat}
                        onChange={(event) => setCredentials({...credentials, passwordRepeat: event.target.value})}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSignup();
                            }
                        }}
                    />
                </div>

                <div className={"my-4 d-flex align-items-center gap-3"} style={{fontSize: "0.8em"}}>
                    <input
                        type="checkbox"
                        style={{transform: "scale(1.5)"}}
                        value={checkVal}
                        onChange={() => {
                            setCheckVal(!checkVal);
                        }}
                    />
                    <div>
                        <span> Ich habe die </span>
                        <a href={"https://tech2solutions.eu/agb/"} target={"_blank"}>allgemeinen
                            Geschäftsbedienungen</a>
                        <span> und die </span>
                        <a href={"https://book2eat.eu/c/dsgvo/"} target={"_blank"}>Datenschutzerklärung</a>
                        <span> gelesen und erkläre mich damit einverstanden. </span>
                    </div>
                </div>

                <Button variant={"primary"} className={"w-100"} onClick={onSignup}>
                    {
                        loading ?
                            <>
                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                <span className="visually-hidden" role="status">Loading...</span>
                            </>
                            :
                            "Registrieren"
                    }
                </Button>

                <div className={"mt-1 text-center"}>
                    <a className={"sign-up-text fw-500 text-decoration-none"} type={"button"}
                       onClick={() => navigate("../login")}>
                        <span className={"text-secondary me-1"}>Bereits registriert?</span>
                        <span className={"text-primary fw-bold"}>Hier anmelden.</span>
                    </a>
                </div>
            </div>
        </AuthScreen>
    );

}
export default SignupScreen;




