import React, { useEffect, useState } from 'react';
import useToasts from "../../hooks/useToast";
import useAPI from "../../hooks/useAPI";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import StatisticAppBar from "./StatisticAppBar";
import dayjs from "dayjs";
import {transformReservations} from "../../utils/reservationUtils";

const StatisticScreen = () => {

    const [reservations, setReservations] = useState([]);
    const [hourlyData, setHourlyData] = useState([]);
    const { renderToast } = useToasts();
    const { sendRequest } = useAPI();
    const initialDate = dayjs();
    const [date, setDate] = useState(initialDate);
    const [area, setArea] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);

    const fetchReservations = () => {
        sendRequest(`reservations/resdate/${date.format("YYYY-MM-DD")}`).then(data => {
            // when the area !== null then show only the reservations for the selected area
            if (data.response.status !== 200) {
                renderToast("Fehler", "Fehler beim abfragen der Reservierungen.", "danger");
                return;
            }
            const reservations = transformReservations(data.data);
            if (area !== null) {
                const filteredReservations = reservations.filter(reservation => reservation.areaId == area);
                setReservations(filteredReservations);
            } else {
                setReservations(reservations);
            }
        }).catch(err => {
            renderToast("Fehler", "Fehler beim abfragen der Reservierungen.", "danger");
        });
    };

    const getTotalPersonCount = () => {
        // Filter reservations to remove duplicates based on ID
        const uniqueReservations = reservations.reduce((unique, reservation) => {
            if (!unique.some(item => item.id === reservation.id)) {
                unique.push(reservation);
            }
            return unique;
        }, []);

        // Calculate total person count from unique reservations
        return uniqueReservations.reduce((total, reservation) => total + parseInt(reservation.personAmount), 0);
    };

    const handleResize = (e) => {
        setWidth(e.target.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        // Count number of reservations per hour
        const hourlyCounts = Array(24).fill(0);

        // Filter reservations to remove duplicates based on ID
        const uniqueReservations = reservations.reduce((unique, reservation) => {
            if (!unique.some(item => item.id === reservation.id)) {
                unique.push(reservation);
            }
            return unique;
        }, []);

        // Iterate over unique reservations to count hourly persons
        uniqueReservations.forEach(reservation => {
            const startHour = parseInt(reservation.startHour);
            const endHour = parseInt(reservation.endHour);
            for (let i = startHour; i < endHour; i++) {
                hourlyCounts[i] += parseInt(reservation.personAmount);
            }
        });

        // Prepare data for the bar chart
        const data = hourlyCounts.map((count, index) => ({
            hour: index < 10 ? `0${index}:00` : `${index}:00`,
            Personen: count
        }));
        setHourlyData(data);
    }, [reservations]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
                    <strong>
                        <p>{`${payload[0].payload.hour} Uhr`}</p>
                        <p>{`Personen: ${payload[0].value}`}</p>
                    </strong>
                </div>
            );
        }
        return null;
    };

    const handleDateChange = (date) => {
        setDate(date);
    }

    const handleAreaChange = (area) => {
        setArea(area);
    }

    useEffect(() => {
        fetchReservations();
    }, [date, area]);

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ width: '100%' }}>
                <StatisticAppBar onDateChange={handleDateChange} onAreaChange={handleAreaChange}/>
            </div>

            <div className={"overflow-x-auto"} style={{ width: '100%', paddingTop: 64 + 15, textAlign: 'center' }}>
                <strong>
                    <span>Reservierungsstatistik</span>
                </strong>
                <div style={{margin: '0 auto', width: 'fit-content' }}>
                    <BarChart width={width -40} height={400} data={hourlyData}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="hour"/>
                        <YAxis/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend/>
                        <Bar dataKey="Personen" fill="#348AA7">
                            {hourlyData.map((entry, index) => (
                                <text x={entry.hour} y={entry.Personen} dy={-10} textAnchor="middle" fill="#666">
                                    {entry.Personen}
                                </text>
                            ))}
                        </Bar>
                    </BarChart>
                </div>
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <p>Gesamtanzahl der Personen heute: {getTotalPersonCount()}</p>
            </div>
        </div>
    );
};

export default StatisticScreen;