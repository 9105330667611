import {FaPrint} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "../loading/LoadingSpinner";
import useAPI from "../../hooks/useAPI";
import useToast from "../../hooks/useToast";
import {Modal} from "react-bootstrap";
import AreaSelect from "../areaSelect/AreaSelect";
import {Checkbox, FormControlLabel} from "@mui/material";
import {generatePDF} from "../pdf-generator/pdf-generator";
import {transformReservations} from "../../utils/reservationUtils";

const GeneratePDFButton = ({date}) => {
    const {sendRequest} = useAPI();
    const {renderToast} = useToast();

    const [pdfLoading, setPdfLoading] = useState(false);
    const [pdfMenuShow, setPdfMenuShow] = useState(false);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [separatePages, setSeparatePages] = useState(false);

    const [allReservations, setAllReservations] = useState([]);
    const [allTables, setAllTables] = useState([]);
    const [relevantReservations, setRelevantReservations] = useState([]);
    const [relevantTables, setRelevantTables] = useState([]);

    useEffect(() => {
        if (!pdfMenuShow) return;

        const formattedDate = date.format("YYYY-MM-DD");
        sendRequest(`reservations/resdate/${formattedDate}`).then(data => {
            setAllReservations(transformReservations(data.data));
        });

        sendRequest("tables").then(data => {
            setAllTables(data.data);
        });
    }, [date, pdfMenuShow]);

    useEffect(() => {
        if (!date) return;
        if (!selectedAreas?.length) return;

        const newRelevantReservations = allReservations.filter(reservation => {
            return selectedAreas.some(area => area.id === +reservation.areaId);
        });
        setRelevantReservations(newRelevantReservations);
    }, [allReservations, selectedAreas])

    useEffect(() => {
        if (!selectedAreas?.length) return;

        const newRelevantTables = allTables.filter(table => {
            return selectedAreas.some(area => area.id === table.area.id);
        });
        setRelevantTables(newRelevantTables);
    }, [allTables, selectedAreas])

    const handleConfirmPDF = () => {
        setPdfLoading(true);
        generatePDF(date, relevantReservations, relevantTables, separatePages, stopLoading);
        setPdfMenuShow(false);
    };

    const handlePdfGenerationClick = () => {
        setPdfMenuShow(true);
    };
    const stopLoading = () => {
        setPdfLoading(false);
        handleClose();
    };

    const handleCheckboxChange = (event) => {
        setSeparatePages(event.target.checked);
    };

    const handleClose = () => {
        setPdfMenuShow(false);
        setSelectedAreas([]);
        setSeparatePages(false);
    };

    return (
        <>
            {pdfLoading && <div
                className={"bg-white position-fixed vh-100 vw-100 top-0 start-0 d-flex justify-content-center align-items-center"}
                style={{zIndex: 99999999999}}
            >
                <LoadingSpinner size={"lg"}/>
            </div>
            }

            <span style={{fontSize: "0.8em"}}
                  className={`btn btn-link bg-primary-subtle ms-1 ms-md-2 py-1 px-2 rounded-5 d-flex align-items-center text-decoration-none`}
                  onClick={handlePdfGenerationClick}
            >
                <FaPrint size={15} className={"m-0 me-md-1"}/>
                <span className={"d-none d-md-block"}>PDF erzeugen</span>
            </span>

            <Modal
                show={pdfMenuShow}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>PDF erzeugen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AreaSelect
                        selectedAreas={selectedAreas}
                        setSelectedAreas={setSelectedAreas}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={separatePages}
                                onChange={handleCheckboxChange}
                                name="separatePages"
                                color="primary"
                            />
                        }
                        label="Bereiche auf Seiten aufteilen"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={handleClose}
                    >
                        Abbrechen
                    </button>
                    <button
                        className="btn btn-primary text-white"
                        onClick={handleConfirmPDF}
                    >
                        PDF erzeugen
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default GeneratePDFButton;