export const convertUTCToLocalTime = (utcDate) => {
    // if (!(utcDate instanceof Date)) {
    //     utcDate = new Date(utcDate);
    // }
    // const localTime = new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000));
    // return localTime;
    return utcDate;
};

export const formatDate = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return date.toLocaleDateString("de-AT", {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    });
}

export const formatDateNumbers = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return date.toLocaleDateString("en-GB", {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    }).replace(/\//g, '-');
}