import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useAPI from "../../hooks/useAPI";
import useToasts from "../../hooks/useToast";
import useDefaults from "../../hooks/useDefaults";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import PinChangeModal from "../../components/lock/PinChangeModal";
import AppBarV2 from "../../components/appbarV2/AppBarV2";

const Settings = () => {
    const [oldSettings, setOldSettings] = useState([]);

    const [restaurantName, setRestaurantName] = useState("");
    const [reservationDuration, setReservationDuration] = useState("");
    const [timeColumns, setTimeColumns] = useState("");
    const [weekDays, setWeekDays] = useState([]);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const {loading, sendRequest} = useAPI();
    const {updateDefaults, defaultValues} = useDefaults();
    const {renderToast} = useToasts();

    const validateForm = () => {
        let disabled = true;

        if (restaurantName !== "" && oldSettings.name !== restaurantName) {
            disabled = false;
        }
        if (reservationDuration !== "" && oldSettings.defaultReservationDuration !== reservationDuration) {
            disabled = false;
        }
        if (timeColumns !== "" && timeColumns !== oldSettings.defCollumDuration) {
            disabled = false;
        }

        weekDays?.forEach((day) => {
            if (day.openTime !== "" && day.closeTime !== "" && (day.openTime !== oldSettings.openTime || day.closeTime !== oldSettings.closeTime)) {
                const openTimeDate = new Date(`2000-01-01T${day.openTime}`);
                const closeTimeDate = new Date(`2000-01-01T${day.closeTime}`);

                if (openTimeDate < closeTimeDate) {
                    disabled = false;
                }
            }
        });

        setIsSubmitDisabled(disabled);
    };

    const addSecondsIfNeeded = (time) => {
        if (time.length === 5) {
            return time + ":00";
        }
        return time;
    };

    const saveSettings = () => {
        const newWeekTimes = weekDays?.map(day => ({
            id: day.id,
            restaurant: {
                id: day.restaurant.id,
            },
            day: day.day,
            openTime: addSecondsIfNeeded(day.openTime),
            closeTime: addSecondsIfNeeded(day.closeTime),
            closed: !day.open
        }));

        const postData = {
            "name": restaurantName,
            "defaultColumnDuration": timeColumns,
            "defaultReservationDuration": reservationDuration,
            "openClosingTimes": newWeekTimes
        }

        sendRequest('restaurant', 'PATCH', {}, postData).then((responseData) => {
            if (responseData.response.status === 200) {
                renderToast("Erfolgreich", "Daten aktualisiert", "success");
                postData.weekdays = postData.openClosingTimes;
                postData.openClosingTimes = undefined;
                updateDefaults(postData);
                getSettings();
            } else {
                renderToast("Ups, etwas ist schief gelaufen", "Fehler bei Aktualisieren der Restaurant Daten", "danger");
            }
        }).catch((error) => {
            console.error('Error saving reservation:', error);
        });
    };

    function getSettings() {
        const storedDefaults = localStorage.getItem('defaultValues');

        if (storedDefaults) {
            const parsedDefaults = JSON.parse(storedDefaults);
            setOldSettings(parsedDefaults);

            setRestaurantName(parsedDefaults.name);
            setReservationDuration(parsedDefaults.defaultReservationDuration);

            const weekDaysWithOpenProperty = parsedDefaults?.weekdays.map(day => ({
                ...day,
                open: !day.closed
            }));
            setWeekDays(weekDaysWithOpenProperty);
        }
    }

    useEffect(() => {
        getSettings();
    }, [defaultValues]);

    useEffect(() => {
        validateForm();
    }, [restaurantName, reservationDuration, timeColumns, oldSettings]);


    // PIN
    const [actualPin, setActualPin] = useState(null);
    const [showPinChange, setShowPinChange] = useState(false);

    const handleConfirmPinChange = (newPin, newPinTime, newPinTimeUnit) => {
        if (newPin?.length === 0) return;
        if (newPinTime <= 0) return;
        if (!newPinTimeUnit) return;

        // set the pin as a new value in the local storage
        localStorage.setItem("pin", newPin);
        localStorage.setItem("pinTime", newPinTime);
        localStorage.setItem("pinTimeUnit", newPinTimeUnit);

        setActualPin(newPin);

        // dispatch "pinChange" event to trigger state updates
        window.dispatchEvent(new Event('pinChange'));

        setShowPinChange(false);
    };

    useEffect(() => {
        const storedPin = localStorage.getItem("pin");
        if (storedPin && storedPin !== "") {
            setActualPin(storedPin);
        }
    }, []);

    return (
        <div>
            <AppBarV2></AppBarV2>
            {loading ? (
                <div className="full-screen-center d-flex justify-content-center align-items-center h-100 w-100 m-5">
                    <div className="centered-content">
                        <LoadingSpinner/>
                    </div>
                </div>
            ) : (
                <Container style={{paddingTop: 64 + 15}}>
                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <Form.Group controlId="formRestaurantName">
                                    <Form.Label>Restaurantname</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Beispiel Restaurant"
                                        value={restaurantName}
                                        onChange={(e) => {
                                            setRestaurantName(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={6} md={6} sm={12}>
                                <Form.Group controlId="formReservationDuration">
                                    <Form.Label>Standard Reservierungsdauer (in Stunden)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        min="0"
                                        value={reservationDuration}
                                        onChange={(e) => {
                                            setReservationDuration(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className={"mb-3"}>
                            <div className={"d-flex gap-3"}>
                                <div>
                                    <button
                                        className={"btn btn-outline-secondary rounded-2 px-4"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setShowPinChange(true);
                                        }}
                                    >
                                        PIN {actualPin ? "ändern" : "setzen"}
                                    </button>
                                </div>

                                <PinChangeModal
                                    show={showPinChange}
                                    setShow={setShowPinChange}
                                    confirm={handleConfirmPinChange}
                                />

                                {actualPin && (
                                    <div>
                                        <button
                                            className={"btn btn-outline-warning rounded-2 px-4"}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                localStorage.removeItem("pin");
                                                setActualPin(null);
                                                renderToast("Erfolgreich", "PIN gelöscht", "success");
                                            }}
                                        >
                                            PIN löschen
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Row>

                        <Row className="mb-3">
                            {
                                weekDays?.map((day, index) => (
                                    <Col key={index} className={"p-2"}>
                                        <Form.Group className="bg-light p-2 rounded-3">
                                            <Form.Label>{day.day}</Form.Label>
                                            <Form.Check
                                                type={"switch"}
                                                label={"geöffnet"}
                                                checked={day.open}
                                                onChange={(e) => {
                                                    const newWeekDays = [...weekDays];
                                                    newWeekDays[index].open = e.target.checked;
                                                    setWeekDays(newWeekDays);
                                                }}
                                            />
                                            {
                                                day.open && (
                                                    <Form>
                                                        <Form.Label className={"mt-3"}>Öffnungszeit</Form.Label>
                                                        <Form.Control
                                                            type="time"
                                                            value={day.openTime}
                                                            onChange={(e) => {
                                                                const newWeekDays = [...weekDays];
                                                                newWeekDays[index].openTime = e.target.value;
                                                                setWeekDays(newWeekDays);
                                                            }}
                                                        />
                                                        <Form.Label className={"mt-3"}>Schließzeit</Form.Label>
                                                        <Form.Control
                                                            type="time"
                                                            value={day.closeTime}
                                                            onChange={(e) => {
                                                                const newWeekDays = [...weekDays];
                                                                newWeekDays[index].closeTime = e.target.value;
                                                                setWeekDays(newWeekDays);
                                                            }}
                                                        />
                                                    </Form>
                                                )
                                            }
                                        </Form.Group>
                                    </Col>
                                ))
                            }
                        </Row>
                        <div className="d-flex justify-content-center mt-5 mb-4">
                            <Button variant="primary" onClick={saveSettings} disabled={isSubmitDisabled}
                                    className="btn-lg">
                                Speichern
                            </Button>
                        </div>
                    </Form>
                </Container>
            )}
        </div>
    );
};

export default Settings;