import {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import {FaInfoCircle} from "react-icons/fa";

let root = null;

function useInfo(bgColor = "primary", textColor = "white") {
    const [info, setInfo] = useState("");
    const [display, setDisplay] = useState("none");

    const renderInfo = (message = "") => {
        setInfo(message);
        setDisplay("flex")
    };

    const hideInfo = () => {
        setDisplay("none");
        setInfo("");
    }

    const handleClick = () => {
        window.location.href = "/contact";
    };

    useEffect(() => {
        if (!root) {
            root = ReactDOM.createRoot(
                document.getElementById('info-root')
            );
        }

        const infoContainer = (
            <div onClick={handleClick} className={`info-container d-${display} bg-${bgColor} justify-content-center align-items-center p-2`}>
                <FaInfoCircle className={`mx-2 text-${textColor}`} size={20}/>
                <span className={`text-${textColor} text-center fw-bold mx-2`}>
                    {info}
                </span>
            </div>
        );

        root.render(infoContainer);
    }, [info]);

    return {renderInfo, hideInfo};
}

export default useInfo;