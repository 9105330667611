import React from 'react';
import ReactDOM from 'react-dom';
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import PrintableTimeline from "../timelineV2/PrintableTimeline";
import {formatDateNumbers} from "../../utils/dateUtils";

const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
        <PrintableTimeline
            reservations={props.reservations}
            tables={props.tables}
            date={props.date}
            separatePages={props.separatePages}
            print={props.print}
        />
    </div>
));

export const generatePDF = (date, reservations, tables, separatePages, onFinished) => {
    const componentRef = React.createRef();
    const div = document.createElement("div");
    div.style.position = 'absolute';
    div.style.left = '-9999px'; // Move off-screen
    document.body.appendChild(div);

    const print = () => {
        const input = document.getElementById("printableTimeline");
        html2canvas(input, {scale: 1, useCORS: true})
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'l',
                    unit: 'mm',
                    format: 'a4'
                });

                // Dimensions of A4 page in mm
                const pageWidth = 297;
                const pageHeight = 210;

                // Calculate the number of pages
                const imgHeight = canvas.height * pageWidth / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                // Add new pages and split the image across pages
                pdf.addImage(imgData, 'PNG', 0, position, pageWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, pageWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save(`Reservations_${formatDateNumbers(date instanceof Date ? date : new Date(date.toDate()))}.pdf`);
                div.remove();
                onFinished();
            });
    };

    ReactDOM.render(
        <ComponentToPrint
            ref={componentRef}
            date={date}
            reservations={reservations}
            tables={tables}
            separatePages={separatePages}
            print={print}
        />,
        div
    );
};

