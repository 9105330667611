const Toast = ({title, message, variant, onClose}) => {
    return (
        <div className={`toast show m-2`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className={`toast-header text-bg-${variant}`}>
                <strong className="me-auto">{title}</strong>
                <button type="button" className={`btn-close text-bg-${variant}`} onClick={onClose}
                        data-bs-dismiss="toast"
                        aria-label="Close"></button>
            </div>
            <div className={`toast-body text-bg-${variant} rounded-bottom`}>
                {message}
            </div>
        </div>
    );
}

export default Toast;