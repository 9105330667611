import "./AuthScreen.css";
import React, {useEffect} from 'react';
import {Button} from "react-bootstrap";
import {InputWithIcon} from "../../components/inputWithIcon/InputWithIcon";
import {Key, Person} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import AuthScreen from "./AuthScreen";
import useToasts from "../../hooks/useToast";
import Cookies from "js-cookie";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {AiFillEyeInvisible} from "react-icons/ai";
import {BiSolidShow} from "react-icons/bi";

const LoginScreen = ({checkDemo}) => {
    const navigate = useNavigate();

    // state for storing the login credentials
    const [credentials, setCredentials] = React.useState({
        email: "",
        password: ""
    });

    const [showPassword, setShowPassword] = React.useState(false);
    const [showResendMailButton, setShowResendMailButton] = React.useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // useAPI hook for sending the login request
    const {loading, status, response, sendRequest} = useAPI();
    const onLogin = () => {
        // check if all fields are filled
        if (credentials.email === "" || credentials.password === "") {
            renderToast("Unvollständig", "Bitte fülle alle Felder aus", "danger")
            return;
        }

        // check if the email is valid
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!credentials.email.match(emailRegex)) {
            renderToast("Ungültige E-Mail", "Bitte überprüfe deine Eingabe", "danger")
            return;
        }

        const sendCredentials = {
            email: credentials.email.toLowerCase(),
            password: credentials.password
        }

        sendRequest("sign", "POST", {}, sendCredentials);
    }

    const resendMail = () => {
        // check if all fields are filled
        if (credentials.email === "") {
            renderToast("Unvollständig", "Bitte fülle das E-Mail Feld aus", "danger");
            return;
        }

        // check if the email is valid
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!credentials.email.match(emailRegex)) {
            renderToast("Ungültige E-Mail", "Bitte überprüfe deine Eingabe", "danger");
            return;
        }

        const sendCredentials = {
            email: credentials.email.toLowerCase(),
        }

        sendRequest("resend", "POST", {}, sendCredentials);
    }

    useEffect(() => {
        if (showResendMailButton) {
            setShowResendMailButton(false);
            if (response) {
                if (status === 201 || status === 200) {
                    renderToast("Erfolgreich", response.response.message, "success");
                } else {
                    renderToast("Ein Fehler ist aufgetreten", response.response.message, "danger");
                }
            }
        } else {
            setShowResendMailButton(false);
            if (response) {
                if (status === 200 && response.token) {
                    renderToast("Erfolgreich eingeloggt", response.message, "success");
                    // Convert the expiry string to a Date object
                    const expiryDate = new Date(response.expiry);
                    Cookies.set("token", response.token, {expires: expiryDate, path: '/'});
                    checkDemo();
                    navigate("/reservation");
                } else if (status === 409) {
                    renderToast("Ein Fehler ist aufgetreten", response.message, "danger");
                    setShowResendMailButton(true);
                } else if (status === 200) {
                    renderToast("Erfolgreich", response.message, "success");
                } else if (status === 404 || status === 401) {
                    renderToast("Ungültige Daten", response.message, "warning");
                }
            }
        }

    }, [loading]);

    useEffect(() => {
        checkDemo();

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("verifyMail")) {
            renderToast("Account angelegt",
                `Eine E-Mail wurde an ${urlParams.get("verifyMail")} gesendet. Bitte bestätige deine E-Mail Adresse.`,
                "success");
        }
    }, []);

    // handle the api response, set cookies, show toasts and navigate to the reservation page
    const {renderToast} = useToasts();

    return (
        <AuthScreen>
            <div>
                <div>
                    <h3 className={"mb-5 text-primary text-center"}>
                        <span className={"fs-5 me-1 d-block d-sm-inline"}>in</span>
                        <span className={"fw-bold text-uppercase d-block d-sm-inline"}>place<span
                            className={"text-dark"}>2</span>eat</span>
                        <span className={"fs-5 ms-1 d-block d-sm-inline"}>einloggen</span>
                    </h3>
                </div>
                <div className={"w-100"}>
                    <InputWithIcon
                        className={"mb-3"}
                        icon={<Person/>}
                        disabled={showResendMailButton}
                        type={"text"}
                        placeholder={"E-Mail"}
                        value={credentials.email}
                        onChange={(event) => setCredentials({...credentials, email: event.target.value})}
                    />
                    <InputWithIcon
                        icon={<Key/>}
                        showVisibleText={true}
                        disabled={showResendMailButton}
                        visibleTextIcon={showPassword ? <BiSolidShow/> : <AiFillEyeInvisible/>}
                        toggleVisibleTextIcon={togglePasswordVisibility}
                        type={showPassword ? "text" : "password"}
                        placeholder={"Passwort"}
                        value={credentials.password}
                        onChange={(event) => setCredentials({...credentials, password: event.target.value})}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onLogin();
                            }
                        }}
                    />
                </div>
                <Button variant={"primary"} className={"mt-5 w-100 text-white"} onClick={onLogin}>{
                    loading ?
                        <LoadingSpinner/>
                        :
                        "Anmelden"
                }</Button>

                {showResendMailButton &&
                    <Button variant={"primary"} className={"mt-3 w-100 text-white"} onClick={resendMail}>{
                        loading ?
                            <LoadingSpinner/>
                            :
                            "E-Mail erneut senden"
                    }</Button>
                }
                <div className={"mt-3 text-center"}>
                    <a className={"sign-up-text fw-500 text-decoration-none"} type={"button"}
                       onClick={() => navigate("../restorePassword")}>
                        <span className={"text-primary fw-bold"}>Passwort vergessen?</span>
                    </a>
                </div>
                <div className={"mt-1 text-center"}>
                    <a className={"sign-up-text fw-500 text-decoration-none"} type={"button"}
                       onClick={() => navigate("../signup")}>
                        <span className={"text-secondary me-1"}>Neu bei place2eat?</span>
                        <span className={"text-primary fw-bold"}>Hier registrieren</span>
                    </a>
                </div>
            </div>
        </AuthScreen>
    );

}
export default LoginScreen;




