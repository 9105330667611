import {useEffect, useState} from 'react';

const DEFAULT_AFK_TIMEOUT = 5 * 60 * 1000;

const useAFK = () => {
    const [isAfk, setIsAfk] = useState(false);
    const [afkTimeOut, setAfkTimeOut] = useState(DEFAULT_AFK_TIMEOUT);

    const fetchAfkTimeOut = () => {
        // get pinTime from the local storage
        const pinTime = localStorage.getItem("pinTime");
        // get pinTimeUnit from the local storage
        const pinTimeUnit = localStorage.getItem("pinTimeUnit");

        // calculate time in seconds
        switch (pinTimeUnit) {
            case "hours":
                setAfkTimeOut(pinTime * 60 * 60 * 1000);
                break;
            case "minutes":
                setAfkTimeOut(pinTime * 60 * 1000);
                break;
            case "seconds":
                setAfkTimeOut(pinTime * 1000);
                break;
            default:
                setAfkTimeOut(DEFAULT_AFK_TIMEOUT);
        }
    };

    useEffect(() => {
        window.addEventListener('pinChange', fetchAfkTimeOut);

        return () => {
            window.removeEventListener('pinChange', fetchAfkTimeOut);
        };
    }, [])

    useEffect(() => {
        fetchAfkTimeOut();
        let afkTimer = setTimeout(() => setIsAfk(true), afkTimeOut);

        const resetAfkTimer = () => {
            clearTimeout(afkTimer);
            setIsAfk(false);
            afkTimer = setTimeout(() => {
                setIsAfk(true);
            }, afkTimeOut);
        };

        // Include touchstart for touch devices
        window.addEventListener('mousemove', resetAfkTimer);
        window.addEventListener('keypress', resetAfkTimer);
        window.addEventListener('click', resetAfkTimer);
        window.addEventListener('touchstart', resetAfkTimer);

        return () => {
            clearTimeout(afkTimer);
            window.removeEventListener('mousemove', resetAfkTimer);
            window.removeEventListener('keypress', resetAfkTimer);
            window.removeEventListener('click', resetAfkTimer);
            window.removeEventListener('touchstart', resetAfkTimer);
        };
    }, [afkTimeOut]);

    return isAfk;
};

export default useAFK;