import React, {useState} from "react";
import {AppBar, Toolbar} from "@mui/material";
import {Button} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import SideDrawer from "../sidedrawer/SideDrawer";

const AppBarV2 = ({children}) => {
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <AppBar position="relative" className={"bg-secondary px-3 w-100 position-fixed"} style={{zIndex: 5}}>
            <Toolbar className={"d-flex justify-content-between"}>
                <div>
                    <Button className={"p-0"} style={{backgroundColor: 'transparent', border: 'none'}}
                            onClick={handleDrawerOpen}>
                        <Icon.List color="white" size={30}/>
                    </Button>
                </div>
                <SideDrawer open={open} onClose={handleDrawerClose}/>
                {children}
            </Toolbar>
        </AppBar>
    );
};

export default AppBarV2;