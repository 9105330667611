import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import LoginScreen from './pages/sign/Login';
import TableReservation from './pages/tableReservation/Tablereservation';
import Settings from './pages/settings/Settings';
import RestaurantEditor from './pages/restaurantEditor/Restauranteditor';
import SignupScreen from './pages/sign/Signup';
import Cookies from "js-cookie";
import useDefaults from "./hooks/useDefaults";
import MailVerifyScreen from "./pages/sign/MailVerifiyScreen";
import useInfo from "./hooks/useInfo";
import useAPI from "./hooks/useAPI";
import PasswordRestoreScreen from "./pages/sign/PasswordRestore";
import ContactScreen from "./pages/contact/ContactScreen";
import StatisticScreen from "./pages/statistic/StatisticScreen";
import LockOverlay from "./components/lock/LockOverlay";

function PrivateRoute({element}) {
    useEffect(() => {
        localStorage.removeItem('defaultValues');
    }, []);

    const {defaultValues} = useDefaults();

    return element;
}

function App() {
    const {renderInfo, hideInfo} = useInfo();
    const {sendRequest} = useAPI();

    useEffect(() => {
        checkDemo()
    }, []);

    const checkDemo = () => {
        if (!Cookies.get("token")) return;

        let restaurantId = null;
        sendRequest("restaurant").then((data) => {
            if (data?.response.status === 200) {
                restaurantId = data.data.id;
                if (restaurantId === 1) {
                    renderInfo("Du befindest dich im Demo Modus. Du kannst keine Änderungen vornehmen.");
                } else {
                    hideInfo();
                }
            } else {
                hideInfo();
            }
        }).catch(() => {
            hideInfo();
            Cookies.remove("token");
        });
    };

    const [showPinOverlay, setShowPinOverlay] = useState(true);

    return (
        <Router>
            <div className="App">
                <LockOverlay show={showPinOverlay} setShow={setShowPinOverlay}/>
                <header className="App-header" style={{backgroundColor: 'white'}}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/reservation"/>}/>
                        <Route path="/login" element={<LoginScreen checkDemo={checkDemo}/>}/>
                        <Route path="/signup" element={<SignupScreen checkDemo={checkDemo}/>}/>
                        <Route path="/restorePassword" element={<PasswordRestoreScreen checkDemo={checkDemo}/>}/>
                        <Route path="/mailverify" element={<MailVerifyScreen checkDemo={checkDemo}/>}/>
                        <Route
                            path="/reservation"
                            element={
                                <PrivateRoute
                                    path="/"
                                    element={<TableReservation/>}
                                />
                            }
                        />
                        <Route
                            path="/birdperspective"
                            element={
                                <PrivateRoute
                                    path="/"
                                    element={<TableReservation/>}
                                />
                            }
                        />
                        <Route
                            path="/restauranteditor"
                            element={
                                <PrivateRoute
                                    path="/"
                                    element={<RestaurantEditor/>}
                                />
                            }
                        />
                        <Route
                            path="/settings"
                            element={
                                <PrivateRoute
                                    path="/"
                                    element={<Settings/>}
                                />
                            }
                        />
                        <Route
                            path="/statistics"
                            element={
                                <PrivateRoute
                                    path="/"
                                    element={<StatisticScreen/>}
                                />
                            }
                        />
                        <Route
                            path="/contact"
                            element={
                                <PrivateRoute
                                    path="/"
                                    element={<ContactScreen/>}
                                />
                            }
                        />

                        <Route
                            path="/mailverify"
                            element={
                                <PrivateRoute
                                    path="/"
                                    element={<MailVerifyScreen checkDemo={checkDemo}/>}
                                />
                            }
                        />
                        <Route
                            path="/restorePassword"
                            element={
                                <PrivateRoute
                                    path="/"
                                    element={<PasswordRestoreScreen/>}
                                />
                            }
                        />
                    </Routes>
                </header>
            </div>
        </Router>
    );
}

export default App;
