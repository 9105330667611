import React, {useEffect, useRef, useState} from "react";
import useAPI from "../../hooks/useAPI";
import {GiTable} from "@react-icons/all-files/gi/GiTable";
import {convertUTCToLocalTime} from "../../utils/dateUtils";
import {Modal, Overlay, Popover} from 'react-bootstrap';
import {FaDoorClosed} from "@react-icons/all-files/fa/FaDoorClosed";
import {FaUser} from "@react-icons/all-files/fa/FaUser";
import {FaPhone} from "@react-icons/all-files/fa/FaPhone";
import {FaCommentDots} from "@react-icons/all-files/fa/FaCommentDots";
import {FaClock} from "@react-icons/all-files/fa/FaClock";
import "./TimelineV2.css";
import {GoPrimitiveDot} from "@react-icons/all-files/go/GoPrimitiveDot";
import {FaTrash} from "@react-icons/all-files/fa/FaTrash";
import useToast from "../../hooks/useToast";
import useDefaults from "../../hooks/useDefaults";
import {transformReservations} from "../../utils/reservationUtils";
import GeneratePDFButton from "../generatePDFButton/GeneratePDFButton";

const initialZoomLevel = 1;
const maxZoomLevel = 3;
const minZoomLevel = 0.5;
const initialHourWidth = 100;

const TimelineV2 = ({date, openModal, tableId, selectedAreas, updateReservations, areas}) => {
    const {sendRequest} = useAPI();
    const {renderToast} = useToast();

    const [zoomLevel, setZoomLevel] = useState(initialZoomLevel);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [reservations, setReservations] = useState([]);
    const [tables, setTables] = useState([]);
    const containerRefs = useRef([]);
    const hoursContainerRef = useRef(null);
    const [popoverShow, setPopoverShow] = useState(false);
    const [popoverTarget, setPopoverTarget] = useState(null);
    const [popoverContent, setPopoverContent] = useState(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [reservationToDelete, setReservationToDelete] = useState(null);
    const {defaultValues, getTodayCloseTime, getTodayOpenTime} = useDefaults();
    const [hours, setHours] = useState([]);

    useEffect(() => {
        updateReservations(reservations);
    }, [reservations]);

    const handleHourCellClick = (hour, table) => {
        const reservation = {
            startHour: hour,
            startMinute: 0,
            endHour: hour + 2,
            endMinute: 0,
            name: "",
            phone: "",
            comment: "",
            tableId: table.id,
            date: date,
            skipTimeZoneCorrection: true
        };
        openModal(reservation);
    };

    let initialTouchDistance = null;

    const handleZoom = (e) => {
        // Prevent zooming on shift + scroll
        if (!e.ctrlKey && !e.metaKey) {
            if (e.type !== 'touchmove' && e.touches?.length !== 2) {
                return;
            }
        }

        preventDefault: if (e.cancelable) {
            if (e.deltaX !== 0) break preventDefault;
            e.preventDefault(); // Prevent default action (e.g., browser zoom)
        }

        if (e.type === 'wheel') {
            // Handle wheel zoom
            const deltaY = e.deltaY;
            setZoomLevel(prevZoomLevel => {
                let newZoomLevel = prevZoomLevel - deltaY * 0.001;
                return Math.max(minZoomLevel, Math.min(newZoomLevel, maxZoomLevel));
            });
        } else if (e.type === 'touchmove' && e.touches.length === 2) {
            // Handle pinch zoom
            const currentTouchDistance = Math.sqrt(
                Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) +
                Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2)
            );

            if (initialTouchDistance === null) {
                initialTouchDistance = currentTouchDistance;
            } else {
                const deltaY = currentTouchDistance - initialTouchDistance;
                setZoomLevel(prevZoomLevel => {
                    let newZoomLevel = prevZoomLevel + deltaY * 0.01;
                    return Math.max(minZoomLevel, Math.min(newZoomLevel, maxZoomLevel));
                });
                initialTouchDistance = currentTouchDistance;
            }
        }
    };

    const handleScroll = (e) => {
        const scrollLeft = e.target.scrollLeft;
        hoursContainerRef.current.scrollLeft = scrollLeft;
        containerRefs.current.forEach(ref => {
            if (ref && ref !== e.target) {
                ref.scrollLeft = scrollLeft;
            }
        });
    };

    useEffect(() => {
        fetchTables();

        const handleUpdateResEvent = () => {
            fetchReservations();
        };
        document.addEventListener("updateReservations", handleUpdateResEvent);

        return () => {
            document.removeEventListener("updateReservations", handleUpdateResEvent);
        };
    }, [tableId, selectedAreas]);

    useEffect(() => {
        for (let i = 0; i < containerRefs.current.length; i++) {
            const ref = containerRefs.current[i];
            if (ref) {
                ref.addEventListener("wheel", handleZoom, {passive: false});
                ref.addEventListener("touchstart", (e) => {
                    if (e.touches.length === 2) {
                        initialTouchDistance = null;
                        if (e.cancelable) {
                            e.preventDefault();
                        }
                    }
                }, {passive: false});
                ref.addEventListener("touchmove", handleZoom, {passive: false});
                ref.addEventListener("touchend", () => {
                    initialTouchDistance = null;
                }, {passive: false});
            }
        }

        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            for (let i = 0; i < containerRefs.current.length; i++) {
                const ref = containerRefs.current[i];
                if (ref && ref.removeEventListener) {
                    ref.removeEventListener("wheel", handleZoom);
                    ref.removeEventListener("touchstart", handleZoom);
                    ref.removeEventListener("touchmove", handleZoom);
                    ref.removeEventListener("touchend", handleZoom);
                }
            }
            clearInterval(intervalId);
        };
    }, [tables]);

    const calculateHoursDifference = (openTime, closeTime) => {
        const hours = [];
        const openTimeParts = openTime.split(":");
        const closeTimeParts = closeTime.split(":");

        let openHours = parseInt(openTimeParts[0]);
        const openMinutes = parseInt(openTimeParts[1]);

        let closeHours = parseInt(closeTimeParts[0]);
        const closeMinutes = parseInt(closeTimeParts[1]);

        if (openMinutes !== 0) {
            openHours = openHours - 1;
        }
        if (closeMinutes !== 0) {
            closeHours = closeHours + 1;
        }
        for (let hour = openHours; hour <= closeHours; hour++) {
            hours.push(hour);
        }

        return hours;
    }

    useEffect(() => {
        if (defaultValues !== null && date !== null) {
            setHours(calculateHoursDifference(getTodayOpenTime(date?.toDate()), getTodayCloseTime(date?.toDate())));
        } else {
            setHours(calculateHoursDifference("00:00:00", "23:00:00"));
        }
    }, [defaultValues, date]);

    const currentDateRef = useRef(date);
    useEffect(() => {
        currentDateRef.current = date;
        fetchReservations();
    }, [date]);

    const fetchReservations = () => {
        const formattedDate = currentDateRef.current.format("YYYY-MM-DD");
        sendRequest(`reservations/resdate/${formattedDate}`).then(data => {
            setReservations(transformReservations(data.data));
        });
    };

    const fetchTables = () => {
        sendRequest("tables").then(data => {
            if (tableId) {
                data.data = data.data.filter(table => table.id === tableId);
                setTables(data.data)
            } else {
                let filteredTables = data.data;
                if (selectedAreas.length > 0) {
                    filteredTables = filteredTables.filter(table => {
                        return selectedAreas.includes(table.area.id);
                    });
                    setTables(filteredTables);
                } else {
                    setTables([]);
                }
            }
        });
    };

    const deleteReservation = (reservation) => {
        let endPoint;
        // check if group_id is there in reservation object
        if (reservation.groupid !== undefined) {
            endPoint = `reservations/area/${reservation.groupid}`;
        } else {
            endPoint = `reservations/${reservation.id}`
        }
        sendRequest(endPoint, "DELETE").then(() => {
            fetchReservations();
            setPopoverShow(false);
            renderToast("Reservierung gelöscht", "Die Reservierung von " + reservation.name + " wurde gelöscht.", "success");
            document.dispatchEvent(new Event("reservationDelete"));
        }).catch((error) => {
            renderToast(error.message, "error");
        });
    }

    const confirmDelete = (reservation) => {
        setReservationToDelete(reservation);
        setPopoverShow(false);
        setShowConfirmDeleteModal(true);
    };

    const onReservationClick = (reservation, event) => {
        const clickedElement = event.currentTarget;

        const showPopover = () => {
            setPopoverTarget(clickedElement);

            const today = new Date();
            const localStart = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDate(), reservation.startHour, reservation.startMinute));
            const localEnd = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDate(), reservation.endHour, reservation.endMinute));
            const timeString = `
            ${localStart.getHours().toString().padStart(2, '0')}:${localStart.getMinutes().toString().padStart(2, '0')} 
            - ${localEnd.getHours().toString().padStart(2, '0')}:${localEnd.getMinutes().toString().padStart(2, '0')}`;

            const content = (
                <>
                    <Popover.Header as={"h3"}>
                        <div className={"d-flex justify-content-between gap-4 py-2"}>
                            <div className={"d-flex gap-2 align-items-center lead"}>
                                <GoPrimitiveDot/><span>{reservation.tableNumber}</span>
                            </div>
                            <div>
                                <button className={"btn text-danger d-flex align-items-center p-1"}
                                        onClick={() => confirmDelete(reservation)}>
                                    <FaTrash/>
                                </button>
                            </div>
                        </div>
                    </Popover.Header>
                    <Popover.Body className={"p-3"}>
                        <div className={"reservationPopoverInfo d-flex flex-column gap-2"}>
                            <div className={"d-flex align-items-center gap-3"}>
                                <FaDoorClosed/><span>{reservation.area}</span>
                            </div>
                            <div className={"d-flex align-items-center gap-3"}>
                                <FaUser/><span>{reservation.name}</span>
                            </div>
                            <div className={"d-flex align-items-center gap-3"}>
                                <FaPhone/><span>{reservation.phone}</span>
                            </div>
                            <div className={"d-flex align-items-center gap-3"}>
                                <FaUser/><span>{reservation.personAmount}</span>
                            </div>
                            {reservation.comment &&
                                <div className={"d-flex align-items-center gap-3"}>
                                    <FaCommentDots/>{reservation.comment}
                                </div>
                            }
                            <div className={"d-flex align-items-center gap-3"}>
                                <FaClock/><span>{timeString}</span>
                            </div>
                        </div>
                        <div className={"mt-3 "}>
                            <button style={{minWidth: "100px", color: 'white'}} onClick={() => {
                                setPopoverShow(false);
                                openModal(reservation);
                            }} className={"w-100 btn-primary btn btn-sm rounded-0"}>Bearbeiten
                            </button>
                            <button
                                style={{minWidth: "100px", marginTop: '10px'}}
                                onClick={() => {
                                    setPopoverShow(false);
                                }}
                                className={"w-100 btn btn-sm btn-outline-secondary rounded-0"}>
                                Schließen
                            </button>
                        </div>
                    </Popover.Body>
                </>
            );
            setPopoverContent(content);
            setPopoverShow(true);
        };

        if (popoverShow) {
            // If a popover is already shown, delay showing the new popover
            setPopoverShow(false);
            setTimeout(showPopover, 150);
        } else {
            showPopover();
        }
    };

    const groupedTables = tables.reduce((acc, table) => {
        (acc[table.area.id] = acc[table.area.id] || []).push(table);
        return acc;
    }, {});

    const renderReservations = (tableId) => {
        return reservations
            .filter(reservation => +reservation.tableId === tableId)
            .map(reservation => {
                // Convert start and end times to local time
                const today = new Date();
                const localStart = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDay(), reservation.startHour, reservation.startMinute));
                const localEnd = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDay(), reservation.endHour, reservation.endMinute));

                const startPosition = ((localStart.getHours() + localStart.getMinutes() / 60) * initialHourWidth * zoomLevel) - hours[0] * initialHourWidth * zoomLevel;
                const endPosition = ((localEnd.getHours() + localEnd.getMinutes() / 60) * initialHourWidth * zoomLevel) - hours[0] * initialHourWidth * zoomLevel;

                const colors = reservation.areaColors;
                let backgroundStyle = {background: "#348AA7"};
                if (colors[0] !== undefined) {
                    if (colors.length > 1) {
                        const gradientColors = colors.map(color => `#${color}`).join(', ');
                        backgroundStyle = {
                            background: `repeating-linear-gradient(120deg, ${gradientColors} 0, ${gradientColors} 10px, transparent 10px, transparent 10px)`
                        };
                    } else if (colors.length === 1) {
                        backgroundStyle = {
                            background: `#${colors[0]}`
                        };
                    }
                }

                return (
                    <div
                        key={reservation.id}
                        id={"res" + reservation.id}
                        onClick={(event) => onReservationClick(reservation, event)}
                        className={"rounded-3 shadow1 align-self-center timeline-reservation"}
                        style={{
                            position: 'absolute',
                            left: `${startPosition}px`,
                            width: `${endPosition - startPosition}px`,
                            ...backgroundStyle
                        }}
                    >
                        <div className="text-white px-1"
                             style={{fontSize: "1.1em", paddingBlock: "0.1em"}}>
                            <div className={"gap-3 text-center text-truncate"}>
                                <span>({reservation.personAmount}) {reservation.name}</span>
                                {reservation.comment && <FaCommentDots className={"ms-2"}/>}
                            </div>
                        </div>
                    </div>
                );
            });
    };

    const calculateRefIndex = (localGroupedTables, groupIndex, areaTableIndex) => {
        let groupSizes = 0;
        for (let group = 0; group < groupIndex; group++) {
            groupSizes += Object.values(localGroupedTables)[group].length;
        }
        return groupSizes + areaTableIndex;
    };

    return (
        <>
            <div className={"mt-2"}>
                <Modal
                    show={showConfirmDeleteModal}
                    onHide={() => setShowConfirmDeleteModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Reservierung Löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Bist du sicher, dass du die Reservierung
                        von <strong>{reservationToDelete?.name}</strong> löschen
                        möchtest?
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setShowConfirmDeleteModal(false)}
                        >
                            Abbrechen
                        </button>
                        <button
                            className="btn btn-danger text-white"
                            onClick={() => {
                                deleteReservation(reservationToDelete);
                                setShowConfirmDeleteModal(false);
                            }}
                        >
                            Löschen
                        </button>
                    </Modal.Footer>
                </Modal>
                <Overlay
                    show={popoverShow}
                    target={popoverTarget}
                    placement={tableId ? "top" : "bottom"}
                    container={document.body}
                    onHide={() => setPopoverShow(false)}
                    rootClose
                >
                    <Popover id={`popover`} className={"rounded-2"} style={{minWidth: "250px"}}>
                        {popoverContent}
                    </Popover>
                </Overlay>
                {
                    (!tableId &&
                        <div className={'start-0 position-absolute z-3'}>
                            <GeneratePDFButton date={date}/>
                        </div>
                    )
                }
                <div
                    ref={hoursContainerRef}
                    className="d-flex overflow-auto scrollbar-hidden bg-white z-2"
                    style={
                        window.innerWidth < 768 ?
                            {borderLeft: "45px solid #fff", position: "sticky", top: 64}
                            :
                            {borderLeft: "175px solid #fff", position: "sticky", top: 64}
                    }
                    onScroll={handleScroll}
                >
                    {hours.map(hour => {
                            let width = initialHourWidth * zoomLevel;
                            if (hour === hours[hours.length - 1]) {
                                width += +32;
                            }
                            return (
                                <div
                                    key={hour}
                                    style={{
                                        width: `${width}px`,
                                        boxSizing: "border-box",
                                        flexShrink: 0,
                                    }}
                                >
                                    {hour < 10 ? "0" + hour : hour}:00
                                </div>
                            );
                        }
                    )}
                </div>
                <div className={"mt-2 d-flex justify-content-center align-items-center "}>
                    {
                        tables.length === 0 &&
                        <p className="text-center" style={{fontWeight: 'bold'}}>Keine Tische verfügbar</p>
                    }
                </div>

                {Object.entries(groupedTables).map(([areaId, tables], groupIndex) => (
                    <div key={areaId} className={"mb-4"}>
                        <div className="lead bg-light p-2 border-top border-1">
                            {tables[0].area.area}
                        </div>
                        {tables.map((table, index) => (
                            <div className="d-flex" key={table.id}>
                                <div className={"d-flex py-2 ms-2 pe-2"}>
                                    <GiTable className={"d-none d-md-block"}
                                             style={{position: "relative", bottom: "3px"}}
                                             size={20}/>
                                    <span style={{width: "150px"}}
                                          className={"ms-2 text-truncate d-none d-md-block"}>{table.tableNumber}</span>
                                    <span className={"d-block d-md-none"}
                                          style={{width: "50px"}}>{table.shortForm}</span>
                                </div>
                                <div
                                    ref={(el) => containerRefs.current[calculateRefIndex(groupedTables, groupIndex, index)] = el}
                                    className="position-relative vw-100 overflow-auto scrollbar-hidden"
                                    onScroll={handleScroll}
                                >

                                    <div className="d-flex h-100 w-100" style={{
                                        borderLeft: "1px solid rgb(0,0,0,0.1)",
                                    }}>
                                        {hours.map((hour, hi) => (
                                            <div
                                                key={hour}
                                                id={`hourCell-${index}-${hi}`}
                                                className={"py-2 hourCell"}
                                                onClick={() => handleHourCellClick(hour, table)}
                                                style={{
                                                    width: `${initialHourWidth * zoomLevel}px`,
                                                    borderRight: "1px solid rgb(0,0,0,0.1)",
                                                    borderBottom: "1px solid rgb(0,0,0,0.1)",
                                                    borderTop: index === 0 ? "1px solid rgb(0,0,0,0.1)" : "none",
                                                    flexShrink: 0
                                                }}
                                            >
                                            </div>
                                        ))}
                                        {renderReservations(+table.id)}
                                    </div>
                                    <div
                                        className="position-absolute bg-danger"
                                        style={{
                                            left: `${(currentTime.getHours() + currentTime.getMinutes() / 60) * initialHourWidth * zoomLevel - hours[0] * initialHourWidth * zoomLevel}px`,
                                            top: 0,
                                            bottom: 0,
                                            width: '2px',
                                        }}
                                    ></div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {!tableId && <div style={{height: `60px`}}></div>}
        </>
    );
};

export default TimelineV2;
