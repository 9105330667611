import {useEffect, useState} from 'react';
import useAPI from "./useAPI";
import useToasts from "./useToast";

function useDefaults() {
    const [defaultValues, setDefaultValues] = useState(null);
    const {loading, status, response = "", sendRequest} = useAPI();
    const {renderToast} = useToasts();

    useEffect(() => {
        const fetchDefaults = async () => {
            const storedDefaults = localStorage.getItem('defaultValues');

            if (storedDefaults) {
                setDefaultValues(JSON.parse(storedDefaults));
            } else {
                try {
                    const data = await sendRequest(`restaurant`, 'GET');
                    if (data !== undefined) {
                        if (data.response.status === 201 || data.response.status === 200) {
                            data.data.setting.weekdays = data.data.setting.openClosingTimes;
                            data.data.setting.openClosingTimes = undefined;
                            setDefaultValues(data.data.setting);
                            localStorage.setItem('defaultValues', JSON.stringify(data.data.setting));
                        } else {
                            renderToast("Standart Wert abfragen fehlgeschlagen", "", "danger");
                        }
                    }
                } catch (error) {
                    console.error("Error, when get Restaurant Default valuess:", error);
                }
            }
        };

        fetchDefaults();
    }, []);

    const getTodayOpenTime = (date) => {
        if (!date) return "00:00:00";

        let day = date.getDay() - 1;
        day = day < 0 ? 6 : day;
        const returnVal = defaultValues?.weekdays[day]?.openTime;

        if (!returnVal) return "00:00:00";
        return returnVal;
    };

    const getTodayCloseTime = (date) => {
        if (!date) return "23:00:00";

        let day = date.getDay() - 1;
        day = day < 0 ? 6 : day;
        const returnVal = defaultValues?.weekdays[day]?.closeTime;

        if (!returnVal) return "23:00:00";
        return returnVal;
    }

    const isClosedOnDate = (date) => {
        if (!date) return false;
        let day = date.getDay() - 1;
        if (day < 0) day = 6;
        return defaultValues?.weekdays[day]?.closed;
    };

    const updateDefaults = (newDefaults) => {
        setDefaultValues(newDefaults);

        localStorage.setItem('defaultValues', JSON.stringify(newDefaults));
    };

    return {defaultValues, updateDefaults, getTodayCloseTime, getTodayOpenTime, isClosedOnDate};
}

export default useDefaults;
