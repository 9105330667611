export const transformReservations = (reservations) => {
    const transformedReservations = [];
    let uniqueId = 0;

    reservations.forEach(reservation => {
        const tableGroup = reservation.tables.length > 1 ? uniqueId : null;
        let addHour = 0;
        const uniqueColors = new Set();

        reservation.tables.forEach(table => {
            uniqueColors.add(table.color);
        });

        reservation.tables.forEach(table => {
            // if hour starts at half time
            if (((new Date(reservation.date).getMinutes() + reservation.minutes % 60) / 60) >= 1) {
                addHour = 1;
            }
            const transformedReservation = {
                area: table.area,
                areaId: table.areaId.toString(),
                endHour: (new Date(reservation.date).getHours() + Math.floor(reservation.minutes / 60)) + addHour,
                endMinute: (new Date(reservation.date).getMinutes() + reservation.minutes) % 60,
                phone: reservation.phone,
                startHour: new Date(reservation.date).getHours(),
                startMinute: new Date(reservation.date).getMinutes(),
                name: reservation.name,
                tableId: table.id.toString(),
                personAmount: reservation.personAmount.toString(),
                comment: reservation.comment,
                id: reservation.id.toString(),
                tableNumber: table.tableNumber,
                reservationDate: reservation.date,
                areaColors: Array.from(uniqueColors)
            };
            if (tableGroup !== null) {
                transformedReservation.tableGroup = tableGroup;
                uniqueId++;
            }
            addHour = 0;
            transformedReservations.push(transformedReservation);
        });
    });
    return transformedReservations;
};