import "./InputWithIcon.css";

export const InputWithIcon = ({
                                  icon,
                                  type,
                                  placeholder,
                                  value,
                                  onChange,
                                  className,
                                  onKeyDown,
                                  showVisibleText,
                                  visibleTextIcon,
                                  toggleVisibleTextIcon,
                                  disabled
                              }) => {
    return (
        <div className={`inputWithIcon d-flex pb-1 ${className}`}>
            <span className={"text-center ms-1 me-2 icon d-flex justify-content-center"}>{icon}</span>
            <input className={"border-0 w-100"} type={type} placeholder={placeholder} value={value} onChange={onChange}
                   onKeyDown={onKeyDown} disabled={disabled}/>
            {showVisibleText && <span onClick={toggleVisibleTextIcon}
                                      className={"text-center ms-1 me-2 icon d-flex justify-content-center"}>{visibleTextIcon}</span>}
        </div>
    );
};