import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {LuBird} from "react-icons/lu";
import {CiViewTimeline} from "react-icons/ci";
import AppBarV2 from "../../components/appbarV2/AppBarV2";
import {IoSearch} from "react-icons/io5";
import SearchFieldModal from "../../components/search/SearchField";

function TableReservationAppBar({onDateChange, view, setView}) {
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [showSearchField, setShowSearchField] = useState(false);

    const handleLeftArrowClick = () => {
        setSelectedDate((prevDate) => prevDate.subtract(1, 'day'));
        onDateChange((prevDate) => prevDate.subtract(1, 'day'));
    };

    const handleRightArrowClick = () => {
        setSelectedDate((prevDate) => prevDate.add(1, 'day'));
        onDateChange((prevDate) => prevDate.add(1, 'day'));

    };

    const toggleView = () => {
        if (view === "bird") {
            setView("timeline")
        } else {
            setView("bird")
        }
    }

    const handleDatePickerClick = (date) => {
        setSelectedDate(date)
        onDateChange(date);
    };

    // function to set the date to today
    const handleTodayClick = () => {
        setSelectedDate(dayjs());
        onDateChange(dayjs());
    };

    return (
        <>
            <SearchFieldModal show={showSearchField} onHide={() => setShowSearchField(false)} />
            <AppBarV2>
                <div className={"d-flex gap-2 mx-2 mx-md-0"}>
                    <Button
                        variant={"outline-light"}
                        onClick={() => setShowSearchField(true)}
                        className={"rounded-2 p-1 px-2 d-flex align-items-center"}>
                        Suchen <IoSearch style={{ marginLeft: '5px' }} />
                    </Button>
                    <div className={"d-flex"}>
                        <Button
                            variant={"link"}
                            onClick={handleLeftArrowClick}
                            style={{ padding: "0px 5px 0px 0px" }}
                        >
                            <Icon.ArrowLeft color="white" size={25}/>
                        </Button>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de-AT'>
                            <div style={{
                                backgroundColor: 'white',
                                height: '40px',
                                display: 'flex',
                                border: 'none',
                                alignItems: 'center',
                                overflow: 'hidden',
                                minWidth: '100px',
                            }}>
                                <DatePicker
                                    sx={{maxWidth: 350}}
                                    style={{width: '100%'}}
                                    format="dddd, DD.MM.YYYY"
                                    value={selectedDate}
                                    onChange={(date) => handleDatePickerClick(date)}
                                />
                            </div>
                        </LocalizationProvider>
                        <Button
                            style={{ padding: "0px 0px 0px 5px" }}
                            variant={"link"}
                            onClick={handleRightArrowClick}>
                            <Icon.ArrowRight color="white" size={25}/>
                        </Button>
                    </div>
                    <Button
                        variant={"outline-light"}
                        onClick={handleTodayClick}
                        className={"rounded-2 p-1 px-2"}>
                        Heute
                    </Button>
                </div>

                <div>
                    <Button
                        variant={"outline-light"}
                        onClick={toggleView}
                        className={"rounded-2 p-1"}>
                        {view === "bird" ? <CiViewTimeline size={25}/> : <LuBird size={25}/>}
                    </Button>
                </div>
            </AppBarV2>
        </>
    );
}

export default TableReservationAppBar;
