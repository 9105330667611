export const generateShortName = (tableName) => {
    let shortName = '';
    const wordsArray = tableName.split(' ');

    if (wordsArray.length === 1) {
        let upperCaseElement = "";
        // Skip the first element because that's often a upperCase letter
        for (var pos = 1; pos < wordsArray[0].length; pos++) {
            if (isUpperCase(wordsArray[0][pos])) {
                upperCaseElement = wordsArray[0][pos];
                break;
            }
        }

        if (upperCaseElement !== "") {
            shortName = wordsArray[0].slice(0, 2) + upperCaseElement;
        } else {
            shortName = wordsArray[0].slice(0, 3);
        }
    }

    if (wordsArray.length === 2) {
        shortName = wordsArray[0].slice(0, 1) + wordsArray[1].slice(0, 2);
    }
    return shortName;
};

function isUpperCase(letter) {
    return letter === letter.toUpperCase();
}

