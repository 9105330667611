import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import useAPI from '../../hooks/useAPI';
import useToasts from "../../hooks/useToast";
import {generateShortName} from "../../utils/shortNameGeneratorUtils";

const EditTableAlert = (props) => {
    const {renderToast} = useToasts();

    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [capacity, setCapacity] = useState(0);

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const {loading, error, status, response, sendRequest} = useAPI();


    useEffect(() => {
        if (!props.show) return;

        setName(props.tableData.table.tableNumber);
        setShortName(props.tableData.table.shortForm);
        setCapacity(props.tableData.table.capacity);
    }, [props.show]);

    useEffect(() => {
        setName("");
        setShortName("");
        setCapacity(0);
    }, [props.hide]);

    const areAllFieldsFilledAndDifferent = () => {
        return (
            name !== '' && shortName !== '' && capacity !== '' &&
            (props.tableData.table.tableNumber !== name ||
                props.tableData.table.shortForm !== shortName ||
                props.tableData.table.capacity !== capacity)
        );
    };

    const handleCapacityChange = (e) => {
        setCapacity(e.target.value);
    };

    const handleSave = () => {

        const isSaveDisabled = !areAllFieldsFilledAndDifferent();
        setIsSaveButtonDisabled(isSaveDisabled);

        const patchData = {
            id: props.tableData.table.id,
            area: {
                id: props.tableData.table.area.id
            },
            capacity: capacity,
            tableNumber: name,
            shortForm: shortName
        };

        sendRequest(`tables/${props.tableData.table.id}`, 'PATCH', {},
            patchData).then((data) => {
            props.onHide();
        }).catch((error) => {
            renderToast("Fehler beim bearbeiten des Tisches", error.message, "danger");
        })
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);

        const generatedShortName = generateShortName(newName);
        setShortName(generatedShortName);
    };

    const handleShortNameChange = (e) => {
        const shortName = e.target.value;
        if (shortName.length <= 3) {
            setShortName(shortName);
        }
    }

    useEffect(() => {
        const isSaveDisabled = !areAllFieldsFilledAndDifferent();
        setIsSaveButtonDisabled(isSaveDisabled);
    }, [name, shortName, capacity]);


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tisch bearbeiten
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Tischname</Form.Label>
                        <Form.Control
                            type="name"
                            placeholder="Tisch 0"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Tischname Abkürzung</Form.Label>
                        <Form.Control
                            type="name"
                            placeholder="T 0"
                            value={shortName}
                            onChange={handleShortNameChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Plätze</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="0"
                            min={"0"}
                            value={capacity}
                            onChange={handleCapacityChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={"secondary"}
                    className={"border-0"}
                    onClick={props.onHide}
                >
                    Schließen
                </Button>
                <Button
                    variant={"success"}
                    className={"border-0"}
                    type="submit"
                    onClick={handleSave}
                    disabled={isSaveButtonDisabled}
                >
                    Speichern
                </Button>{' '}
            </Modal.Footer>
        </Modal>
    );
};

export default EditTableAlert;
