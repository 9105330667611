import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker, TimeField} from '@mui/x-date-pickers';
import useAPI from '../../hooks/useAPI';
import {convertUTCToLocalTime} from "../../utils/dateUtils";
import useToasts from "../../hooks/useToast";
import useDefaults from "../../hooks/useDefaults";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import {IoMdArrowDropdown} from "react-icons/io";
import {transformReservations} from "../../utils/reservationUtils";
import TableSelect from "./MultipleSelectChip";
import {BsFillTrashFill} from "react-icons/bs";

const ReservationAlert = (props) => {
    const {renderToast} = useToasts();

    const initialDate = dayjs();
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [comment, setComment] = useState('');
    const [startTime, setStartTime] = useState(initialDate);
    const [selectedTables, setSelectedTables] = useState([]);
    const [reservedTables, setReservedTables] = useState([]);
    const [personAmount, setPersonAmount] = useState("");
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const {loading, error, status, response, sendRequest} = useAPI();
    const [tables, setTables] = useState([]);
    const [durationInput, setDurationInput] = useState(2);
    const {defaultValues} = useDefaults();
    const [canSelectArea, setCanSelectArea] = useState(false);
    const [selectedArea, setSelectedArea] = useState("");
    const [reservations, setReservations] = useState(props.reservations);

    useEffect(() => {
        if (props.show && (props.editingReservation.phone === "" || props.editingReservation.phone === undefined)) {
            getLastPhoneNumber();
        }
        if (!props.editingReservation) return;

        const today = new Date();
        // check if the editingReservation has no startHour, startMinute, endHour or endMinute
        // if so, set the current time as default
        if (props.editingReservation.startHour === undefined) {
            props.editingReservation.startHour = today.getHours();
        }
        if (props.editingReservation.startMinute === undefined) {
            props.editingReservation.startMinute = today.getMinutes();
        }
        if (props.editingReservation.endHour === undefined) {
            props.editingReservation.endHour = today.getHours() + 2;
        }
        if (props.editingReservation.endMinute === undefined) {
            props.editingReservation.endMinute = today.getMinutes();
        }
        const localStart = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDate(), props.editingReservation.startHour, props.editingReservation.startMinute));
        setStartTime(dayjs(localStart));

        // calc the duration of the reservation
        const localEnd = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDate(), props.editingReservation.endHour, props.editingReservation.endMinute));
        const durationHours = dayjs(localEnd).diff(dayjs(localStart), 'hour');
        const durationMinutes = dayjs(localEnd).diff(dayjs(localStart), 'minute') % 60;
        const minutesInHours = durationMinutes / 60;

        // check if it is a new reservation
        if (props.editingReservation.personAmount === undefined) {
            // get from localstorage defaultReservationDuration
            setDurationInput(+defaultValues?.defaultReservationDuration || 2);
        } else {
            // set the duration of the reservation based on the start and end time
            setDurationInput(durationHours + minutesInHours);
        }

        if (props.editingReservation.tableGroup !== undefined) {
            const relatedReservations = findGroupReservations(props.reservations, props.editingReservation.tableGroup);
            setSelectedTables(relatedReservations);
            setReservedTables(relatedReservations);
        } else {
            setSelectedTables([props.editingReservation.tableId]);
            setReservedTables([props.editingReservation.tableId]);
        }
        // Check if there is an reservation date, if not set the current date that is passed as props
        if (props.editingReservation?.reservationDate) {
            // when we have a reservation not today then we need to set it from the reservation
            setSelectedDate(dayjs(props.editingReservation.reservationDate));
        } else {
            setSelectedDate(props.date);
        }
        setName(props.editingReservation.name);
        setPhoneNumber(props.editingReservation.phone);
        setComment(props.editingReservation.comment);
        setPersonAmount(props.editingReservation.personAmount);
    }, [props.editingReservation, props.show]);

    const findGroupReservations = (reservations, tableGroup) => {
        return reservations
            .filter(reservation => reservation.tableGroup === tableGroup)
            .map(reservation => reservation.tableId);
    };

    const areAllFieldsFilled = () => {
        if (canSelectArea) {
            return !!name && !!phoneNumber && !!selectedDate && !!startTime && !!durationInput && !!selectedArea && !!personAmount;
        }
        return !!name && !!phoneNumber && !!selectedDate && !!startTime && !!durationInput && !!selectedTables && !!personAmount && selectedTables.length > 0;
    };

    const validateDurationInput = (input) => {
        return /^-?\d*(\.\d*)?$/.test(input);
    };

    const handleDurationInputChange = (e) => {
        const inputValue = e.target.value;

        if (validateDurationInput(inputValue)) {
            setDurationInput(inputValue);
        }
    };

    async function getTables() {
        try {
            const data = await sendRequest(`tables`, 'GET');
            setTables(data.data);
        } catch (error) {
            renderToast("Fehler beim Laden der Tische", error.message, "danger");
        }
    }

    async function getLastPhoneNumber() {
        try {
            sendRequest(`reservations/phone`, 'GET').then((data) => {
                if (data.response.status === 200) {
                    if (data.data.phone !== null) {
                        setPhoneNumber(data.data.phone);
                    }
                    if (data.data.name !== null) {
                        setName(data.data.name);
                    }
                }
            });
        } catch (error) {
            console.error("error when get last phone number ", error);
        }
    }

    const handleSave = () => {

        const formattedDate = selectedDate.format('YYYY-MM-DD');
        const formattedTime = startTime.format('HH:mm');

        // Example Time Stamp: 2023-10-06T08:48:22.009Z
        const date = `${formattedDate}T${formattedTime}:00.009Z`;

        const isSaveDisabled = !areAllFieldsFilled();
        setIsSaveButtonDisabled(isSaveDisabled);

        let postData = {};

        if (canSelectArea === true && selectedArea !== "") {
            postData = {
                date: date,
                minutes: durationInput * 60,
                name: name,
                phone: phoneNumber,
                comment: comment,
                personAmount: personAmount
            };
            postData.area = {
                id: selectedArea,
            };
        } else {
            postData.reservation = {
                date: date,
                minutes: durationInput * 60,
                name: name,
                phone: phoneNumber,
                comment: comment,
                personAmount: personAmount
            };
            // need to parse to string otherwise backend will not process the request
            postData.tableIds = selectedTables.map(tableId => String(tableId));
        }

        const updateResEvent = new CustomEvent('updateReservations');
        const notAcceptedStatusCode = 406;
        let isOverlapping = false;

        if (props.editingReservation?.id) {
            postData.id = props.editingReservation.id;
            sendRequest(`reservations/${props.editingReservation.id}`, 'PATCH', {}, postData).then((data) => {
                document.dispatchEvent(updateResEvent);
                if (data.response.status === notAcceptedStatusCode) {
                    isOverlapping = true;
                    renderToast("Überschneidung", "Es liegt bereits eine Reservierung in diesen Zeitbereich vor", "danger");
                }
            }).catch((error) => {
                console.error("error when updating reservation ", error);
            }).then(() => {
                if (!isOverlapping) {
                    props.onHide();
                }
            });
        } else {
            const endPoint = (canSelectArea === true && selectedArea !== "")
                ? "reservations/area"
                : "reservations";
            sendRequest(endPoint, 'POST', {}, postData).then((data) => {
                document.dispatchEvent(updateResEvent);
                if (data.response.status === notAcceptedStatusCode) {
                    isOverlapping = true;
                    renderToast("Überschneidung", "Es liegt bereits eine Reservierung in diesen Zeitbereich vor", "danger");
                }
            }).catch((error) => {
                console.error("error when creating reservation ", error);
            }).then(() => {
                if (!isOverlapping) {
                    props.onHide();
                }
            });
        }
    };

    useEffect(() => {
        if (props.show) return;

        getTables();
        setSelectedDate(initialDate);
        setName('');
        setPhoneNumber('');
        setComment('');
        setSelectedTables([]);
        setReservedTables([]);
        setDurationInput(2);
        setCanSelectArea(false);
        setSelectedArea("");
        setPersonAmount("");
        setPhoneNumberSuggestions([]);
        props.setEditingReservation({});
    }, [props.show]);

    useEffect(() => {
        const isSaveDisabled = !areAllFieldsFilled();

        // if the selected value of #tableSelectForm is disabled, disable the save button
        const selectForm = document.getElementById('tableSelectForm');
        if (selectForm) {
            const selectedOption = selectForm.options[selectForm.selectedIndex];
            if (selectedOption && selectedOption.disabled) {
                setIsSaveButtonDisabled(true);
                return;
            }
        }

        setIsSaveButtonDisabled(isSaveDisabled);
    }, [name, phoneNumber, selectedDate, startTime, durationInput, selectedTables, selectedArea, canSelectArea, personAmount]);

    useEffect(() => {
        if (defaultValues !== null) {
            setDurationInput(+defaultValues?.defaultReservationDuration || 2);
        }

    }, [defaultValues]);

    useEffect(() => {
        // Set the first entry of the areas as default for selected area but check if available
        // And check if there is no area selected yet
        if (props.areas.length > 0 && canSelectArea === true && selectedArea === "") {
            setSelectedArea(props.areas[0].id);
        }
    }, [canSelectArea]);

    const handlePersonAmountChange = (e) => {
        const input = e.target.value;
        if (/^\d*$/.test(input)) {
            setPersonAmount(input);
        }
    };

    useEffect(() => {
        if (selectedTables.length === 0) return;
        if (canSelectArea) return;
        if (props.editingReservation.personAmount !== undefined) return;

        const selectedTablesCapacities = selectedTables.map(tableId => {
            const table = tables.find(table => table.id === tableId);
            return table ? table.capacity : 0; // Wenn Tisch nicht gefunden, Kapazität als 0 betrachten
        });

        const totalCapacity = selectedTablesCapacities.reduce((acc, capacity) => acc + capacity, 0);

        setPersonAmount(totalCapacity);
    }, [selectedTables]);

    function isPersonAmountMoreThenSeats() {
        if (canSelectArea) return false;
        if (selectedTables.length === 0) return false;
        if (personAmount === "") return false;

        const selectedTablesCapacities = selectedTables.map(tableId => {
            const table = tables.find(table => table.id === parseInt(tableId));
            return table ? table.capacity : 0;
        });
        const totalCapacity = selectedTablesCapacities.reduce((acc, capacity) => acc + capacity, 0);

        return personAmount > totalCapacity;
    }

    const [phoneNumberSuggestions, setPhoneNumberSuggestions] = useState([]); // Beispiel-Datenarray

    const handlePhoneNumberInputChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleSelectPhoneNumberSuggestion = (selectedSuggestion) => {
        setPhoneNumber(selectedSuggestion);
    };

    const getPhoneNumberSuggestions = (name) => {
        if (name === "") return;
        sendRequest(`reservations/autofillphone/${name}`, 'GET').then((data) => {
            if (data.response.status === 200) {
                setPhoneNumberSuggestions(data.data);
                if (data.data.length > 0) {
                    setPhoneNumber(data.data[0]);
                }
            }
        }).catch((error) => {
            console.error("error when get suggestions ", error);
        });
    }

    useEffect(() => {
        setReservations(props.reservations);
    }, [props.reservations]);

    useEffect(() => {
        if (props.show) {
            getReservations();
        }
    }, [selectedDate]);

    function getReservations() {
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        sendRequest(`reservations/resdate/${formattedDate}`).then(data => {
            setReservations(transformReservations(data.data));
        });
    }

    function isReservationOverlap(startTime, endTime, tableID) {
        if (reservations === null || reservations.length === 0 || !props.show) {
            return false;
        }

        startTime = startTime.toDate();
        endTime = endTime.toDate();

        startTime.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
        endTime.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

        for (let i = 0; i < reservations.length; i++) {
            const reservation = reservations[i];

            const existingReservationStartTime = new Date(startTime);
            existingReservationStartTime.setHours(reservation.startHour, reservation.startMinute, 0, 0);
            const existingReservationEndTime = new Date(endTime);
            existingReservationEndTime.setHours(reservation.endHour, reservation.endMinute, 0, 0);
            if ((startTime < existingReservationEndTime && endTime > existingReservationStartTime) &&
                reservation.tableId == tableID) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if (!props.show) return;
        if (props.editingReservation?.id) return;
        if (selectedTables.length === 0) return;
        // check if one table from selectedTables is overlapping and when true then clear the selectedTables
        const isOverlapping = selectedTables.some(tableId => isReservationOverlap(startTime, startTime.add(durationInput, 'hour'), tableId));
        if (isOverlapping) {
            setSelectedTables([]);
        }
    }, [selectedDate, startTime, durationInput]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Reservierung hinzufügen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"reservationAlertModalBody"}>
                <Form>
                    <div className={"d-flex gap-2"}>
                        <Form.Group className="mb-3 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                // get phone number suggestions when tap outside of textfield
                                onBlur={() => getPhoneNumberSuggestions(name)}
                                type="name"
                                placeholder="Max Mustermann"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required={true}
                                size="lg"
                            />
                        </Form.Group>
                        <Form.Group className="mb-4 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Telefonnummer <span className="text-danger">*</span></Form.Label>
                            <div className="input-group mb-3">
                                <Form.Control
                                    type="text"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberInputChange}
                                    required={true}
                                    size="lg"
                                />
                                <span className="input-group-text" id="basic-addon2">
                                    {phoneNumber ? (
                                        <BsFillTrashFill
                                            className="ml-2"
                                            style={{
                                                cursor: 'pointer',
                                                color: 'red',
                                                fontSize: '20px',
                                                borderRadius: "0"
                                            }}
                                            onClick={() => setPhoneNumber('')}
                                        />
                                    ) : (
                                        <BsFillTrashFill
                                            className="ml-2"
                                            style={{
                                                color: 'grey',
                                                fontSize: '20px',
                                            }}
                                        />
                                    )}
                                </span>
                                {phoneNumberSuggestions.length > 0 && (
                                    <span className="input-group-text" id="basic-addon2">
                                        <Dropdown className="ml-2">
                                            <Dropdown.Toggle
                                                variant="outline-secondary"
                                                id="dropdown-basic"
                                                style={{
                                                    border: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: 'black',
                                                    padding: '0'
                                                }}
                                            >
                                                    <IoMdArrowDropdown
                                                        style={{fontSize: '1.5rem', fontWeight: 'bold'}}/>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {phoneNumberSuggestions.map((suggestion, index) => (
                                                    <Dropdown.Item
                                                        style={{backgroundColor: 'transparent'}}
                                                        key={index}
                                                        onClick={() => handleSelectPhoneNumberSuggestion(suggestion)}
                                                    >
                                                        {suggestion}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>
                                )}
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: '5px'}}
                         className={"gap-2 mb-3 "}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de-AT'>
                            <DatePicker
                                label="Datum"
                                format="dd, DD.MM.YYYY"
                                value={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                style={{height: '100%'}}
                                sx={{maxWidth: 300}}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de-AT'>
                            <TimeField
                                label="Startzeit"
                                value={startTime}
                                onChange={(date) => setStartTime(date)}
                                format="HH:mm"
                                style={{height: '100%', minWidth: '120px', maxWidth: '120px'}}
                            />
                        </LocalizationProvider>
                        <Form.Group controlId="exampleForm.ControlInput1"
                                    className={"d-flex gap-2 ms-4 align-items-center"}>
                            <Form.Label className={"mb-0 text-center"} style={{alignItems: "center", fontSize: "1em"}}>Dauer
                                (in Stunden)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder=""
                                onFocus={(e) => e.target.select()}
                                value={durationInput}
                                style={{minWidth: "60px", maxWidth: "100px", height: "100%"}}
                                onChange={handleDurationInputChange}
                                min={0.1}
                            />
                        </Form.Group>
                    </div>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3" controlId="tableSelectForm">
                                {canSelectArea === true ?
                                    <div>
                                        <Form.Label>Raum auswählen <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={selectedArea}
                                            onChange={(e) => setSelectedArea(e.target.value)}
                                        >
                                            {!props.editingReservation &&
                                                <option value="" disabled>Raum auswählen</option>}
                                            {props.areas.map((area) => (
                                                <option key={area.id} value={area.id}>
                                                    {`${area.area}`}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                    :
                                    <div>
                                        <TableSelect
                                            selectedDate={selectedDate}
                                            startTime={startTime}
                                            durationInput={durationInput}
                                            tables={tables}
                                            selectedTables={selectedTables}
                                            setSelectedTables={setSelectedTables}
                                            isReservationOverlap={isReservationOverlap}
                                            reservedTables={reservedTables}
                                        />
                                    </div>
                                }
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlNumber">
                                <Form.Label>Personenanzahl <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="number"
                                    onFocus={(e) => e.target.select()}
                                    value={personAmount}
                                    onChange={handlePersonAmountChange}
                                    size="lg"
                                />
                                {isPersonAmountMoreThenSeats() && (
                                    <Form.Text className="text-danger">
                                        Mehr Personen als Plätze
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Kommentar</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={1}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            size="lg"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={"d-flex justify-content-between"}>
                {!props.editingReservation?.id ?
                    <Form>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Form.Check.Label
                                htmlFor="default-checkbox"
                                style={{
                                    margin: '0',
                                    userSelect: 'none'
                                }}
                                className={"me-3"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCanSelectArea(!canSelectArea);
                                }}
                            >
                                Ganzen Raum reservieren
                            </Form.Check.Label>
                            <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                checked={canSelectArea}
                                onChange={() => setCanSelectArea(!canSelectArea)}
                            />
                        </div>
                    </Form>

                    : <div></div>}
                <div>
                    <Button
                        variant={"secondary"}
                        className={"me-2 border-0"}
                        onClick={props.onHide}
                    >
                        Schließen
                    </Button>
                    <Button
                        variant={"success"}
                        className={"border-0"}
                        type="submit"
                        onClick={handleSave}
                        disabled={isSaveButtonDisabled}
                    >
                        Speichern
                    </Button>{' '}
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ReservationAlert;
