import React from 'react';

const LoadingSpinner = ({size = "sm"}) => {
    return (
        <>
            <span className={`spinner-border spinner-border-${size}`} aria-hidden="true"></span>
            <span className="visually-hidden" role="status">Loading...</span>
        </>
    );
};

export default LoadingSpinner;