import {FaLock} from "react-icons/fa";
import React, {useEffect, useRef} from "react";
import Numpad from "../numpad/Numpad";
import useAFK from "../../hooks/useAFK";

const LockOverlay = ({show, setShow}) => {
    const [actualPin, setActualPin] = React.useState(null);
    const [noPin, setNoPin] = React.useState(true);
    const [pinInput, setPinInput] = React.useState("");
    const lockScreenOverlayRef = useRef();
    const inputRef = useRef(null);

    const isAFK = useAFK();

    const setPin = () => {
        // get the pin from the local storage
        const pin = localStorage.getItem("pin");
        if (pin && pin !== "") {
            setNoPin(false);
            setActualPin(pin);
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (!isAFK) {
            return;
        }

        setPin();

        if (!noPin) {
            setShow(true);
            setPinInput("");
        }
    }, [isAFK]);

    useEffect(() => {
        // focus the input field
        if (inputRef.current) inputRef.current.focus();
    }, [inputRef.current]);

    useEffect(() => {
        if (show) {
            // reset the pin input
            setPinInput("");

            // get the pin from the local storage
            const setPinSuccess = setPin();
            if (!setPinSuccess) return;
        }
    }, []);

    const handleChange = (e) => {
        setPinInput((prev) => {
            if (e.target.value.length > 4) {
                return prev;
            }
            return e.target.value;
        });
    };

    useEffect(() => {
        if (pinInput === actualPin) {
            setShow(false);
        }
    }, [pinInput]);

    const numPadInput = (value) => {
        if (value === "del") {
            setPinInput((prev) => prev.slice(0, -1));
            return;
        }

        setPinInput((prev) => {
            if ((prev + value).length > 4) {
                return prev;
            }
            return prev + value;
        });
    }

    return (
        !noPin && show && (
            <div
                className="overflow-auto bg-white position-fixed top-0 start-0 vh-100 vw-100 px-2 pt-4"
                style={{zIndex: 30}}
                ref={lockScreenOverlayRef}
            >
                <div className={"px-5 d-flex flex-column align-items-center"}>
                    <div className={"text-center"}>
                        <div className={"mb-5"}><FaLock size={45}/></div>
                        <h1 className={"lead"}>Pin zum Entsperren eingeben</h1>
                    </div>

                    <div className={"d-flex gap-3 mt-4"}>
                        <input
                            ref={inputRef}
                            type="password"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            id={"pinInput"}
                            className={"form-control rounded-5 text-center"}
                            style={{
                                maxWidth: 300,
                                height: 50,
                                fontSize: 45,
                                letterSpacing: '20px',
                                padding: '0',
                                paddingLeft: '10px',
                            }}
                            value={pinInput}
                            onChange={handleChange}
                        />
                    </div>

                    <div className={"mt-5"}>
                        <Numpad onInput={numPadInput}/>
                    </div>

                    <div className={"d-flex gap-4 flex-column flex-md-row mt-5"}>
                        <div className={"d-flex justify-content-center"}>
                            <img
                                src={"/tech2-logo.png"}
                                alt={"Tech2 Logo"}
                                className={"img-fluid"}
                                style={{maxWidth: 300}}
                            />
                        </div>
                        <div className={"d-flex justify-content-center align-items-center"}>
                            <img
                                src={"/logo512.png"}
                                alt={"Tech2 Logo"}
                                className={"img-fluid"}
                                style={{maxWidth: 150, maxHeight: 150}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

export default LockOverlay;