import React, {useEffect, useMemo, useState} from 'react';
import {Checkbox, FormControlLabel, ListItemText, MenuItem, Select} from '@mui/material';
import useAPI from "../../hooks/useAPI";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

const AreaSelect = ({selectedAreas, setSelectedAreas}) => {
    const {sendRequest} = useAPI();
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        sendRequest("areas").then((data) => {
            if (data.response.status === 200 || data.response.status === 201) {
                setAreas(data.data);
                setSelectedAreas(data.data);
            }
        });
    }, []);

    const selectedAreaNames = useMemo(() => {
        return selectedAreas.map(area => area.area);
    }, [selectedAreas])

    const handleChange = (event) => {
        const selectedAreaNames = event.target.value;
        const selectedAreaObjects = areas.filter(area => selectedAreaNames.includes(area.area));
        setSelectedAreas(selectedAreaObjects);
    };

    return (
        <div>
            <FormControl sx={{width: 400, mb: 2}}>
                <InputLabel id="demo-multiple-checkbox-label">Bereiche auswählen</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedAreaNames}
                    onChange={handleChange}
                    input={<OutlinedInput label="Bereiche auswählen"/>}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {areas.map((area) => (
                        <MenuItem key={area.id} value={area.area}>
                            <Checkbox checked={selectedAreaNames.includes(area.area)}/>
                            <ListItemText primary={area.area}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default AreaSelect;