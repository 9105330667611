import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import Dropdown from "react-bootstrap/Dropdown";
import {BsFillCaretDownFill} from "react-icons/bs";
import useToasts from "../../hooks/useToast";
import useAPI from "../../hooks/useAPI";
import 'dayjs/locale/de';
import AppBarV2 from "../../components/appbarV2/AppBarV2";

function StatisticAppBar({onDateChange, onAreaChange}) {
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [dropdownSelection, setDropdownSelection] = useState(null);
    const {renderToast} = useToasts();
    const {sendRequest} = useAPI();
    const [areas, setAreas] = useState([]);

    const handleLeftArrowClick = () => {
        setSelectedDate((prevDate) => prevDate.subtract(1, 'day'));
        onDateChange((prevDate) => prevDate.subtract(1, 'day'));
    };

    const handleRightArrowClick = () => {
        setSelectedDate((prevDate) => prevDate.add(1, 'day'));
        onDateChange((prevDate) => prevDate.add(1, 'day'));

    };

    const handleDatePickerClick = (date) => {
        setSelectedDate(date)
        onDateChange(date);
    };

    const handleDropdownSelect = (selectedValue) => {
        if (selectedValue !== -1) {
            setDropdownSelection(areas[selectedValue].area);
            onAreaChange(areas[selectedValue].id);
        } else {
            setDropdownSelection("");
            onAreaChange(null);
        }
    }

    async function getAreas() {
        try {
            sendRequest(`areas`, 'GET').then((data) => {
                if (data.response.status === 201 || data.response.status === 200) {
                    setAreas(data.data);
                } else {
                    renderToast("Räume abfragen fehlgeschlagen", "", "danger")
                }
            });
        } catch (error) {
            console.error("Fehler beim Abrufen der Räume:", error);
        }
    }

    useEffect(() => {
        getAreas();
    }, []);

    return (
        <AppBarV2>
            <div className={"d-flex mx-2 mx-md-0"}>
                <Button
                    style={{backgroundColor: 'transparent', border: 'none'}}
                    onClick={handleLeftArrowClick}
                >
                    <Icon.ArrowLeft color="white" size={25}/>
                </Button>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de-AT'>
                    <div style={{
                        backgroundColor: 'white',
                        height: '40px',
                        display: 'flex',
                        border: 'none',
                        alignItems: 'center',
                        overflow: 'hidden',
                        minWidth: '100px',
                    }}>
                        <DatePicker
                            sx={{maxWidth: 350}}
                            style={{width: '100%'}}
                            format="dd, DD.MM.YYYY"
                            value={selectedDate}
                            onChange={(date) => handleDatePickerClick(date)}
                        />
                    </div>
                </LocalizationProvider>
                <Button
                    style={{backgroundColor: 'transparent', border: 'none'}}
                    onClick={handleRightArrowClick}>
                    <Icon.ArrowRight color="white" size={25}/>
                </Button>
            </div>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic-button" className={"rounded-2 py-1"} variant={"outline-light"}>
                    {dropdownSelection ? dropdownSelection : "Alle Räume"}{' '}
                    <BsFillCaretDownFill/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {dropdownSelection && (
                        <Dropdown.Item key={-1} onClick={() => handleDropdownSelect(-1)}>
                            Alle Räume
                        </Dropdown.Item>
                    )}
                    {areas.map((item, index) => {
                        // Skip the currently selected item
                        if (item.area === dropdownSelection) return null;

                        return (
                            <Dropdown.Item key={index}>
                                <div
                                    onClick={() => handleDropdownSelect(index)}
                                    style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <span style={{marginRight: '8px'}}>
                                            {item.area}
                                        </span>
                                </div>
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </AppBarV2>
    );
}

export default StatisticAppBar;
