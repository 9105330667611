// useToast.js
import ReactDOM from "react-dom/client";
import Toast from "../components/toast/Toast";
import {useEffect, useState} from "react";

let root = null;

function useToasts() {
    const [toasts, setToasts] = useState([]);

    // close all open toasts
    const closeToasts = () => {
        setToasts([]);
    };

    const closeToast = (toastToCLose) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== toastToCLose.id));
    };

    const renderToast = (title = "", message = "", variant = "primary", duration = 6500) => {
        const newToast = {title, message, variant, duration, id: Math.random()};
        setToasts([...toasts, newToast]);

        // close toast after duration
        setTimeout(() => closeToast(newToast), duration);
    }

    useEffect(() => {
        if (!root) {
            root = ReactDOM.createRoot(
                document.getElementById('toast-root')
            );
        }

        const toastContainer = (
            <div className="toast-container position-fixed end-0 bottom-0">
                {toasts.map((toast, index) => (
                    <Toast
                        key={index}
                        title={toast.title}
                        message={toast.message}
                        variant={toast.variant}
                        onClose={() => closeToast(toast)}
                    />
                ))}
            </div>
        );

        root.render(toastContainer);
    }, [toasts]);

    return {renderToast, closeToasts};
}

export default useToasts;
