import {FaDeleteLeft} from "react-icons/fa6";

const Numpad = ({onInput}) => {
    const numbers = [[1, 2, 3], [4, 5, 6], [7, 8, 9]];

    return (
        <div>
            {numbers.map((row, rowIndex) => (
                <div key={rowIndex} className="row my-4">
                    {row.map((number, index) => (
                        <div key={index} className={"col"}>
                            <button onClick={() => onInput(number)} style={{minWidth: 75, minHeight: 75}}
                                    className="btn btn-outline-secondary rounded-circle">{number}
                            </button>
                        </div>
                    ))}
                </div>
            ))}
            <div className={"row my-4"}>
                <div className="col">
                    <button onClick={() => onInput(0)} style={{minWidth: 75, minHeight: 75}}
                            className="btn btn-outline-secondary rounded-circle">0
                    </button>
                </div>
                <div className="col"></div>
                <div className="col">
                    <button onClick={() => onInput("del")} style={{minWidth: 75, minHeight: 75}}
                            className="btn btn-outline-secondary rounded-circle">
                        <FaDeleteLeft size={30}/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Numpad;