import React, {useEffect, useRef, useState} from "react";
import {PiChairDuotone} from "react-icons/pi";
import {renderToStaticMarkup} from "react-dom/server";
import {GiBrickWall} from "react-icons/gi";

const cellTypeColors = {
    TABLE: "#8B4513",
    CHAIR: "#ffffff",
    WALL: "#ffffff",
};

export const gridDefaults = {
    gridSize: 100,
    cellSize: 50,
    initialZoomLevel: 1,
    zoomStep: 0.001,
}

const chairImageSrc = `data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(<PiChairDuotone size={35} style={{color: "#000000"}}/>)
)}`;
const wallImageSrc = `data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(<GiBrickWall size={35} style={{color: "#000000"}}/>)
)}`;

export const RestaurantGrid = (
    {
        onCellClick,
        filledCells = [],
        onCellMouseMove,
        selectedElement,
        setSelectedElement
    }) => {
    const [zoomLevel, setZoomLevel] = useState(gridDefaults.initialZoomLevel);
    const [canvasPosition, setCanvasPosition] = useState({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    });

    const chairImage = new Image();
    chairImage.src = chairImageSrc;

    const wallImage = new Image();
    wallImage.src = wallImageSrc;

    const canvasRef = useRef(null);

    const updateCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Set canvas size
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Calculate the center of the canvas
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        // Calculate the offset based on the zoom level
        const offsetX = centerX - (centerX - canvasPosition.x) * zoomLevel;
        const offsetY = centerY - (centerY - canvasPosition.y) * zoomLevel;

        // Draw grid
        ctx.strokeStyle = "gray";
        ctx.lineWidth = 0.5;

        for (let x = 0; x < gridDefaults.gridSize; x++) {
            for (let y = 0; y < gridDefaults.gridSize; y++) {
                const cellX = (x - gridDefaults.gridSize / 2) * gridDefaults.cellSize * zoomLevel + offsetX;
                const cellY = (y - gridDefaults.gridSize / 2) * gridDefaults.cellSize * zoomLevel + offsetY;

                ctx.strokeRect(cellX, cellY, gridDefaults.cellSize * zoomLevel, gridDefaults.cellSize * zoomLevel);

                // fill the specified cells
                const cell = filledCells.find(
                    (filledCell) => filledCell.x === x && filledCell.y === y
                );
                if (cell) {
                    ctx.fillStyle = cellTypeColors[cell.cellType];
                    ctx.fillRect(cellX, cellY, gridDefaults.cellSize * zoomLevel, gridDefaults.cellSize * zoomLevel);

                    // Draw cell contents based on cell type
                    if (cell.cellType === "TABLE" && cell.table && cell.table.tableNumber) {
                        let truncatedTableNumber = "";
                        if (cell.table.shortForm == null) {
                            truncatedTableNumber = cell.table.tableNumber.slice(0, 3);
                        } else {
                            truncatedTableNumber = cell.table.shortForm.slice(0, 3);
                        }
                        // Add text with appropriate color
                        ctx.fillStyle = "#fff"; // White text
                        ctx.font = `${16 * zoomLevel}px Arial`; // Adjust the text size based on the zoom level
                        ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        ctx.fillText(truncatedTableNumber, cellX + gridDefaults.cellSize * zoomLevel / 2, cellY + gridDefaults.cellSize * zoomLevel / 2);
                    } else {
                        // For other cell types (CHAIR, WALL), use your existing code to draw them.
                        if (cell.cellType === "CHAIR") {
                            if (chairImage.complete) {
                                ctx.drawImage(chairImage, cellX, cellY, gridDefaults.cellSize * zoomLevel, gridDefaults.cellSize * zoomLevel);
                            } else {
                                chairImage.onload = () => {
                                    ctx.drawImage(chairImage, cellX, cellY, gridDefaults.cellSize * zoomLevel, gridDefaults.cellSize * zoomLevel);
                                };
                            }
                        } else if (cell.cellType === "WALL") {
                            // Check if the WALL is a 'ghost' wall
                            if (cell.ghost) {
                                ctx.globalAlpha = 0.5; // Set half opacity for ghost walls
                            }

                            if (wallImage.complete) {
                                ctx.drawImage(wallImage, cellX, cellY, gridDefaults.cellSize * zoomLevel, gridDefaults.cellSize * zoomLevel);
                            } else {
                                wallImage.onload = () => {
                                    ctx.drawImage(wallImage, cellX, cellY, gridDefaults.cellSize * zoomLevel, gridDefaults.cellSize * zoomLevel);
                                };
                            }

                            // Reset opacity to full for other elements
                            if (cell.ghost) {
                                ctx.globalAlpha = 1.0;
                            }
                        }
                    }
                }
            }
        }
    };

    let isDragging = false;
    let lastMouseX = 0;
    let lastMouseY = 0;
    let lastClosePopoverEvent;

    const handleCellMouseMove = (e) => {
        if (!onCellMouseMove) return;

        const canvas = canvasRef.current;

        // Calculate the center of the canvas
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        // Calculate the offset based on the zoom level and canvas position
        const offsetX = centerX - (centerX - canvasPosition.x) * zoomLevel;
        const offsetY = centerY - (centerY - canvasPosition.y) * zoomLevel;

        const actualCellSize = gridDefaults.cellSize * zoomLevel;

        const rect = canvas.getBoundingClientRect();
        const xHover = Math.floor((e.clientX - rect.left - offsetX) / actualCellSize + gridDefaults.gridSize / 2);
        const yHover = Math.floor((e.clientY - rect.top - offsetY) / actualCellSize + gridDefaults.gridSize / 2);

        if (xHover < 0 || xHover >= gridDefaults.gridSize || yHover < 0 || yHover >= gridDefaults.gridSize) return;

        onCellMouseMove(xHover, yHover);
    };

    useEffect(() => {
        // add eventlistener for mouseout
        // this is necessary to prevent the canvas from being dragged when the mouse leaves the canvas while dragging
        const canvas = canvasRef.current;
        canvas.addEventListener('mouseout', () => {
            isDragging = false;
        });

        // add resize event listener to update the canvas when window size changes
        window.addEventListener('resize', updateCanvas);

        // cleanup eventlisteners
        return () => {
            canvas.removeEventListener('mouseout', () => {
                isDragging = false;
            });
            window.removeEventListener('resize', updateCanvas);
        }
    }, []);

    useEffect(() => {
        // Add event listeners for zoom and navigation
        const canvas = canvasRef.current;
        canvas.addEventListener('wheel', handleZoom);
        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);

        // Add touch event listeners
        canvas.addEventListener('touchstart', handleTouchStart, {passive: false});
        canvas.addEventListener('touchmove', handleTouchMove, {passive: false});
        canvas.addEventListener('touchend', handleTouchEnd);

        return () => {
            // Clean up event listeners when the component unmounts
            canvas.removeEventListener('wheel', handleZoom);
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);

            // Clean up touch event listeners
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('touchmove', handleTouchMove);
            canvas.removeEventListener('touchend', handleTouchEnd);
        };
    }, [zoomLevel]);

    const initialPinchDistance = useRef(null);

    const handleTouchStart = (e) => {
        e.preventDefault();
        if (e.touches.length === 2) {
            const dx = e.touches[0].clientX - e.touches[1].clientX;
            const dy = e.touches[0].clientY - e.touches[1].clientY;
            initialPinchDistance.current = Math.sqrt(dx * dx + dy * dy);
        } else {
            isDragging = true;
            lastMouseX = e.touches[0].clientX;
            lastMouseY = e.touches[0].clientY;
            setDownCords({x: e.touches[0].clientX, y: e.touches[0].clientY});
        }
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        if (e.touches.length === 2) {
            const dx = e.touches[0].clientX - e.touches[1].clientX;
            const dy = e.touches[0].clientY - e.touches[1].clientY;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const ratio = distance / initialPinchDistance.current;
            setZoomLevel((prevZoomLevel) => {
                const newZoomLevel = prevZoomLevel * ratio;
                return Math.max(0.1, Math.min(newZoomLevel, 2));
            });
            initialPinchDistance.current = distance;
        } else if (isDragging) {
            const deltaX = (e.touches[0].clientX - lastMouseX) / zoomLevel;
            const deltaY = (e.touches[0].clientY - lastMouseY) / zoomLevel;
            setCanvasPosition((prevPosition) => ({
                x: prevPosition.x + deltaX,
                y: prevPosition.y + deltaY,
            }));
            lastMouseX = e.touches[0].clientX;
            lastMouseY = e.touches[0].clientY;
        }
    };

    const handleTouchEnd = () => {
        isDragging = false;
    };

    useEffect(() => {
        // Update the canvas whenever zoom or position changes
        updateCanvas();
    }, [zoomLevel, canvasPosition, filledCells]);

    const handleZoom = (e) => {
        e.preventDefault();
        // Zoom in or out depending on the direction of the scroll
        setZoomLevel((prevZoomLevel) => {
            if (prevZoomLevel - e.deltaY * gridDefaults.zoomStep >= 2) return 2;

            if (prevZoomLevel - e.deltaY * gridDefaults.zoomStep <= 0.1) return 0.1;

            return prevZoomLevel - e.deltaY * gridDefaults.zoomStep;
        });
    };

    const [downCords, setDownCords] = useState({x: 0, y: 0});

    const handleMouseDown = (e) => {
        isDragging = true;
        lastMouseX = e.clientX;
        lastMouseY = e.clientY;
        setDownCords({x: e.clientX, y: e.clientY})
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const closePopoverEvent = new CustomEvent("closePopover");
        document.dispatchEvent(closePopoverEvent);
        lastClosePopoverEvent = new Date();

        const deltaX = (e.clientX - lastMouseX) / zoomLevel;
        const deltaY = (e.clientY - lastMouseY) / zoomLevel;

        setCanvasPosition((prevPosition) => ({
            x: prevPosition.x + deltaX,
            y: prevPosition.y + deltaY,
        }));

        lastMouseX = e.clientX;
        lastMouseY = e.clientY;
    };

    const handleMouseUp = () => {
        isDragging = false;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.addEventListener('click', handleCellClick);
        canvas.addEventListener('touchend', handleCellClick)
        return () => {
            canvas.removeEventListener('click', handleCellClick);
            canvas.removeEventListener('touchend', handleCellClick);
        }
    }, [downCords]);

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.addEventListener('mousemove', handleCellMouseMove);
        return () => {
            canvas.removeEventListener('mousemove', handleCellMouseMove);
        }
    }, [canvasPosition, zoomLevel]);

    const handleCellClick = (e) => {
        let clientX, clientY;

        // Check if the event is a touch event
        if (e.type === 'touchend') {
            // Touch event: get the coordinates from the touches array
            clientX = e.changedTouches[0].clientX;
            clientY = e.changedTouches[0].clientY;
        } else {
            // Mouse event: get the coordinates directly from the event
            clientX = e.clientX;
            clientY = e.clientY;
        }

        // if the mouse was moved while clicking, do nothing
        if (downCords.x !== clientX && downCords.y !== clientY) return;

        // if the onCellClick callback is not defined, do nothing
        // if defined, the clicked cells coordinates are calculated and passed to the callback function
        if (!onCellClick) return;

        const canvas = canvasRef.current;

        // Calculate the center of the canvas
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        // Calculate the offset based on the zoom level
        const offsetX = centerX - (centerX - canvasPosition.x) * zoomLevel;
        const offsetY = centerY - (centerY - canvasPosition.y) * zoomLevel;

        const actualCellSize = gridDefaults.cellSize * zoomLevel;

        const cellX = (0 - gridDefaults.gridSize / 2) * gridDefaults.cellSize * zoomLevel + offsetX;
        const cellY = (0 - gridDefaults.gridSize / 2) * gridDefaults.cellSize * zoomLevel + offsetY;

        const dividerX = cellX / actualCellSize * -1;
        const dividerY = cellY / actualCellSize * -1;

        const xClick = Math.ceil(clientX / actualCellSize + dividerX) - 1;
        const yClick = Math.ceil(clientY / actualCellSize + dividerY) - 1;

        if (xClick < 0 || xClick >= gridDefaults.gridSize || yClick < 0 || yClick >= gridDefaults.gridSize) return;

        // execute the given callback function
        onCellClick(xClick, yClick, e.type);
    };

    return (
        <div className="editor-grid overflow-hidden vh-100">
            <canvas
                ref={canvasRef}
                id="editor-grid-canvas"
                width={window.innerWidth}
                height={window.innerHeight}
            ></canvas>
        </div>
    );
}