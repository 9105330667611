import React, {useEffect, useRef, useState} from "react";
import {Button, Form, ListGroup, Modal} from "react-bootstrap";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useToasts from "../../hooks/useToast";
import useAPI from "../../hooks/useAPI";
import ReservationAlert from "../../pages/tableReservation/ReservationAlert";
import {transformReservations} from "../../utils/reservationUtils";
import LoadingSpinner from "../loading/LoadingSpinner";
import {FiClock, FiEdit, FiMessageSquare, FiPhone, FiUsers} from "react-icons/fi";
import {convertUTCToLocalTime} from "../../utils/dateUtils";

function SearchFieldModal(props) {
    const [searchTerm, setSearchTerm] = useState("");
    const [lastSearchTerm, setLastSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState(null);
    const inputRef = useRef(null);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [showAddReservation, setShowAddReservation] = useState(false);
    const [editingReservation, setEditingReservation] = useState(null);
    const [reservations, setReservations] = useState([]);
    const [isLoadMoreButtonEnabled, setIsLoadMoreButtonEnabled] = useState(false);
    const [limit, setLimit] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const {renderToast} = useToasts();
    const {loading, error, status, response, sendRequest} = useAPI();

    useEffect(() => {
        if (props.show) {
            inputRef.current.focus();
            setDefaultDateRange();
            setLimit(10);
        }

        if (!props.show) {
            setSearchResults(null);
            setSearchTerm("");
            setLimit(10);
        }

        setLastSearchTerm("")
    }, [props.show]);

    const handleClose = () => {
        setSearchResults(null);
        setSearchTerm("");
        setLimit(10);
        props.onHide();
    };

    const setDefaultDateRange = () => {
        setStartDate(dayjs());
        setEndDate(dayjs().add(365, 'day'));
    };

    const handleSearch = () => {
        if (!searchTerm) {
            renderToast("Fehler", "Bitte geben Sie einen Suchbegriff ein", "warning");
            return;
        }
        if (startDate.isAfter(endDate)) {
            renderToast("Fehler", "Das Startdatum darf nicht nach dem Enddatum liegen", "warning");
            return;
        }
        getSearchResults(searchTerm, startDate, endDate, limit);
    };

    useEffect(() => {
        if (!searchTerm) return;
        if (!props.show) return;

        const timeoutId = setTimeout(() => {
            if (searchTerm === lastSearchTerm) return;
            getSearchResults(searchTerm, startDate, endDate, limit);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [searchTerm, lastSearchTerm]);

    function getSearchResults(input, dateFrom, dateTo, limit) {
        setIsLoading(true);
        const postData = {
            "name": input,
            "startDate": formatToISO(dateFrom),
            "endDate": formatToISO(dateTo),
            "limit": limit,
        };

        sendRequest(`reservations/search`, "POST", {}, postData).then((data) => {
            if (data.data.reservations) {
                setSearchResults(data.data.reservations);
                if (data.data.reservations.length === limit) {
                    setIsLoadMoreButtonEnabled(true);
                } else {
                    setIsLoadMoreButtonEnabled(false);
                }
            }
        }).catch((error) => {
            renderToast("Fehler beim Abfragen der Suchergebnisse", error, "error");
        }).then(() => {
            setLastSearchTerm(input);
            setIsLoading(false);
        });
    }

    const formatToISO = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };

    const formatDate = (date) => {
        return dayjs(date).format('DD.MM.YYYY');
    };

    function getReservations(date) {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        sendRequest(`reservations/resdate/${formattedDate}`).then(data => {
            if (data.response.status === 200) {
                setReservations(transformReservations(data.data));
                setShowAddReservation(true);
            }
        }).catch((error) => {
            renderToast("Fehler beim Abfragen der Reservierungen", error, "error");
        }).then(() => {
            setIsLoading(false);
        });
    }

    function getReservationByID(id) {
        setIsLoading(true);
        sendRequest(`reservations/${id}`).then(data => {
            const transformedReservations = transformReservations([data.data.reservation]);

            const date = parseDate(data.data.reservation.date);
            const startHour = date.getHours();
            const startMinute = date.getMinutes();
            const endHour = startHour + Math.floor((startMinute + data.data.reservation.minutes) / 60);
            const endMinute = (startMinute + data.data.reservation.minutes) % 60;

            transformedReservations[0].startHour = startHour;
            transformedReservations[0].startMinute = startMinute;
            transformedReservations[0].endHour = endHour;
            transformedReservations[0].endMinute = endMinute;
            setEditingReservation(transformedReservations[0]);
            getReservations(transformedReservations[0].reservationDate);
        });
    }

    const parseDate = (dateString) => {
        // Parse string to Date object
        const date = new Date(dateString);
        // Convert to UTC time
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    };

    const getTimeString = (reservation) => {
        const date = parseDate(reservation.date);

        const startHour = date.getHours();
        const startMinute = date.getMinutes();

        // Berechnung der Endzeit
        const endHour = startHour + Math.floor((startMinute + reservation.minutes) / 60);
        const endMinute = (startMinute + reservation.minutes) % 60;

        // Konvertierung der Zeiten in lokale Zeit
        const localStart = convertUTCToLocalTime(new Date(date.getFullYear(), date.getMonth(), date.getDate(), startHour, startMinute));
        const localEnd = convertUTCToLocalTime(new Date(date.getFullYear(), date.getMonth(), date.getDate(), endHour, endMinute));

        // Formatierung der Zeitangabe
        const formattedStartTime = `${localStart.getHours().toString().padStart(2, '0')}:${localStart.getMinutes().toString().padStart(2, '0')}`;
        const formattedEndTime = `${localEnd.getHours().toString().padStart(2, '0')}:${localEnd.getMinutes().toString().padStart(2, '0')}`;

        return `${formattedStartTime} - ${formattedEndTime}`;
    };


    return (
        <>
            <ReservationAlert
                areas={{}}
                show={showAddReservation}
                onHide={() => {
                    setShowAddReservation(false);
                    getSearchResults(searchTerm, startDate, endDate, limit);
                    setEditingReservation(null);
                }}
                editingReservation={editingReservation}
                setEditingReservation={setEditingReservation}
                date={editingReservation?.reservationDate}
                reservations={reservations}
            />
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" className={"reservationAlertModalBody"} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Reservierung suchen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formYear">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de-AT'>
                                <div className="row justify-content-lg-between">
                                    <div className="col-md-5 pb-2">
                                        <DatePicker
                                            value={startDate}
                                            onChange={date => setStartDate(date)}
                                            format="DD.MM.YYYY"
                                            label="Von"
                                            style={{height: '100%'}}
                                            sx={{maxWidth: 300}}
                                        />
                                    </div>
                                    <div className="col-md-5 pb-2">
                                        <DatePicker
                                            value={endDate}
                                            onChange={date => setEndDate(date)}
                                            format="DD.MM.YYYY"
                                            label="Bis"
                                            style={{height: '100%'}}
                                            sx={{maxWidth: 300}}
                                        />
                                    </div>
                                </div>
                            </LocalizationProvider>
                        </Form.Group>
                        <Form.Group controlId="formSearch" className={"mt-2"}>
                            <Form.Control
                                type="text"
                                placeholder="Suchbegriff eingeben"
                                value={searchTerm}
                                size="lg"
                                onChange={e => setSearchTerm(e.target.value)}
                                ref={inputRef}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                  }
                                }
                            />
                        </Form.Group>
                    </Form>
                    {isLoading && (
                        <div className={"mt-4"} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxHeight: '340px',
                            paddingBottom: '10px',
                        }}>
                            <LoadingSpinner/>
                        </div>
                    )}
                    {searchResults && (
                        <>
                            {searchResults && searchResults.length === 0 && (
                                <div className="mt-2"
                                     style={{textAlign: 'center', fontSize: '1.2em', paddingTop: '10px'}}>
                                    Keine Reservierungen gefunden
                                </div>
                            )}
                            <div className={"mt-2"} style={{maxHeight: "340px", overflowY: "auto"}}>
                                <ListGroup>
                                    {searchResults && searchResults.map((result, index) => (
                                        <ListGroup.Item key={index} onClick={() => {
                                            getReservationByID(result.id);
                                        }}>
                                            <div className="d-flex justify-content-between" style={{paddingBottom: '8px'}}>
                                                <strong>{result.name}</strong>
                                                {formatDate(result.date)}
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className={"d-flex flex-column gap-2"}>
                                                        <div className={"d-flex align-items-center gap-2"}>
                                                            <FiPhone/> <span>{result.phone}</span>
                                                        </div>
                                                        <div className={"d-flex align-items-center gap-2"}>
                                                            <FiUsers/> <span>{result.personAmount}</span>
                                                        </div>
                                                        {result.comment && (
                                                            <div className={"d-flex align-items-center gap-2"}>
                                                                <FiMessageSquare/> {result.comment}
                                                            </div>
                                                        )}
                                                        <div className={"d-flex align-items-center gap-2"}>
                                                            <FiClock/> <span>{getTimeString(result)}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <FiEdit size={25}/>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                                {isLoadMoreButtonEnabled && searchResults.length > 0 && (
                                    <p
                                        className={"text-primary"}
                                        style={{
                                            paddingTop: "12px",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            setLimit(limit + 10);
                                            getSearchResults(searchTerm, startDate, endDate, limit + 10);
                                        }}>
                                        Mehr laden
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} variant="secondary">Schließen</Button>
                    <Button onClick={handleSearch}>Suchen</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SearchFieldModal;