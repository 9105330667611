import React, {useEffect, useState} from 'react';
import {Divider, Drawer, List, ListItem, ListItemText} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {BsCalendarCheckFill, BsFillGrid1X2Fill} from "react-icons/bs";
import {FaCogs, FaUserFriends} from "react-icons/fa";
import {BiLogOut} from "react-icons/bi";
import Cookies from "js-cookie";
import {IoMdContact} from "react-icons/io";
import {FcStatistics} from "react-icons/fc";

function SideDrawer({open, onClose}) {
    const navigate = useNavigate();


    const [restaurantname, setrestaurantname] = useState([]);

    useEffect(() => {
        const storedDefaults = localStorage.getItem('defaultValues');
        if (storedDefaults) {
            setrestaurantname(JSON.parse(storedDefaults).name);
        }
    }, []);

    function logout() {
        Cookies.remove('token', {path: '/'});
        navigate('/');
    }

    return (
        <Drawer anchor="left" open={open} onClose={onClose}>
            <div role="presentation" onClick={onClose} onKeyDown={onClose}>
                <List className={"sidnav-list py-0"}>
                    <ListItem onClick={() => navigate('/reservation')}>
                        <span className={"text-color-icon d-flex align-items-center me-3"}>
                            <BsCalendarCheckFill/>
                        </span>
                        <ListItemText primary="Reservierungen"/>
                    </ListItem>
                    <Divider/>
                    <ListItem onClick={() => navigate('/restauranteditor')}>
                        <span className={"text-color-icon d-flex align-items-center me-3"}>
                            <BsFillGrid1X2Fill/>
                        </span>
                        <ListItemText primary="Restaurant Editor"/>
                    </ListItem>
                    <Divider/>
                    <ListItem onClick={() => navigate('/settings')}>
                        <span className={"text-color-icon d-flex align-items-center me-3"}>
                            <FaCogs/>
                        </span>
                        <ListItemText primary="Einstellungen"/>
                    </ListItem>
                    <Divider/>
                    <ListItem onClick={() => navigate('/statistics')}>
                        <span className={"text-color-icon d-flex align-items-center me-3"}>
                            <FcStatistics />
                        </span>
                        <ListItemText primary="Statistik"/>
                    </ListItem>
                    <Divider/>
                    <ListItem onClick={() => navigate('/contact')}>
                        <span className={"text-color-icon d-flex align-items-center me-3"}>
                           <IoMdContact />
                        </span>
                        <ListItemText primary="Kontakt"/>
                    </ListItem>
                    <Divider/>
                    {/*<ListItem onClick={() => navigate('/usermanagement')}>*/}
                    {/*    <span className={"text-color-icon d-flex align-items-center me-3"}>*/}
                    {/*        <FaUserFriends/>*/}
                    {/*    </span>*/}
                    {/*    <ListItemText primary="Benutzer"/>*/}
                    {/*</ListItem>*/}
                    <Divider/>
                    <ListItem onClick={() => logout()}>
                        <span className={"text-color-icon d-flex align-items-center me-3"}>
                            <BiLogOut/>
                        </span>
                        <ListItemText primary="Logout"/>
                    </ListItem>
                </List>
            </div>

            <div style={{marginTop: 'auto', padding: '10px'}}>
                <div>{restaurantname}</div>
                <div>{"©place2eat 2.0.1"}</div>
            </div>
        </Drawer>
    );
}

export default SideDrawer;
