import React, {useState} from 'react';
import {GoPrimitiveDot} from "@react-icons/all-files/go/GoPrimitiveDot";
import {Popover} from 'react-bootstrap';
import "./popover.css";
import {FaChair} from "@react-icons/all-files/fa/FaChair";
import {GiTable} from "@react-icons/all-files/gi/GiTable";
import useAPI from "../../hooks/useAPI";
import {FaTrash} from "@react-icons/all-files/fa/FaTrash";
import EditTableAlert from "../editTable/editTableAlert";

const PopoverComponent = ({position, popoverClass, cellData, onClose}) => {
    const {loading, status, response = "", sendRequest} = useAPI();
    const [showEditTable, setShowEditTable] = useState(false);

    async function deleteCell() {
        if (cellData.cellType === "TABLE") {
            try {
                sendRequest(`cells/tablecell/${cellData.id}`, 'DELETE').then((data) => {
                    onClose(); // Hier schließen wir das Popover nach dem Löschen
                });
            } catch (error) {
                console.error("Fehler beim Löschen der Cells:", error);
            }
        } else {
            try {
                sendRequest(`cells/designcell/${cellData.id}`, 'DELETE').then((data) => {
                    onClose();
                });
            } catch (error) {
                console.error("Fehler beim Löschen der Cells:", error);
            }
        }
    }

    return (
        <>
            <EditTableAlert
                show={showEditTable}
                onHide={() => {
                    setShowEditTable(false);
                    onClose();
                }}
                tableData={cellData}
            />
            <div className={popoverClass + " custom-popover"} style={{
                position: 'absolute',
                top: position[1],
                left: position[0],
                backgroundColor: 'white',
            }}>
                <Popover>
                    <Popover.Header as={"h3"}>
                        <div className={"d-flex justify-content-between gap-4 py-2"}>
                            <div className={"d-flex gap-2 align-items-center lead"}>
                                {
                                    cellData.table && cellData.table.tableNumber !== null ? (
                                        <><GoPrimitiveDot/><span>{cellData.table.tableNumber}</span></>
                                    ) : (
                                        <span>{cellData.cellType}</span>
                                    )
                                }
                            </div>
                            <button className={"btn text-danger d-flex align-items-center p-1"}
                                    onClick={deleteCell}>
                                <FaTrash/>
                            </button>
                        </div>
                    </Popover.Header>
                    {cellData.table && cellData.table.tableNumber !== null && (
                        <Popover.Body>
                            <div className={"reservationPopoverInfo d-flex flex-column gap-1"}>
                                <div className={"d-flex align-items-center gap-2"}>
                                    {cellData.table && cellData.table.shortForm !== null && (
                                        <div className={"d-flex align-items-center gap-2"}>
                                            <><GiTable/><span>{cellData.table.shortForm}</span></>
                                        </div>
                                    )}
                                </div>
                                {cellData.table && cellData.table.capacity !== null && (
                                    <div className={"d-flex align-items-center gap-2"}>
                                        <><FaChair/><span>{cellData.table.capacity}</span></>
                                    </div>
                                )}
                            </div>
                            <div className={"mt-3"}>
                                <button style={{minWidth: "100px"}} onClick={() => {
                                    setShowEditTable(true);
                                }} className={"w-100 btn btn-sm btn-outline-primary rounded-0"}>
                                    Bearbeiten
                                </button>
                            </div>
                        </Popover.Body>
                    )}
                </Popover>
            </div>
        </>

    );
}

export default PopoverComponent;
