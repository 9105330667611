import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import dayjs from "dayjs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

function getStyles(name: any, selectedTableIds: any[], theme: Theme) {
    return {
        fontWeight:
            selectedTableIds.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function TableSelect(props) {
    const theme = useTheme();
    const { selectedDate, startTime, durationInput, tables, selectedTables, setSelectedTables, isReservationOverlap, reservedTables } = props;
    const selectedTableIds = selectedTables.map(tableId => {
        const parsed = parseInt(tableId);
        return isNaN(parsed) ? null : parsed;
    }).filter(id => id !== null);

    const groupedTables = tables.reduce((acc, table) => {
        (acc[table.area.area] = acc[table.area.area] || []).push(table);
        return acc;
    }, {});

    return (
        <div>
            <FormControl sx={{ m: 1, width: '100%' }}>
                <InputLabel id="table-select-label">Tische auswählen</InputLabel>
                <Select
                    labelId="table-select-label"
                    id="table-select"
                    multiple
                    value={selectedTableIds}
                    onChange={(e: SelectChangeEvent<typeof selectedTableIds>) => {
                        setSelectedTables(e.target.value);
                    }}
                    input={<OutlinedInput label="Tische auswählen" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                                if (!value) return null;
                                const selectedTable = tables.find(table => table.id == value);
                                return <Chip key={value} label={selectedTable ? selectedTable.tableNumber : ''} />;
                            })}
                        </Box>
                    )}
                    MenuProps={MenuProps}>
                    {Object.keys(groupedTables).map((areaName) => (
                        [
                            <MenuItem key={areaName} disabled>
                                {areaName}
                            </MenuItem>,
                            ...groupedTables[areaName].map((table) => {
                                const start = dayjs(selectedDate).set('hour', startTime.hour()).set('minute', startTime.minute());
                                const end = start.add(durationInput, 'hour');
                                const isOverlap = reservedTables.includes(table.id.toString()) ? false : isReservationOverlap(start, end, table.id);

                                return (
                                    <MenuItem
                                        key={table.id}
                                        value={table.id}
                                        disabled={(props.editingReservation && props.editingReservation.id && (+props.editingReservation.tableId !== +table.id)) || isOverlap}
                                        style={getStyles(table.id.toString(), selectedTables, theme)}>
                                        {`${table.tableNumber} (Plätze: ${table.capacity})`}
                                    </MenuItem>
                                );
                            })
                        ]
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}