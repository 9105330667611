import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './public.css'
import 'bootstrap/dist/css/bootstrap.css';
import './styles/colors.scss';

const root = ReactDOM.createRoot(document.getElementById('app-root'));
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

reportWebVitals();
