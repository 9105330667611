import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import ReservationAlert from './ReservationAlert';
import dayjs from "dayjs";
import TableReservationAppBar from "./TableReservationAppBar";
import TimelineV2 from "../../components/timelineV2/TimelineV2";
import Birdperspective from "./Birdperspective";
import {useSearchParams} from "react-router-dom";
import MultiSelect from "../../components/multiselect/MultiSelect";
import useAPI from "../../hooks/useAPI";
import useToast from "../../hooks/useToast";
import useDefaults from "../../hooks/useDefaults";
import {transformReservations} from "../../utils/reservationUtils";


const TableReservation = () => {
        const {sendRequest} = useAPI();
        const {renderToast} = useToast();
        const [showAddReservation, setAddReservation] = useState(false);
        const initialDate = dayjs();
        const [date, setDate] = useState(initialDate);
        const [view, setView] = useState("timeline")
        const [searchParams, setSearchParams] = useSearchParams();
        const [areas, setAreas] = React.useState([]);
        const [areaOptions, setAreaOptions] = useState([]);
        const [selectedAreas, setSelectedAreas] = useState([]);
        const [reservations, setReservations] = useState(null);

        useEffect(() => {
            if (searchParams.get("view") === "bird") setView("bird");

            sendRequest("areas").then((data) => {
                if (data.response.status === 200 || data.response.status === 201) {
                    setAreas(data.data);
                } else {
                    renderToast("Fehler", "Fehler beim abfragen der Areas.", "danger");
                }
            });
        }, []);

        useEffect(() => {
            const newAreaOptions = areas.map((area) => ({label: area?.area, value: area?.id}));
            setAreaOptions(newAreaOptions);
            setSelectedAreas(areas.map((area) => area.id));
        }, [areas]);

        useEffect(() => {
            if (view === "bird") {
                searchParams.set("view", "bird");
            } else {
                searchParams.delete("view");
            }
            setSearchParams(searchParams);
        }, [view]);


        const [editingReservation, setEditingReservation] = useState();

        const handleDateChange = (date) => {
            setDate(date);
        };

        const toggleAreaSelection = (option) => () => {
            setAreaOptions((prev) => {
                prev.map((prevOption) => {
                    if (prevOption.value === option.value) {
                        prevOption.selected = !prevOption.selected;
                    }
                    return prevOption;
                });
            });
        };

    const {defaultValues, isClosedOnDate} = useDefaults();
    const [closedToday, setClosedToday] = useState(false);

    useEffect(() => {
        setClosedToday(isClosedOnDate(date?.toDate()));
    }, [date, defaultValues]);

    useEffect(() => {
        if (reservations === null) {
            const formattedDate = date.format('YYYY-MM-DD');
            sendRequest(`reservations/resdate/${formattedDate}`).then(data => {
                setReservations(transformReservations(data.data));
            });
        }
    }, [reservations]);

        return (
            <>
                <div style={{height: 64}}>
                    <TableReservationAppBar view={view} setView={setView} date={date} onDateChange={handleDateChange}/>
                </div>

                {closedToday ?
                <div className={"text-center p-4 lead"}>
                    An diesem Tag ist geschlossen!
                </div> : (
                <div className={"my-2"}>
                    {view === "bird" ?
                        <Birdperspective
                            date={date}
                            selectedAreas={selectedAreas}
                            showAddReservation={showAddReservation}
                            openModal={(reservation) => {
                                setEditingReservation(reservation);
                                setAddReservation(true);
                            }}
                            updateReservations={setReservations}
                        />
                        :
                        <TimelineV2
                            openModal={(reservation) => {
                                setEditingReservation(reservation);
                                setAddReservation(true);
                            }}
                            selectedAreas={selectedAreas}
                            date={date}
                            updateReservations={setReservations}
                            areas={areas}
                        />
                    }
                </div>
                )}

                <div className="position-fixed d-flex justify-content-end m-2 m-md-3 bottom-0 end-0 gap-2 gap-md-3 flex-wrap">
                    <MultiSelect
                            selectedOptions={selectedAreas}
                            setSelectedOptions={setSelectedAreas}
                            className={"py-2"}
                            placeholder={"Bereiche auswählen"}
                            options={areaOptions}
                            toggleSelect={toggleAreaSelection}/>
                    <Button onClick={() => setAddReservation(true)} variant={"primary"}
                            className={"d-flex d-flex align-items-center p-2 border border-1 border-primary-subtle shadow"}>
                        <Icon.Plus color="white" size={30}/>
                        <span className={"ps-1 pe-2 d-none d-md-block"}>Reservierung erstellen</span>
                    </Button>
                </div>

                <ReservationAlert
                    areas={areas}
                    show={showAddReservation}
                    onHide={() => setAddReservation(false)}
                    editingReservation={editingReservation}
                    setEditingReservation={setEditingReservation}
                    date={date}
                    reservations={reservations}
                />

            </>
        )
};


export default TableReservation;
