import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";

const PinChangeModal = ({show, setShow, confirm}) => {
    const [pinInput, setPinInput] = React.useState("");
    const [pinTime, setPinTime] = useState(5);
    const [pinTimeUnit, setPinTimeUnit] = useState("minutes");

    const handleClosePinChange = () => {
        setShow(false);
    }

    const handleChange = (e) => {
        setPinInput((prev) => {
            if (e.target.value.length > 4) {
                return prev;
            }
            return e.target.value;
        });
    };

    return (
        <Modal show={show} onHide={handleClosePinChange}>
            <Modal.Header closeButton>
                <Modal.Title>PIN ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"d-flex flex-column align-items-center my-4"}>
                    <p>Neue PIN festlegen</p>
                    <div className={"d-flex gap-3"}>
                        <input
                            type="password"
                            className={"form-control rounded-5 text-center"}
                            style={{
                                maxWidth: 300,
                                height: 50,
                                fontSize: 45,
                                letterSpacing: '20px',
                                padding: '0',
                                paddingLeft: '10px',
                            }}
                            value={pinInput}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <Row className={"mb-3"}>
                    <div className={"d-flex justify-content-center gap-3"}>
                        <div>
                            <input
                                type={"number"}
                                className={"form-control"}
                                placeholder={"Zeit bis zur Sperre festlegen"}
                                value={pinTime}
                                onChange={(e) => {
                                    setPinTime(e.target.value);
                                }}
                                style={{width: 100}}
                            />
                        </div>
                        <div>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={
                                    (e) => {
                                        setPinTimeUnit(e.target.value);
                                    }
                                }
                            >
                                <option selected={pinTimeUnit === "seconds"} value="seconds">Sekunden
                                </option>
                                <option selected={pinTimeUnit === "minutes"} value="minutes">Minuten
                                </option>
                                <option selected={pinTimeUnit === "hours"} value="hours">Stunden</option>
                            </select>
                        </div>
                    </div>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePinChange}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={() => confirm(pinInput, pinTime, pinTimeUnit)}>
                    PIN speichern
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PinChangeModal;