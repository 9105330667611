import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import useAPI from "../../hooks/useAPI";
import useToasts from "../../hooks/useToast";

const AddAreaAlert = (props) => {
    const [name, setName] = useState('');
    const defaultColor = '#348AA7';
    const [color, setColor] = useState(defaultColor);

    const { loading, status, response = "", sendRequest } = useAPI();
    const { renderToast } = useToasts();

    async function addNewArea(name) {
        try {
            if (name === '') {
                renderToast("Raumname darf nicht leer sein", "", "danger");
                return;
            }

            if (Object.keys(props.editingArea).length !== 0) {
                const areaData = {
                    area: name,
                    // remove # from color
                    color: color.substring(1),
                    deleted: false,
                    active: true,
                };
                sendRequest(`areas/${props.editingArea.id}`, 'PATCH', {}, areaData).then((areasData) => {
                      if (areasData.response.status === 200) {
                          renderToast("Erfolgreich ", areasData.data.message, "success")
                          try {
                              sendRequest(`areas`, 'GET').then((data) => {
                                  if (data.response.status === 200) {
                                      props.updateDropDown(props.editingArea.id, data.data);
                                      props.onHide();
                                  } else {
                                      renderToast("Räume abfragen fehlgeschlagen", "", "danger")
                                  }
                              });
                          } catch (error) {
                              console.error("Fehler beim Abrufen der Areas:", error);
                          }
                      } else {
                          renderToast("Raum erstellen fehlgeschlagen", "", "danger")
                      }
                });
            } else {
                const areaData = {
                    area: name,
                    // remove # from color
                    color: color.substring(1),
                    deleted: false,
                    active: true,
                };
                sendRequest(`areas`, 'POST', {}, areaData).then((areasData) => {
                    if (areasData.response.status === 201) {
                        try {
                            sendRequest(`areas`, 'GET').then((data) => {
                                if (data.response.status === 200) {
                                    props.updateDropDown(areasData.data.id, data.data);
                                    props.onHide();
                                } else {
                                    renderToast("Räume abfragen fehlgeschlagen", "", "danger")
                                }
                            });
                        } catch (error) {
                            console.error("Fehler beim Abrufen der Areas:", error);
                        }

                    } else {
                        renderToast("Raum erstellen fehlgeschlagen", "", "danger")
                    }
                });
            }
        } catch (error) {
            console.error("Error when add new area:", error);
        }
    }

    useEffect(() => {
        if (props.show) {
            setName('');
            setColor(defaultColor);
        }
        if (Object.keys(props.editingArea).length !== 0) {
            setName(props.editingArea.area);
            setColor(`#${props.editingArea.color}`);
        }
    }, [props.show]);

    return (
        <Modal
            style={{ paddingTop: 50, paddingBottom: 50 }}
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.editingArea ? 'Raum hinzufügen' : 'Raum bearbeiten'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Raumname</Form.Label>
                        <Form.Control
                            type="name"
                            placeholder="Raum"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 d-flex justify-content-between align-items-center" controlId="exampleForm.ControlInput2">
                        <div className="d-flex align-items-center">
                            <Form.Label className="me-2 mb-0">Farbe</Form.Label>
                            <div>
                                <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
                            </div>
                        </div>
                        <Button
                            className="ms-2"
                            style={{
                                backgroundColor: defaultColor,
                                border: 'none',
                            }}
                            onClick={() => setColor(defaultColor)}>
                            Standard
                        </Button>
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer className={'d-flex justify-content-between'}>
                <Button className={'me-2'}
                        style={{
                            backgroundColor: 'var(--app-color)',
                            border: 'none',
                        }}
                        onClick={props.onHide}
                >
                    Schließen
                </Button>
                <Button
                    style={{
                        backgroundColor: 'var(--button-color-dark)',
                        border: 'none',
                    }}
                    onClick={() => addNewArea(name)}
                >
                    {Object.keys(props.editingArea).length !== 0 ? 'Raum bearbeiten' : 'Neuen Raum anlegen'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddAreaAlert;
