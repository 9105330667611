import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import useAPI from "../../hooks/useAPI";
import "./TableMenu.css";
import {FaEdit} from "react-icons/fa";
import {RxDotFilled} from "react-icons/rx";
import {FaUser} from "@react-icons/all-files/fa/FaUser";
import {FaTrash} from "@react-icons/all-files/fa/FaTrash";
import {HiPlus} from "react-icons/hi";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {generateShortName} from "../../utils/shortNameGeneratorUtils";
import useToast from "../../hooks/useToast";
import {BsChevronCompactLeft, BsChevronCompactRight} from "react-icons/bs";
import {transformReservations} from "../../utils/reservationUtils";
import ReservationAlert from "../../pages/tableReservation/ReservationAlert";
import dayjs from "dayjs";

const TableMenu = ({areaId, placedTables, selectedTable, setSelectedTable, expanded, setExpanded, updateCells}) => {
    const {sendRequest} = useAPI();
    const {renderToast} = useToast();
    const [tables, setTables] = useState([]);
    const [showCreateTableModal, setShowCreateTableModal] = useState(false);
    const [modalTable, setModalTable] = useState({});
    const [editTable, setEditTable] = useState(false);

    useEffect(() => {
        fetchTables();
    }, [placedTables]);

    const fetchTables = () => {
        if (!placedTables) {
            return;
        }
        sendRequest(`tables/area/${areaId}`).then((data) => {
            if (data?.response.status === 200) {
                const filteredPlacedTables = data.data.filter((table) => placedTables.some((placedTable) => placedTable.id === table.id));
                const unplacedTables = data.data.filter((table) => !placedTables.some((placedTable) => placedTable.id === table.id));
                setTables([unplacedTables, filteredPlacedTables]);
            }
        });
    };

    const handleTableSelect = (table) => {
        if (table.placed) {
            setSelectedTable(null);
            return;
        }
        if (selectedTable === table.id) {
            setSelectedTable(null);
            return;
        }

        setSelectedTable(table.id);

        // dispatch event
        const tableMenuSelectEvent = new CustomEvent("tableMenuSelect");
        tableMenuSelectEvent.table = table;
        document.dispatchEvent(tableMenuSelectEvent);
    };

    const handleCreateTable = () => {
        setModalTable({});
        setShowCreateTableModal(true);
    }

    function handleEditTable(table) {
        setModalTable(table);
        setEditTable(true);
        setShowCreateTableModal(true);
    }

    async function handleDeleteTable(table) {
        setExistingReservations([]);
        await sendRequest(`tables/${table.id}`, 'DELETE').then((data) => {
            if (data.response.status === 200) {
                setShowConfirmDeleteModal(false);
                fetchTables();
                renderToast("Tisch gelöscht", "Der Tisch wurde erfolgreich gelöscht.", "success");
            } else if (data.response.status === 406) {
                renderToast("Ups, etwas ist schief gelaufen", data.data.message, "danger");
                if (data.data) {
                    // remove deleted and message field from data.data
                    const {deleted, message, ...rest} = data.data;
                    setExistingReservations(transformReservations(rest.data));
                }
            }
        });
        updateCells();
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [tableToDelete, setTableToDelete] = useState("");
    const [existingReservations, setExistingReservations] = useState([]);

    const [showAddReservation, setAddReservation] = useState(false);
    const [editingReservation, setEditingReservation] = useState(null);
    const [date, setDate] = useState(dayjs());
    const [reservations, setReservations] = useState({});

    function getReservations() {
        const formattedDate = date.format('YYYY-MM-DD');
        sendRequest(`reservations/resdate/${formattedDate}`).then(data => {
                setReservations(transformReservations(data.data));
        });
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate werden von 0 bis 11 gezählt, daher +1
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    return (
        <>
            <ReservationAlert
                areas={{}}
                show={showAddReservation}
                onHide={() => {
                    setAddReservation(false);
                    handleDeleteTable(tableToDelete);
                }}
                editingReservation={editingReservation}
                setEditingReservation={setEditingReservation}
                date={date}
                reservations={reservations}
            />
            <Modal
                show={showConfirmDeleteModal}
                onHide={() => {
                    setShowConfirmDeleteModal(false);
                    setExistingReservations([]);
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tisch löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bist du sicher, dass du den
                    <strong> {tableToDelete.tableNumber}</strong> löschen
                    möchtest?
                </Modal.Body>

                {existingReservations.length > 0 && (
                    <Modal.Body>
                        <p>
                            <strong>ACHTUNG:</strong> Der Tisch hat noch Reservierungen.
                            Bitte lösche zuerst die Reservierungen, um den Tisch zu löschen.
                        </p>
                        <ul style={{ display: 'flex', flexDirection: 'column',
                          gap: '0.5rem' }}>
                            {existingReservations.map((reservation, idx) => (
                                <li key={idx} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',}}>
                                    <div>
                                        <strong>{reservation.name}{' '}</strong> am
                                        <strong>{' '}{formatDate(reservation.reservationDate)}</strong>
                                    </div>
                                    <button className="btn btn-primary ml-auto" onClick={() => {
                                        getReservations();
                                        setEditingReservation(reservation);
                                        setAddReservation(true);
                                    }}>Bearbeiten</button>
                                </li>
                            ))}
                        </ul>
                    </Modal.Body>
                )}

                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowConfirmDeleteModal(false)}
                    >
                        Abbrechen
                    </button>
                    <button
                        className="btn btn-danger text-white"
                        onClick={() => {
                            handleDeleteTable(tableToDelete);
                        }}>
                        Löschen
                    </button>
                </Modal.Footer>
            </Modal>

            <div className={"d-flex bg-white"}>
                <div className={"text-dark py-3 align-self-center"} role={"button"}
                     onClick={toggleExpanded}>
                    {expanded ? <BsChevronCompactRight size={25}/> : <BsChevronCompactLeft size={25}/>}
                </div>
                <div className={"tableMenu pe-3 overflow-y-auto vh-100 spacingY"} style={{paddingBlock: "80px"}}>
                    <div className={"pb-2 d-flex justify-content-between"}>
                        <h3 className={"lead fs-3"}>Tische</h3>
                        <div className={"d-flex"}>
                            <Button onClick={handleCreateTable} variant={"outline-primary"}
                                    className={"rounded-5 d-flex align-items-center gap-1"}>
                                <HiPlus size={20}/>Tisch erstellen</Button>
                        </div>
                    </div>
                    <div className={"overflow-y-auto"}>
                        <div className={"d-flex flex-column overflow-y-auto"} style={{gap: "0.5em"}}>
                            {
                                tables.map((tableGroup, i) => (
                                    <>
                                        <h5 key={"group" + i} className={`lead ${i === 1 && "mt-4"}`}
                                            style={{fontSize: "0.8em"}}>
                                            {i === 0 ? "Nicht Platzierte Tische" : "Platzierte Tische"}
                                        </h5>
                                        {tableGroup.map((table, idx) => (
                                            <>
                                                {idx !== 0 && <hr className={"m-0"} style={{opacity: 0.1}}/>}
                                                <div
                                                    key={"table" + idx}
                                                    tabIndex={0}
                                                    className={
                                                        `d-flex justify-content-between align-items-center rounded-5 tableMenuItem ${i === 0 ? "not-placed" : "placed"} ${selectedTable === table.id && "bg-primary-subtle"}`
                                                    }
                                                    onClick={() => handleTableSelect({...table, placed: i === 1})}
                                                >
                                                    <div className={"p-2"}>
                                                        <div className={"d-flex gap-3"}>
                                                            <div
                                                                className={`${
                                                                    selectedTable === table.id ? "bg-success-subtle" :
                                                                        i === 1 ? "bg-secondary-subtle" : "bg-primary-subtle"
                                                                } rounded-circle d-flex justify-content-center align-items-center text-wrap checkCircle shadow-sm`}
                                                            >
                                                                {table.shortForm}
                                                            </div>
                                                            <div>
                                                                <div className={"d-flex align-items-center"}>
                                                                    <span>{table.shortForm}</span>
                                                                    <span><RxDotFilled/></span>
                                                                    <span>{table.tableNumber}</span>
                                                                </div>
                                                                <div style={{fontSize: "0.8em"}}
                                                                     className={"text-dark-emphasis d-flex gap-2"}>
                                                        <span className={"d-flex align-items-center gap-1"}>
                                                            <FaUser/>{table.capacity}
                                                        </span>
                                                                    <span><RxDotFilled/></span>
                                                                    <span>{table.area.area}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"d-flex bg-light rounded-5 editDeleteButtons mx-2"}>
                                                        <Button variant={"link"}
                                                                className={"text-secondary rounded-circle p-0"}
                                                                onClick={(e) => {
                                                                    handleEditTable(table);
                                                                    e.stopPropagation();
                                                                }}
                                                        >
                                                            <FaEdit/>
                                                        </Button>
                                                        <Button variant={"link"}
                                                                className={"text-secondary rounded-circle p-0"}
                                                                onClick={(e) => {
                                                                    setShowConfirmDeleteModal(true);
                                                                    setTableToDelete(table);
                                                                    e.stopPropagation();
                                                                }}
                                                        >
                                                            <FaTrash/>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <CreateTableModal
                    show={showCreateTableModal}
                    handleClose={() => setShowCreateTableModal(false)}
                    areaId={areaId}
                    onSave={fetchTables}
                    modalTable={modalTable}
                    setModalTable={setModalTable}
                    edit={editTable}
                    setEdit={setEditTable}
                />
            </div>
        </>
    );
};

export default TableMenu;

const CreateTableModal = ({show, handleClose, areaId, onSave, modalTable, setModalTable, edit = false, setEdit}) => {
    const {sendRequest} = useAPI();
    const {renderToast} = useToast();

    const handleSave = () => {
        const tableData = {
            tableNumber: modalTable.tableNumber,
            shortForm: modalTable.shortForm,
            capacity: modalTable.capacity,
            area: {
                id: areaId
            }
        };

        if (edit) {
            // Update existing table
            sendRequest(`tables/${modalTable.id}`, 'PATCH', {}, tableData).then(() => {
                handleClose();
                renderToast("Tisch bearbeitet", "Der Tisch wurde erfolgreich bearbeitet.", "success");
                onSave();
            }).catch((err) => {
                renderToast("Tisch konnte nicht bearbeitet werden", err.message, "danger");
            });
            setEdit(false);
        } else {
            // Create new table
            sendRequest('tables', 'POST', {}, tableData).then((data) => {
                if (data.response.status === 201) {
                    handleClose();
                    renderToast("Tisch erstellt", "Der Tisch wurde erfolgreich erstellt.", "success");
                    onSave();
                } else {
                    renderToast("Tisch konnte nicht erstellt werden", "Bitte versuche es erneut.", "danger");
                }
            }).catch((err) => {
                renderToast("Tisch konnte nicht erstellt werden", err.message, "danger");
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // prevent the form from refreshing the page
        handleSave();
    };

    const handleTableNumberChange = (e) => {
        setModalTable({...modalTable, tableNumber: e.target.value, shortForm: generateShortName(e.target.value)})
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTable ? 'Tisch bearbeiten' : 'Neuen Tisch erstellen'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={"mb-3"}>
                        <Form.Group className={"pe-0"} as={Col} controlId={"tableName"}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                required
                                type={"text"}
                                placeholder={"Tisch 1"}
                                value={modalTable.tableNumber}
                                onChange={handleTableNumberChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId={"tableShortName"}>
                            <Form.Label>Kurzname</Form.Label>
                            <Form.Control
                                maxLength={3}
                                required
                                type={"text"}
                                placeholder={"T1"}
                                value={modalTable.shortForm}
                                onChange={(e) => setModalTable({...modalTable, shortForm: e.target.value})}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group className={"mb-1"} controlId={"tableCapacity"}>
                        <Form.Label>Plätze</Form.Label>
                        <Form.Control
                            required
                            type={"number"}
                            placeholder={"4"}
                            min={"1"}
                            value={modalTable.capacity}
                            onChange={(e) => setModalTable({...modalTable, capacity: e.target.value})}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Abbrechen
                    </Button>
                    <Button type={"submit"} variant="primary">
                        {modalTable ? 'Tisch speichern' : 'Tisch erstellen'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};