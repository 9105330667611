import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Circle, Group, Image, Layer, Rect, Stage, Text} from 'react-konva';
import useAPI from "../../hooks/useAPI";
import useToast from "../../hooks/useToast";
import {gridDefaults} from "../restaurantGrid/RestaurantGrid";
import useImage from 'use-image';
import 'rc-slider/assets/index.css';
import {convertUTCToLocalTime} from "../../utils/dateUtils";
import TimelineV2 from "../timelineV2/TimelineV2";
import {FaXmark} from "react-icons/fa6";
import LoadingSpinner from "../loading/LoadingSpinner";

const calculateTableBounds = (cells) => {
    const returnVal = cells.reduce((acc, cell) => {
        if (cell.cellType === "TABLE") {
            acc[cell.table.id] = acc[cell.table.id] || {
                minX: Infinity,
                minY: Infinity,
                maxX: -Infinity,
                maxY: -Infinity
            };
            const {minX, minY, maxX, maxY} = acc[cell.table.id];
            acc[cell.table.id].minX = Math.min(minX, cell.x);
            acc[cell.table.id].minY = Math.min(minY, cell.y);
            acc[cell.table.id].maxX = Math.max(maxX, cell.x);
            acc[cell.table.id].maxY = Math.max(maxY, cell.y);
        }
        return acc;
    }, {});
    return returnVal;
};

const BirdView = ({period, reservations, areas, openModal, date, areasLocked}) => {
    const {sendRequest} = useAPI();
    const {renderToast} = useToast();
    const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight});
    const [cellsByArea, setCellsByArea] = useState([]);
    const [scale, setScale] = useState(1);
    const [hoveredTableId, setHoveredTableId] = useState(null);
    const [timelineTableId, setTimelineTableId] = useState(null);
    const [wallTexture] = useImage('/wall_texture.jpg');
    const getCellByCords = useCallback((cellsObj, x, y) => {
        const key = `${x},${y}`;
        return cellsObj[key];
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCells = async () => {
            const allCellsByArea = await Promise.all(areas.map(async (areaId) => {
                try {
                    const data = await sendRequest(`cells/area/${areaId}`);
                    if (data.response.status === 200 || data.response.status === 201) {
                        if (!data.data || data.data.length === 0) return null;

                        const cellsObj = data.data.reduce((acc, cell) => {
                            acc[`${cell.x},${cell.y}`] = cell;
                            return acc;
                        }, {});

                        let modifiedData = data.data.map(cell => {
                            if (cell.cellType !== "WALL") return cell;

                            const borderedCords = [
                                {x: cell.x, y: cell.y - 1},
                                {x: cell.x + 1, y: cell.y},
                                {x: cell.x, y: cell.y + 1},
                                {x: cell.x - 1, y: cell.y},
                            ];

                            const borderRadius = [8, 8, 8, 8];

                            borderedCords.forEach((cord, index) => {
                                const cellToCheck = cellsObj[`${cord.x},${cord.y}`];
                                if (cellToCheck?.cellType === cell.cellType) {
                                    borderRadius[index] = 0;
                                    if (index + 1 < borderedCords.length) {
                                        borderRadius[index + 1] = 0;
                                    } else {
                                        borderRadius[0] = 0;
                                    }
                                }
                            });

                            return {...cell, borderRadius};
                        });

                        return {areaId, cells: modifiedData};
                    }
                } catch (error) {
                    renderToast("Fehler", "Fehler beim Abfragen der Tische.", "danger");
                    return null;
                }
            }));

            // Filter out any null responses and update state
            setCellsByArea(prevAreas => {
                const updatedAreas = allCellsByArea.filter(area => area !== null);
                // Assuming you want to replace all previous areas
                return updatedAreas;
            });
            setIsLoading(false);
        };

        fetchCells();
    }, [areas]);

    // useRef for tracking the last touch distance
    const lastDist = useRef(0);
    const stageRef = useRef(null);
    const [zooming, setZooming] = useState(false);
    useEffect(() => {
        const handleTouchMove = (event) => {
            stageRef.current.draggable(event.touches.length <= 1);
            event.preventDefault();
            if (event.touches.length === 2) {
                if (areasLocked) return;

                event.stopPropagation();
                setZooming(true);

                // Calculate distance between two touch points
                const touch1 = event.touches[0];
                const touch2 = event.touches[1];
                const dist = Math.hypot(
                    touch2.clientX - touch1.clientX,
                    touch2.clientY - touch1.clientY
                );
                if (lastDist.current !== 0) {
                    // Calculate the scale factor
                    const scaleBy = dist / lastDist.current;
                    const stage = stageRef.current;
                    const oldScale = stage.scaleX();
                    const newScale = oldScale * scaleBy;

                    setScale(newScale);
                    stage.scale({x: newScale, y: newScale});

                    // Find the center between the two fingers
                    const touchCenterX = (touch1.clientX + touch2.clientX) / 2;
                    const touchCenterY = (touch1.clientY + touch2.clientY) / 2;

                    // Adjusting the stage position
                    const stageBox = stage.container().getBoundingClientRect();
                    const stageX = stageBox.left;
                    const stageY = stageBox.top;

                    // Relative position of touch center to the stage
                    const relativeCenterX = touchCenterX - stageX;
                    const relativeCenterY = touchCenterY - stageY;

                    // Adjusting for the new scale
                    const dx = (relativeCenterX - stage.x()) * (1 - scaleBy);
                    const dy = (relativeCenterY - stage.y()) * (1 - scaleBy);

                    stage.x(stage.x() + dx);
                    stage.y(stage.y() + dy);
                }
                lastDist.current = dist;
            }
        };

        const handleTouchEnd = () => {
            setZooming(false);
        }

        const handleTouchStart = (event) => {
            if (event.touches.length === 2) {
                const touch1 = event.touches[0];
                const touch2 = event.touches[1];
                lastDist.current = Math.hypot(
                    touch2.clientX - touch1.clientX,
                    touch2.clientY - touch1.clientY
                );
            }
        }

        // Add touch event listeners to the Stage
        if (stageRef.current) {
            const stage = stageRef.current.container();
            stage.addEventListener('touchstart', handleTouchStart)
            stage.addEventListener('touchend', handleTouchEnd);
            stage.addEventListener('touchmove', handleTouchMove);
        }

        return () => {
            if (stageRef.current) {
                const stage = stageRef.current.container();
                stage.removeEventListener('touchstart', handleTouchStart);
                stage.removeEventListener('touchend', handleTouchEnd);
                stage.removeEventListener('touchmove', handleTouchMove);
            }
        };
    }, [stageRef, isLoading]);

    const handleWheel = (e) => {
        e.evt.preventDefault();
        const scaleBy = 1.05;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
        stage.scale({x: newScale, y: newScale});

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
            scale: newScale
        };
        stage.position(newPos);
        stage.batchDraw();

        setStagePos(newPos);
        localStorage.setItem('stagePos', JSON.stringify(newPos));
    };

    const positionedAreasResult = useMemo(() => {
        let width = 0;
        const positionedCellsByArea = cellsByArea.map(({areaId, cells}) => {
            const minX = Math.min(...cells.map(cell => cell.x));
            const maxX = Math.max(...cells.map(cell => cell.x));
            const positionedArea = cells.map(cell => {
                cell.x = cell.x - minX + width;
                return cell;
            });
            width += maxX - minX + 2;
            return {areaId, cells: positionedArea};
        });
        const bounds = calculateTableBounds(positionedCellsByArea.flatMap(({cells}) => cells));
        return positionedCellsByArea.map(({areaId, cells}) => ({areaId, cells, bounds}));
    }, [cellsByArea]);

    const getTableGradient = useCallback((table) => {
        const tableReservations = reservations.filter(reservation => +reservation.tableId === +table.id);
        if (!tableReservations) return [
            0, "#20bf55",
            1, "#20bf55"
        ];

        // calculate the percentage of the reservation start and end in the period
        const periodStart = +period[0];
        const periodEnd = +period[1];
        // Convert start and end times to local time
        const today = new Date();

        const gradient = [0, "#20bf55"];
        tableReservations.sort((a, b) => {
            const aStart = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDay(), a.startHour, a.startMinute));
            const bStart = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDay(), b.startHour, b.startMinute));
            return aStart - bStart;
        });
        for (let i = 0; i < tableReservations.length; i++) {
            const reservation = tableReservations[i];

            const localStart = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDay(), reservation.startHour, reservation.startMinute));
            const localEnd = convertUTCToLocalTime(new Date(today.getFullYear(), today.getMonth(), today.getDay(), reservation.endHour, reservation.endMinute));

            let reservationStart = localStart.getHours() + localStart.getMinutes() / 60;
            let reservationEnd = localEnd.getHours() + localEnd.getMinutes() / 60;

            // If the reservation is not within the selected period, continue
            if (reservationEnd < periodStart || reservationStart > periodEnd) {
                continue;
            }

            // Calculate the start and end percentages of the reservation within the period
            let startPercentage = ((reservationStart - periodStart) / (periodEnd - periodStart));
            let endPercentage = ((reservationEnd - periodStart) / (periodEnd - periodStart));

            // make sure the percentages are between 0 and 1
            if (startPercentage < 0) {
                startPercentage = 0;
            }
            if (endPercentage > 1) {
                endPercentage = 1;
            }

            if (gradient[gradient.length - 1] !== startPercentage) {
                gradient.push(startPercentage, "#20bf55")
            }

            gradient.push(
                startPercentage, "#ff4747",
                endPercentage, "#ff4747",
                endPercentage, "#20bf55",
            );
        }
        gradient.push(1, "#20bf55");
        return gradient;
    }, [reservations, period]);

    const handleTableClick = useCallback((event, table) => {
        setTimelineTableId(table.id);
    }, []);

    const handleDragEnd = useCallback((event, areaId) => {
        const layer = event.target.getLayer();
        const layerPos = layer.position();

        const body = {
            id: areaId,
            xPos: layerPos.x,
            yPos: layerPos.y,
        }

        sendRequest(`areas/${areaId}`, 'PATCH', {}, body).then((data) => {
            if (data.response.status === 200 || data.response.status === 201) {
                renderToast("Erfolg", "Position erfolgreich gespeichert.", "success");
            } else {
                renderToast("Fehler", "Fehler beim Speichern der Position.", "danger");
            }
        })
    }, []);

    const [areasWithPosition, setAreasWithPosition] = useState([]);

    useEffect(() => {
        sendRequest(`areas`, 'GET').then((data) => {
            if (data.response.status === 200 || data.response.status === 201) {
                setAreasWithPosition(data.data);
            } else {
                renderToast("Fehler", "Fehler beim Abfragen der Räume.", "danger");
            }
        });
    }, [areas]);

    const getAreaPosition = useCallback((areaId) => {
        const area = areasWithPosition.find(area => area.id === areaId);
        if (!area) return {x: 0, y: 0};
        if (!area.xPos || !area.yPos) return {x: 0, y: 0};
        return {x: area.xPos, y: area.yPos};
    }, [areasWithPosition]);

    const [stagePos, setStagePos] = useState(() => {
        const savedPos = localStorage.getItem('stagePos');
        return savedPos ? JSON.parse(savedPos) : {
            x: 0,
            y: -gridDefaults.cellSize * gridDefaults.gridSize / 2 + dimensions.height / 2 - 25,
            scale: 1
        };
    });

    const handleStageDragEnd = (event) => {
        const stage = event.target.getStage();
        const newPos = {x: stage.x(), y: stage.y(), scale: stage.scaleX()};
        setStagePos(newPos);
        localStorage.setItem('stagePos', JSON.stringify(newPos));
    };

    return (
        <>
            {isLoading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 9999
                }}>
                    <LoadingSpinner size={"lg"}/>
                </div>
            ) : (
                <Stage
                    className={"position-absolute top-0 left-0"}
                    style={{zIndex: -10}}
                    width={dimensions.width}
                    height={dimensions.height}
                    x={stagePos.x}
                    y={stagePos.y}
                    draggable
                    onDragEnd={handleStageDragEnd}
                    scaleX={stagePos.scale}
                    scaleY={stagePos.scale}
                    onWheel={handleWheel}
                    ref={stageRef}
                >
                    {
                        positionedAreasResult.map(({areaId, cells, bounds}) => {
                            const AreaElements = cells.map((cell) => {
                                if (cell.cellType === "TABLE") {
                                    const tableBounds = bounds[cell.table.id];
                                    if (cell.x === tableBounds.minX && cell.y === tableBounds.minY) {
                                        const isHovered = hoveredTableId === cell.table.id;
                                        const gradient = getTableGradient(cell.table);
                                        return (
                                            <>
                                                <Group
                                                    onPointerClick={(e) => handleTableClick(e, cell.table)}
                                                    onMouseEnter={() => setHoveredTableId(cell.table.id)}
                                                    onMouseLeave={() => setHoveredTableId(null)}
                                                >
                                                    <Rect
                                                        key={cell.table.id}
                                                        x={gridDefaults.cellSize * cell.x}
                                                        y={gridDefaults.cellSize * cell.y}
                                                        width={gridDefaults.cellSize * (tableBounds.maxX - tableBounds.minX + 1)}
                                                        height={gridDefaults.cellSize * (tableBounds.maxY - tableBounds.minY + 1)}
                                                        cornerRadius={[8, 8, 8, 8]}
                                                        fillPriority="linear-gradient"
                                                        fillLinearGradientStartPoint={{x: 0, y: 0}}
                                                        fillLinearGradientEndPoint={{
                                                            x: gridDefaults.cellSize * (tableBounds.maxX - tableBounds.minX + 1),
                                                            y: 0
                                                        }}
                                                        fillLinearGradientColorStops={gradient}
                                                        onPointerClick={(e) => handleTableClick(e, cell.table)}
                                                        onMouseEnter={() => setHoveredTableId(cell.table.id)}
                                                        onMouseLeave={() => setHoveredTableId(null)}
                                                    />
                                                    {isHovered && (
                                                        <Rect
                                                            x={gridDefaults.cellSize * cell.x}
                                                            y={gridDefaults.cellSize * cell.y}
                                                            width={gridDefaults.cellSize * (tableBounds.maxX - tableBounds.minX + 1)}
                                                            height={gridDefaults.cellSize * (tableBounds.maxY - tableBounds.minY + 1)}
                                                            fill={"#ffffff"}
                                                            cornerRadius={[8, 8, 8, 8]}
                                                            opacity={0.25}
                                                            onMouseEnter={() => setHoveredTableId(cell.table.id)}
                                                            onMouseLeave={() => setHoveredTableId(null)}
                                                        />
                                                    )}
                                                    <Text
                                                        x={gridDefaults.cellSize * cell.x}
                                                        y={gridDefaults.cellSize * cell.y}
                                                        width={gridDefaults.cellSize * (tableBounds.maxX - tableBounds.minX + 1)}
                                                        height={gridDefaults.cellSize * (tableBounds.maxY - tableBounds.minY + 1)}
                                                        text={cell.table.shortForm}
                                                        align='center'
                                                        verticalAlign='middle'
                                                        fontSize={16}
                                                        fill='#000'
                                                    />
                                                </Group>
                                            </>
                                        );
                                    }
                                }
                                let element;
                                switch (cell.cellType) {
                                    case "CHAIR":
                                        element = (
                                            <Circle
                                                key={cell.id}
                                                x={gridDefaults.cellSize * cell.x + gridDefaults.cellSize / 2}
                                                y={gridDefaults.cellSize * cell.y + gridDefaults.cellSize / 2}
                                                radius={gridDefaults.cellSize / 3}
                                                fill={"#a9a9a9"}
                                            />
                                        );
                                        break;
                                    case "WALL":
                                        element = (
                                            <Image
                                                key={cell.id}
                                                x={gridDefaults.cellSize * cell.x}
                                                y={gridDefaults.cellSize * cell.y}
                                                width={gridDefaults.cellSize}
                                                height={gridDefaults.cellSize}
                                                image={wallTexture}
                                                cornerRadius={cell.borderRadius}
                                            />
                                        );
                                        break;
                                    default:
                                        break;
                                }

                                return element;
                            });
                            const position = getAreaPosition(areaId);
                            return (
                                <Layer
                                    draggable={!zooming && !areasLocked}
                                    key={areaId}
                                    x={position.x}
                                    y={position.y}
                                    onDragEnd={(e) => handleDragEnd(e, areaId)}
                                >
                                    {AreaElements}
                                </Layer>
                            );
                        })
                    }
                </Stage>
            )}
            {timelineTableId && (
                <div className={"fixed-bottom bg-white z-0"} style={{
                    paddingBottom: "60px",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)"
                }}>
                    <div className={"start-0 position-absolute z-3"}>
                        <button className={"btn btn-link p-1"} onClick={() => setTimelineTableId(null)}>
                            <FaXmark size={20}/>
                        </button>
                    </div>
                    <TimelineV2
                        tableId={timelineTableId}
                        date={date}
                        openModal={openModal}
                        updateReservations={() => {}}
                    />
                </div>
            )}
        </>
    );
}

export default BirdView;
