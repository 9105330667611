import Dropdown from "react-bootstrap/Dropdown";
import {BsFillCaretUpFill} from "react-icons/bs";
import "./MultiSelect.css";
import {FaXmark} from "react-icons/fa6";

const MultiSelect = ({
                         options,
                         selectedOptions,
                         setSelectedOptions,
                         minWidth = "190px",
                         placeholder = "Select option"
                     }) => {
    const toggleOption = (option) => {
        if (selectedOptions.includes(option.value)) {
            setSelectedOptions((prev) => {
                return prev.filter((prevOption) => prevOption !== option.value);
            });
        } else {
            setSelectedOptions((prev) => {
                return [...prev, option.value];
            });
        }
    };

    const handleXClick = (event, option) => {
        event.stopPropagation();
        toggleOption(option);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle
                className={"h-100 d-flex align-items-center"}
                variant={"secondary"}
                style={{minWidth: minWidth}}
            >
                <div className={"d-flex justify-content-center gap-2 flex-grow-1"}>
                    {selectedOptions.length > 0 ? (
                        options.map((option) => {
                            if (!selectedOptions.includes(option.value)) return null;
                            return (
                                <div
                                    className={
                                        "chipDiv text-bg-light rounded px-2 d-flex align-items-center gap-1"
                                    }
                                    style={{fontSize: "1.2em"}}
                                    key={option.value}
                                    onClick={(event) => handleXClick(event, option)}
                                >
                                    <span style={{fontSize: "0.8em"}}>{option.label}</span>
                                    <FaXmark
                                        className={"closeChipX rounded"}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <span>{placeholder}</span>
                    )}
                </div>
                {selectedOptions.length < options.length && (
                    <BsFillCaretUpFill className={"dropdown-icon ms-2"}/>
                )}
            </Dropdown.Toggle>
            {selectedOptions.length === options.length || (
                <Dropdown.Menu>
                    {options.map((option, idx) => {
                        if (selectedOptions.includes(option.value)) return null;
                        return (
                            <Dropdown.Item
                                data-optionindex={idx}
                                onClick={() => toggleOption(option)}
                                key={option?.value}
                            >
                                {option?.label}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            )}
        </Dropdown>
    );
};

export default MultiSelect;