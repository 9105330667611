import RestaurantEditorAppBar from "./RestaurantEditorAppBar";
import {RestaurantGrid} from "../../components/restaurantGrid/RestaurantGrid";
import RestaurantElements, {icons} from "../../components/restaurantGrid/RestaurantElements";
import useAPI from "../../hooks/useAPI";
import React, {useEffect, useRef, useState} from 'react';
import useToasts from "../../hooks/useToast";
import PopoverComponent from "../../components/popover/Popover";
import TableMenu from "../../components/restaurantGrid/TableMenu";

const RestaurantEditor = () => {
        const [filledCells, setFilledCells] = React.useState([]);
        const {sendRequest} = useAPI();
        const [selectedElement, setSelectedElement] = useState(-1);
        const [x, setX] = useState(-1);
        const [y, setY] = useState(-1);
        const [showAddTable, setShowAddTable] = useState(false);
        const [showSelectTable, setShowSelectTable] = useState(false);
        const [tables, setTables] = React.useState([]);
        const [nearElements, setNearElements] = useState([]);
        const [areas, setAreas] = useState([{}]);
        const [areaID, setAreaID] = useState(-1);
        const [cellData, setCellData] = useState([]);
        const {renderToast} = useToasts();
        const [tableMenuSelection, setTableMenuSelection] = useState(null);

        const [dragWall, setDragWall] = useState(null);

        const [lastMouseClickPos, setLastMouseClickPos] = useState([0, 0]);
        const [popoverClass, setPopoverClass] = useState("d-none");

        const onSelectedElementChange = (elementId) => {
            setSelectedElement(elementId);
        };
        const handleAreaIDChange = (selectedAreaID) => {
            setAreaID(selectedAreaID);
        };

        async function deleteCell(x, y) {
            try {
                const cellToDelete = filledCells.find(cell => cell.x === x && cell.y === y);
                if (cellToDelete) {
                    if (cellToDelete.cellType === "TABLE") {
                        await sendRequest(`cells/tablecell/${cellToDelete.id}`, 'DELETE');
                    } else {
                        await sendRequest(`cells/designcell/${cellToDelete.id}`, 'DELETE');
                    }
                }
            } catch (error) {
                console.error("Error deleting cell:", error);
            }
        }

        // Function to generate neighboring ghost walls
        const generateNeighborGhosts = (x, y) => {
            const directions = [
                {x: 1, y: 0},  // right
                {x: -1, y: 0}, // left
                {x: 0, y: 1},  // down
                {x: 0, y: -1}, // up
                {x: 2, y: 0},  // 2 steps right
                {x: -2, y: 0}, // 2 steps left
                {x: 0, y: 2},  // 2 steps down
                {x: 0, y: -2}  // 2 steps up
            ];

            const neighboringWalls = directions.map(direction => ({
                x: x + direction.x,
                y: y + direction.y,
                "cellType": "WALL",
                "ghost": true
            }));

            return neighboringWalls;
        }

        const onCellClick = async (x, y, eventType) => {
            if (selectedElement === -1) {
                renderToast("Kein Restaurant Element ausgewählt", "Bitte wähle ein Element aus", "danger");
                return;
            }

            // Check if the selected element is the delete element
            if (selectedElement === 100) {
                // Filter out the cell at the clicked position
                const newFilledCells = filledCells.filter(cell => cell.x !== x || cell.y !== y);
                setFilledCells(newFilledCells);

                // Delete the cell from the backend
                await deleteCell(x, y);
                return;
            }

            // if a table is selected in TableMenu.js, place it
            if (tableMenuSelection) {
                await addCell(x, y, "TABLE", tableMenuSelection);
                setTableMenuSelection(null);
                return;
            }

            const ghostCells = [];
            let skip = false;
            if (selectedElement === 2 && eventType === "touchend" && dragWall) {
                skip = true;
                const xDistance = x - dragWall.x;
                const yDistance = y - dragWall.y;
                const axis = Math.abs(xDistance) > Math.abs(yDistance) ? "x" : "y";
                const distance = (axis === "x" ? xDistance : yDistance) + 1;

                if (axis === "x") {
                    for (let i = 1; i <= Math.abs(xDistance); i++) {
                        ghostCells.push({
                            x: dragWall.x + (distance > 0 ? i : -i),
                            y: dragWall.y,
                            cellType: "WALL",
                            ghost: true
                        });
                    }
                } else {
                    for (let i = 1; i <= Math.abs(yDistance); i++) {
                        ghostCells.push({
                            x: dragWall.x,
                            y: dragWall.y + (distance > 0 ? i : -i),
                            cellType: "WALL",
                            ghost: true
                        });
                    }
                }

                setFilledCells((prev) => {
                    return [...prev.filter((cell) => !cell.ghost), ...ghostCells];
                });
            }

            if (isElementAtPosition(filledCells, x, y) || skip) {
                for (const cell of filledCells) {
                    if (dragWall?.x === x && dragWall?.y === y) {
                        setDragWall(null);
                        setFilledCells((prev) => {
                            return prev.filter((cell) => !cell.ghost);
                        });
                        return;
                    }
                    if (cell.x === x && cell.y === y) {
                        setCellData(cell);
                        if (cell.cellType === "WALL") {
                            if (dragWall && cell.ghost) {
                                setDragWall(null);
                                if (ghostCells.length > 0) {
                                    addCellsFromArray(ghostCells);
                                } else {
                                    addCellsFromArray(filledCells.filter((cell) => cell.ghost));
                                }
                            } else {
                                // if wall is selected, enable wall bulk placement
                                if (selectedElement === 2) {
                                    setDragWall({x: x, y: y});
                                    setFilledCells((prev) => {
                                        return [...prev.filter((cell) => !cell.ghost), ...generateNeighborGhosts(x, y)];
                                    })
                                }
                            }
                        }
                        if (cell.cellType === "TABLE") {
                            setPopoverClass("d-block");
                        }
                        break;
                    }
                }

                return;
            }

            if (selectedElement !== 0) {
                addCell(x, y, icons[selectedElement].name, null);
                return;
            }

            const elements = checkAdjacentElements(filledCells, x, y);
            setNearElements(elements);

            if (elements['left'] == null && elements['right'] == null && elements['above'] == null && elements['below'] == null) {
                setX(x);
                setY(y);
                await getTables();
                if (popoverClass === "d-block") return;
                setShowAddTable(true);
            } else {
                const keys = Object.keys(elements);
                const nonNullValues = keys.filter(key => elements[key] !== null);

                // More than one value there
                if (nonNullValues.length > 1) {
                    const firstValue = elements[nonNullValues[0]];
                    var allValueSame = true;
                    for (var key in nonNullValues) {
                        if (elements[nonNullValues[key]] !== firstValue) {
                            setShowSelectTable(true);
                            allValueSame = false;
                            break;
                        }
                    }

                    if (allValueSame) {
                        addCell(x, y, "TABLE", firstValue);
                    }
                } else {
                    addCell(x, y, "TABLE", elements[nonNullValues]);
                }
            }
        }

        const dragWallRef = useRef(null);

        useEffect(() => {
            dragWallRef.current = dragWall;
        }, [dragWall]);

        const onCellMouseMove = (x, y) => {
            if (!dragWallRef.current) return;

            const xDistance = x - dragWallRef.current.x;
            const yDistance = y - dragWallRef.current.y;
            const axis = Math.abs(xDistance) > Math.abs(yDistance) ? "x" : "y";
            const distance = axis === "x" ? xDistance : yDistance;

            const ghostCells = [];
            if (axis === "x") {
                for (let i = 1; i <= Math.abs(xDistance); i++) {
                    ghostCells.push({
                        x: dragWallRef.current.x + (distance > 0 ? i : -i),
                        y: dragWallRef.current.y,
                        cellType: "WALL",
                        ghost: true
                    });
                }
            } else {
                for (let i = 1; i <= Math.abs(yDistance); i++) {
                    ghostCells.push({
                        x: dragWallRef.current.x,
                        y: dragWallRef.current.y + (distance > 0 ? i : -i),
                        cellType: "WALL",
                        ghost: true
                    });
                }
            }

            if (ghostCells.length === 0) {
                ghostCells.push(...generateNeighborGhosts(x, y))
            }

            setFilledCells((prev) => {
                return [...prev.filter((cell) => !cell.ghost), ...ghostCells];
            })
        };

        function checkAdjacentElements(elements, x, y) {
            const adjacentElements = {
                left: null,
                right: null,
                above: null,
                below: null,
            };

            elements.forEach((element) => {
                if (element.x === x - 1 && element.y === y) {
                    adjacentElements.left = element.table?.id || null;
                }
                if (element.x === x + 1 && element.y === y) {
                    adjacentElements.right = element.table?.id || null;
                }
                if (element.x === x && element.y === y - 1) {
                    adjacentElements.above = element.table?.id || null;
                }
                if (element.x === x && element.y === y + 1) {
                    adjacentElements.below = element.table?.id || null;
                }
            });

            return adjacentElements;
        }

        function isElementAtPosition(elements, x, y) {
            for (const element of elements) {
                if (element.x === x && element.y === y) {
                    return true;
                }
            }
            return false;
        }

        async function getTables() {
            try {
                sendRequest(`tables/area/unused/${areaID}`, 'GET').then((data) => {
                    if (data.response.status === 200 || data.response.status === 201) {
                        setTables(data.data);
                    } else {
                        renderToast("Tische abfragen fehlgeschlagen", "", "danger");
                    }
                });
            } catch (error) {
                console.error("Fehler beim Abrufen der Cells:", error);
            }
        }

        async function getCells() {
            try {
                if (areaID === -1 || areaID === undefined) {
                    return;
                }

                sendRequest(`cells/area/${areaID}`, 'GET').then((data) => {
                    if (data.response.status === 200 || data.response.status === 201) {
                        setFilledCells(data.data);
                    } else {
                        renderToast("Zellen abfragen fehlgeschlagen", "", "danger");
                    }
                });
            } catch (error) {
                console.error("Fehler beim Abrufen der Cells:", error);
            }
        }

        async function getAreas() {
            try {
                sendRequest(`areas`, 'GET').then((data) => {

                    if (data.response.status === 201 || data.response.status === 200) {
                        setAreas(data.data);
                        if (data.data.length > 0) {
                            setAreaID(data.data[0].id);
                        } else {
                            setAreaID(-1);

                        }
                    } else {
                        renderToast("Räume abfragen fehlgeschlagen", "", "danger")
                    }
                });
            } catch (error) {
                console.error("Fehler beim Abrufen der Areas:", error);
            }
        }

        async function addCell(x, y, cellTyp, tableID) {
            try {
                const tableCellData = {
                    x: x,
                    y: y,
                    cellType: cellTyp,
                    area: {
                        id: areaID
                    },
                    table: {
                        id: tableID
                    },
                };
                sendRequest(`cells`, 'POST', {}, tableCellData).then(() => {
                    getCells();
                });
            } catch (error) {
                console.error("Fehler beim Abrufen der Cells:", error);
            }
        }

        async function addCellsFromArray(cellArray) {
            try {
                const formattedCells = cellArray
                    .map(cell => ({
                        x: cell.x,
                        y: cell.y,
                        cellType: cell.cellType,
                        area: {
                            id: areaID
                        },
                    }));

                sendRequest(`cells/addarray`, 'POST', {}, formattedCells).then(() => {
                    getCells();
                });
            } catch (error) {
                console.error("Fehler beim Hinzufügen der Zellen:", error);
            }
        }

        const handleTableSelected = (selectedTable) => {
            addCell(x, y, selectedElement, selectedTable)
        };

        const handleClick = (event) => {
            // Get the closest element with the id "custom-popover" to the event target


            const popover = event.target.closest('.custom-popover');

            // If a matching element was found, it means the event target is inside the specified element
            if (popover !== null) {
                return;
            }

            setLastMouseClickPos([event.clientX, event.clientY]);
        }

        const handlePopoverClose = () => {
            setPopoverClass("d-none");
            getCells();
            getTables();
        };

        const updateCells = () => {
            getCells();
        };

        useEffect(() => {
            getAreas();
            document.addEventListener("click", (event) => handleClick(event));
            document.addEventListener("closePopover", () => {
                setPopoverClass("d-none")
            });

            return () => {
                document.removeEventListener("click", (event) => handleClick(event));
                document.removeEventListener("closePopover", () => {
                    setPopoverClass("d-none")
                });
            };
        }, []);

        useEffect(() => {
            getCells();
        }, [areaID]);

        const [placedTables, setPlacedTables] = useState([]);

        useEffect(() => {
            const newPlacedTables = [];
            filledCells.forEach((cell) => {
                if (cell.cellType === "TABLE") {
                    if (!tables.some((table) => table.id === cell.table.id)) {
                        newPlacedTables.push(cell.table);
                    }
                }
            });
            setPlacedTables(newPlacedTables);
        }, [filledCells]);

    const [tableMenuExpanded, setTableMenuExpanded] = useState(true);

    useEffect(() => {
            if (selectedElement !== 0) {
                setTableMenuSelection(null);
            } else {
                setTableMenuExpanded(true)
            }

            if (selectedElement !== 2) {
                setDragWall(null);
                setFilledCells((prev) => {
                    return prev.filter((cell) => !cell.ghost);
                });
            }
        }, [selectedElement]);

        return (
            <div>
                <PopoverComponent popoverClass={popoverClass} position={lastMouseClickPos} cellData={cellData}
                                  onClose={handlePopoverClose}/>
                <div>
                    <RestaurantEditorAppBar allAreas={areas} areaID={handleAreaIDChange} updateCells={updateCells}/>
                </div>
                <div className={"d-flex"}>
                    {
                        areaID !== -1 &&
                        <div className={"position-absolute top-0"} style={{zIndex: -10}}>
                            <RestaurantGrid
                                onCellClick={onCellClick}
                                filledCells={filledCells}
                                onCellMouseMove={onCellMouseMove}
                                selectedElement={selectedElement}
                                setSelectedElement={setSelectedElement}
                            />
                        </div>
                    }
                    {
                        selectedElement === 0 &&
                        <div
                            className={`shadow overflow-hidden position-absolute top-0 end-0 table-menu vh-100 z-n1 ${!tableMenuExpanded ? 'no-pointer-events' : ''}`}
                            style={{
                                transition: 'transform 0.25s ease',
                                transform: tableMenuExpanded ? 'translateX(0)' : 'translateX(85%)',
                                zIndex: 10,
                                backgroundColor: tableMenuExpanded ? 'white' : 'transparent'
                            }}>
                            <TableMenu
                                areaId={areaID}
                                placedTables={placedTables}
                                selectedTable={tableMenuSelection}
                                setSelectedTable={setTableMenuSelection}
                                expanded={tableMenuExpanded}
                                setExpanded={setTableMenuExpanded}
                                updateCells={getCells}
                            />
                        </div>
                    }
                </div>
                {
                    <>
                        <div className={"bg-white p-2 py-3"}
                             style={{
                                 position: 'absolute',
                                 bottom: '0',
                                 left: '0',
                                 width: '100%',
                                 boxSizing: 'border-box',
                                 boxShadow: '0px -10px 10px 0px rgba(0,0,0,0.25)',
                             }}>
                            <RestaurantElements
                                onSelectedElementChange={onSelectedElementChange}
                                selectedIcon={selectedElement}
                            />
                        </div>
                    </>
                }
            </div>
        )
    }
;

export default RestaurantEditor;
