import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-bootstrap/Dropdown';
import AddAreaAlert from "./AddAreaAlert";
import useAPI from "../../hooks/useAPI";
import useToasts from "../../hooks/useToast";
import {BsFillCaretDownFill, BsFillTrashFill} from "react-icons/bs";
import AppBarV2 from "../../components/appbarV2/AppBarV2";
import {FaEdit} from "react-icons/fa";

function RestaurantEditorAppBar({allAreas, areaID, updateCells}) {

    const [areas, setAreas] = useState(allAreas);
    const [dropdownSelection, setDropdownSelection] = useState(null);
    const [showAddArea, setShowAddArea] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [areaToDelete, setAreaToDelete] = useState(null);
    const {loading, status, response = "", sendRequest} = useAPI();
    const {renderToast} = useToasts();
    const [areaToEdit, setAreaToEdit] = useState({});

    const handleDropdownSelect = (selectedValue) => {
        if (selectedValue !== -1) {
            setDropdownSelection(areas[selectedValue].area);
            areaID(areas[selectedValue].id);
        } else {
            setShowAddArea(true);
        }
    };

    function updateAreas(newAreas) {
        setAreas(newAreas);
    }

    function updateDropdown(newAreaID, newAreas) {
        areaID(newAreaID);
        setDropdownSelection(newAreas[getIndexOfAreaByID(newAreaID, newAreas)].area);
        getAreas();
    }

    function getIndexOfAreaByID(areaID, areas) {
        return areas.findIndex(area => area.id === areaID);
    }

    useEffect(() => {
        if (allAreas.length > 0) {
            setAreas(allAreas);
            setDropdownSelection(allAreas[0].area);
            areaID(allAreas[0].id);
        }
    }, [allAreas]);

    useEffect(() => {
        if (!areas.length) return;
        if (!dropdownSelection) return;

        setDropdownSelection(areas[0].area);
    }, [areas]);

    async function deleteArea(id) {
        try {
            await sendRequest(`areas/${id}`, 'DELETE').then((data) => {
                getAreas();
            });
        } catch (error) {
            console.error("Fehler beim Löschen der Area:", error);
        }
    }

    async function getAreas() {
        try {
            sendRequest(`areas`, 'GET').then((data) => {
                if (data.response.status === 201 || data.response.status === 200) {
                    setDropdownSelection(areas.length > 0 ? areas[0].area : null);
                    areaID(data.data[0].id);
                    updateCells();
                    updateAreas(data.data);
                } else {
                    renderToast("Räume abfragen fehlgeschlagen", "", "danger")
                }
            });
        } catch (error) {
            console.error("Fehler beim Abrufen der Areas:", error);
        }
    }

    return (
        <>
            <Modal
                show={showConfirmDeleteModal}
                onHide={() => setShowConfirmDeleteModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Raum löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bist du sicher, dass du den Raum <strong>{areaToDelete?.area}</strong> löschen
                    möchtest?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowConfirmDeleteModal(false)}
                    >
                        Abbrechen
                    </button>
                    <button
                        className="btn btn-danger text-white"
                        onClick={() => {
                            deleteArea(areaToDelete?.id);
                            setShowConfirmDeleteModal(false);
                        }}
                    >
                        Löschen
                    </button>
                </Modal.Footer>
            </Modal>
            <AppBarV2>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic-button" className={"rounded-2 py-1"} variant={"outline-light"}>
                        {dropdownSelection ? dropdownSelection : "Keine Räume vorhanden"}{' '}
                        <BsFillCaretDownFill/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {areas.map((item, index) => {
                            // Skip the currently selected item
                            if (item.area === dropdownSelection) return null;

                            return (
                                <Dropdown.Item key={index}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <span
                                                onClick={() => handleDropdownSelect(index)}
                                                style={{marginRight: '20px', cursor: 'pointer', fontSize: '18px'}}>
                                                {item.area}
                                            </span>
                                        <div>
                                            <FaEdit
                                                style={{
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                    marginLeft: '14px',
                                                    transition: 'filter 0.3s',
                                                    fontSize: '20px',
                                                }}
                                                onClick={(e) => {
                                                    setAreaToEdit(areas[index]);
                                                    setShowAddArea(true);
                                                }}
                                            />
                                            <BsFillTrashFill
                                                style={{
                                                    cursor: 'pointer',
                                                    color: 'red',
                                                    marginLeft: '14px',
                                                    transition: 'filter 0.3s',
                                                    fontSize: '20px',
                                                }}
                                                onClick={(e) => {
                                                    setAreaToDelete(areas[index]);
                                                    setShowConfirmDeleteModal(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            );
                        })}
                        <Dropdown.Divider/>
                        <Dropdown.Item key={-1} onClick={() => handleDropdownSelect(-1)}>
                            Raum erstellen
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </AppBarV2>
            <AddAreaAlert editingArea={areaToEdit} show={showAddArea} onHide={() => {
                setShowAddArea(false);
                setAreaToEdit({});
            }} updateDropDown={updateDropdown}/>
        </>
    );
}

export default RestaurantEditorAppBar;
